import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { PriorityHigh } from '@material-ui/icons';
import { CustomButton } from '../../../../../../componentes/CustomButton';

export const CallToActionAlert = ({ message, onClickAction, buttonText }) => {
  return (
    <Box
      maxWidth='700px'
      padding='16px'
      display='flex'
      gridGap='16px'
      width='100%'
      flexDirection={['column', 'row']}
      justifyContent={['center', 'space-between']}
      alignItems='center'
      style={{ backgroundColor: '#A4C360', borderRadius: '4px' }}>
      <Box display='flex' gridGap='4px'>
        <PriorityHigh style={{ color: '#03070C' }} />
        <Typography
          variant='subtitle2'
          style={{ color: '#03070C', fontWeight: 700, maxWidth: 550 }}>
          {message}
        </Typography>
      </Box>
      <CustomButton
        style={{ backgroundColor: '#7F60C3' }}
        onClick={onClickAction}>
        {buttonText}
      </CustomButton>
    </Box>
  );
};
