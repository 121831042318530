import React, { Fragment, useEffect, Suspense, lazy } from 'react';
import Loading from '@material-ui/core/LinearProgress';
import Portada from '../subvistas/Common/Portada';

const Paquetes = lazy(() => import('../subvistas/Precios/Paquetes'));
const Reinscripcion = lazy(() => import('../subvistas/Precios/Reinscripcion'));
const FAQ = lazy(() => import('../subvistas/Common/FAQ'));

import FlechaGuia from '../componentes/utils/ArrowDown';

import cover from '../imagenes/precios.svg';

const Precios = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Portada
        titulo='Precios'
        descripcion='Nuestros planes están pensados para que tú elijas cómo quieres llevar las materias.'
        img={cover}
        img_alt='Estudia la prepa en linea con precios accesibles.'
        bg_color='#999df2'
      />
      <Suspense fallback={<Loading />}>
        <Paquetes />
        <Reinscripcion />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <FAQ />
      </Suspense>
      <FlechaGuia />
    </Fragment>
  );
};

export default Precios;
