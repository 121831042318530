import * as R from 'ramda';
import React, { Suspense, lazy } from 'react';
import 'date-fns';
import Box from '@material-ui/core/Box';
import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';
import esLocale from 'date-fns/locale/es';
import format from 'date-fns/format';
import { LinearProgress as Loading } from '@material-ui/core';
import { StudentInfoForm } from './sections/StudentInfoForm';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useStyles } from './styles';
import { TutorInfoForm } from './sections/TutorInfoForm';
import { AddressInfoForm } from './sections/AddressInfoForm';
import { useEdicion } from './useEdicion';
import { EdicionAlert } from './components/EdicionAlert';

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

const Button = lazy(() => import('../../../componentes/utils/Button'));

const Edicion = ({
  back,
  obligated,
  listaFaltantes,
  setListaFaltantes,
  setObligated,
}) => {
  const classes = useStyles();

  const {
    data,
    dispatch,
    errors,
    handleChange,
    handleDateChange,
    handleDateChangeT,
    handleSubmit,
  } = useEdicion({ setObligated });

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={esLocale}>
      <form onSubmit={handleSubmit}>
        <Box className={classes.root}>
          {obligated && (
            <EdicionAlert
              severity='warning'
              style={{ width: '100%', marginBottom: 8, marginTop: 8 }}>
              Necesitas agregar los siguientes datos antes de continuar:{' '}
              {R.join(', ', listaFaltantes)}.
            </EdicionAlert>
          )}

          <Suspense fallback={<Loading />}>
            <StudentInfoForm
              handleChange={handleChange}
              handleDateChange={handleDateChange}
              data={data}
              listaFaltantes={listaFaltantes}
              errors={errors}
              dispatch={dispatch}
            />
          </Suspense>

          <Suspense fallback={<Loading />}>
            <TutorInfoForm
              handleChange={handleChange}
              data={data}
              listaFaltantes={listaFaltantes}
              errors={errors}
              handleDateChange={handleDateChangeT}
            />
          </Suspense>

          <Suspense fallback={<Loading />}>
            <AddressInfoForm
              handleChange={handleChange}
              data={data}
              listaFaltantes={listaFaltantes}
              errors={errors}
            />
          </Suspense>

          {!R.isEmpty(errors) && (
            <EdicionAlert
              severity='warning'
              style={{ width: '100%', marginBottom: 8, marginTop: 8 }}>
              Revisa los errores anteriores para poder continuar. Serás llevado
              al inicio del formulario automáticamente.
            </EdicionAlert>
          )}
          <Box
            display='flex'
            justifyContent='flex-end'
            gridGap='16px'
            width='100%'>
            {R.not(obligated) && (
              <Suspense fallback={<Loading />}>
                <Button onClick={back}>
                  <Typography variant='body1'>REGRESAR</Typography>
                </Button>
              </Suspense>
            )}

            <Suspense fallback={<Loading />}>
              <Button type='submit' onClick={() => setListaFaltantes([])}>
                <Typography variant='body1'>GUARDAR CAMBIOS</Typography>
              </Button>
            </Suspense>
          </Box>
        </Box>
      </form>
    </MuiPickersUtilsProvider>
  );
};

export default Edicion;
