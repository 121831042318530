import axios from 'axios';

const backend = `${process.env.PREPA_SERVER}`;
const url = `${backend}estudiantes/`;

export const PERIODO_ACTUAL = () => {
  return axios.get(`${backend}periodos/actual`);
};

export const PERIODO_SIGUIENTE = () => {
  return axios.get(`${backend}periodos/siguiente`);
};

export const PERIODO_INSCRIPCION = () => {
  return axios.get(`${backend}periodos/inscripcion`);
};

export const PERIODO_REINSCRIPCION = () => {
  return axios.get(`${backend}periodos/reinscripcion`);
};

export const PAGO_OXXO = (
  matricula,
  token,
  cantidad,
  concepto,
  moneda,
  periodo
) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(
    `${url}${matricula}/pagoOxxo`,
    {
      importe: cantidad,
      moneda: moneda,
      concepto: concepto,
      periodo,
    },
    headers
  );
};

export const PAGO_SPEI = (
  matricula,
  token,
  cantidad,
  concepto,
  moneda,
  periodo
) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(
    `${url}${matricula}/pagoSpei`,
    {
      importe: cantidad,
      moneda: moneda,
      concepto: concepto,
      periodo,
    },
    headers
  );
};

export const PAGO_TARJETA = (
  matricula,
  conektaToken,
  stripeToken,
  token,
  cantidad,
  concepto,
  moneda,
  periodo
) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(
    `${url}${matricula}/pagoTarjeta`,
    {
      importe: cantidad,
      moneda: moneda,
      concepto: concepto,
      periodo,
      conektaToken,
      stripeToken,
      periodo,
    },
    headers
  );
};

export const INSCRIPCION_MATERIAS = (matricula, materias, token) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(
    `${url}${matricula}/agregarMaterias`,
    {
      materias: materias,
    },
    headers
  );
};

export const MATERIAS_FALTANTES = (matricula, token) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.get(`${url}${matricula}/materiasFaltantes`, headers);
};

export const GET_DOCUMENT = (urlDocument, token) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  return axios.get(urlDocument, headers);
};
