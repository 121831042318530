import { useState, useEffect, useContext } from 'react';
import { backend } from '../../../utils';
import * as store from '../../../utils/localStore';
import axios from 'axios';
import * as R from 'ramda';
import { getGrade } from '../../../utils/utils';
import { verifyUserInfo } from './utils';
import { PERIODO_REINSCRIPCION } from '../../../API';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { useDetermineGraduatedState } from './useDetermineGraduatedState';
import { sesionStore } from '../../../utils/sesionStore';

export const useGetStudent = () => {
  const [generalInfo, setGeneralInfo] = useState([]);
  const [studentInfo, setStudentInfo] = useState({});
  const [currentSubjects, setCurrentSubjects] = useState([]);
  const [messages, setMessages] = useState([]);
  const [obligated, setObligated] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [hasDebt, setHasDebt] = useState(false);
  const [isReinscripcion, setIsReinscripcion] = useState(false);
  const [noGrades, setNoGrades] = useState(false);
  const [headerState, setHeaderState] = useState({
    nameOfTheComponentToShow: '', // 'payDocumentButton', 'waitingMessage', 'documentsTrackId',  'payTuitionButton', 'reinscripcionButton', 'payBeforeRegister'
    showPaymentFlow: false,
    showReinscripcionFlow: false,
    reinscripcionLabel: 'Reinscripción',
    documentTrackId: '',
    paymentInfo: {
      concept: '',
      amount: 0,
      term: '',
      studentId: '',
      currency: 'mxn',
      showPaymentInput: false,
      cardUser: '',
      cardNumber: '',
      cardExpirationMonth: '',
      cardExpirationYear: '',
      cardCVC: '',
    },
  });

  const global = useContext(sesionStore);
  const { state, dispatch } = global;
  const { sesion: student } = state;

  const { Persona, Periodos } = student;
  const { status, missingDocuments } = useDetermineGraduatedState(student);

  const fetchData = async () => {
    const { user, hash } = store.getItems('educsesion');

    const headers = {
      headers: {
        Authorization: `Bearer ${hash}`,
      },
    };

    try {
      const { data } = await axios.get(
        `${backend}estudiantes/${user}`,
        headers
      );
      setMessages(verifyUserInfo(data.estudiante));
      dispatch({ type: 'UPDATE_USER', payload: data.estudiante });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      setObligated(true);
      setIsEdit(true);
    }
  }, [messages]);

  useEffect(() => {
    if (R.isEmpty(student)) return;

    const currentTerm = Periodos[Periodos.length - 1];
    const currentSubjects = currentTerm?.Materias || [];
    const currentTermFromWebpack = process.env.PERIODO_EN_CURSO;

    const totalSubjects =
      Periodos && R.sum(R.map((p) => p.Materias.length)(Periodos));
    const statusDescription = student?.Estatus?.descripcion;
    const cuatrimestre = getGrade(Periodos?.length);
    const scholarship = student?.becado ? 'Sí' : 'No';
    const userName = `${Persona?.nombre} ${Persona?.apellidoPaterno} ${
      Persona?.apellidoMaterno || ''
    }`;
    const dateOfEnrollment = new Date(student?.createdAt).toLocaleDateString();

    if (
      Periodos.length < 2 &&
      Periodos?.every((subject) =>
        subject.Materias.every((materia) => materia.Calificacion === null)
      )
    ) {
      setNoGrades(true);
    } else {
      setNoGrades(false);
    }

    setStudentInfo({
      name: userName,
      dateOfEnrollment,
      email: Persona?.Contacto?.email,
      phone: Persona?.Contacto?.telefono,
      schoolId: student.matricula,
      status: statusDescription,
    });
    setGeneralInfo([
      {
        label: 'cuatrimestre',
        value: cuatrimestre,
      },
      {
        label: 'materias cursadas',
        value: totalSubjects,
      },
      { label: 'tiene beca', value: scholarship },
    ]);
    setCurrentSubjects(currentSubjects);
    if (student.EstatusId === 5) {
      setHeaderState({
        ...headerState,
        nameOfTheComponentToShow: status,
        paymentInfo: {
          concept: 'Documentación',
          amount: 500,
          term: currentTermFromWebpack,
          studentId: student.matricula,
          showPaymentInput: false,
          currency: 'mxn',
        },
        documentTrackId: student.documentosGraduadoTrackId,
        missingDocuments,
      });
    }
  }, [student]);

  useEffect(() => {
    if (!R.isEmpty(student)) {
      const currentTerm = process.env.PERIODO_EN_CURSO;
      const currentTermCompleteInfo = student.Periodos.find(
        (periodo) => periodo.Periodo.periodo === currentTerm
      );

      const hasDebt =
        currentTermCompleteInfo?.pagoActual < currentTermCompleteInfo?.deuda; //pagoActual < deuda

      setHasDebt(hasDebt);

      const showPayTuitionButton =
        currentTermCompleteInfo && hasDebt && student?.EstatusId === 4; // Inscrito

      if (showPayTuitionButton && !isReinscripcion) {
        setHeaderState({
          ...headerState,
          nameOfTheComponentToShow: 'payTuitionButton',
          paymentInfo: {
            ...headerState.paymentInfo,
            concept: 'Colegiatura',
            amount:
              currentTermCompleteInfo?.deuda -
              currentTermCompleteInfo?.pagoActual,
            term: currentTermCompleteInfo.Periodo.periodo,
            studentId: student.matricula,
            showPaymentInput: true,
          },
        });
      }
    }
  }, [student, isReinscripcion]);

  useEffect(() => {
    const fetchReinscripcion = async () => {
      try {
        const response = await PERIODO_REINSCRIPCION();
        const initialReinscripcionDate = new Date(
          response.data.periodo.fechaInicioReinscripciones
        );
        const finalReinscripcionDate = new Date(
          response.data.periodo.fechaFinReinscripciones
        );
        const currentDate = new Date();
        const isReinscripcionPeriod =
          isAfter(currentDate, initialReinscripcionDate) ||
          isBefore(currentDate, finalReinscripcionDate) ||
          isEqual(currentDate, initialReinscripcionDate) ||
          isEqual(currentDate, finalReinscripcionDate);

        setIsReinscripcion(
          isReinscripcionPeriod &&
            student.Estatus.descripcion === 'Evaluado' &&
            student.Periodos.length > 0
        );

        const currentTermCompleteInfo =
          student.Periodos[student.Periodos.length - 1];
        const isPaymentCompleted =
          currentTermCompleteInfo.pagoActual >= currentTermCompleteInfo.deuda;

        if (
          student.Estatus.descripcion === 'Evaluado' &&
          student.Periodos.length > 0 &&
          isPaymentCompleted &&
          isReinscripcionPeriod
        ) {
          setHeaderState({
            ...headerState,
            nameOfTheComponentToShow: 'reinscripcionButton',
          });
        } else if (
          student.Estatus.descripcion === 'ConMaterias' &&
          student.Periodos.length > 0 &&
          isReinscripcionPeriod
        ) {
          setHeaderState({
            ...headerState,
            nameOfTheComponentToShow: 'reinscripcionButton',
            reinscripcionLabel: 'Terminar reinscripción',
          });
        } else if (
          student.Estatus.descripcion === 'Evaluado' &&
          student.Periodos.length > 0 &&
          !isPaymentCompleted &&
          isReinscripcionPeriod
        ) {
          setHeaderState({
            ...headerState,
            nameOfTheComponentToShow: 'payBeforeRegister',
            paymentInfo: {
              ...headerState.paymentInfo,
              concept: 'Colegiatura',
              amount:
                currentTermCompleteInfo?.deuda -
                currentTermCompleteInfo?.pagoActual,
              term: currentTermCompleteInfo.Periodo.periodo,
              studentId: student.matricula,
              showPaymentInput: true,
            },
          });
        }
      } catch (error) {
        console.log('Error', error);
      }
    };
    if (Object.values(student).length > 0) fetchReinscripcion();
    return () => {};
  }, [student]);

  return {
    student,
    studentInfo,
    generalInfo,
    currentSubjects,
    Persona,
    Periodos,
    isEdit,
    setIsEdit,
    obligated,
    messages,
    setMessages,
    setObligated,
    hasDebt,
    headerState,
    setHeaderState,
    noGrades,
  };
};
