import React from 'react';
import { Box } from '@material-ui/core';
import { Header } from '../components/Header';
import Edicion from '../../Edicion/Edicion';
import { StudentInfo } from '../sections/StudentInfo/StudentInfo';
import { GeneralAcademicInfo } from '../sections/GeneralAcademicInfo/GeneralAcademicInfo';
import { Subjects } from '../sections/Subjects';
import { AcademicHistory } from '../sections/AcademicHistory';
import { PaymentsHistory } from '../sections/PaymentsHistory';
import { DiscordButton } from '../components/DiscordButton';
import { Documents } from '../sections/Documents';
import { StudentAlerts } from '../sections/StudentAlerts/StudentAlerts';
import { useModifyStudent } from '../useModifyStudent';
import { ConfirmDocumentsModal } from '../components/ConfirmDocumentsModal';
import { SelectGraduatedDocuments } from '../components/SelectGraduatedDocuments';

export const StudentProfile = (props) => {
  const [openConfirmDocuments, setOpenConfirmDocuments] = React.useState(false);
  const [openSelectGraduatedDocuments, setOpenSelectGraduatedDocuments] =
    React.useState(false);

  const { modifyStudent } = useModifyStudent();

  const {
    student,
    Persona,
    generalInfo,
    studentInfo,
    currentSubjects,
    isEdit,
    setIsEdit,
    obligated,
    messages,
    setMessages,
    setObligated,
    headerState,
    setHeaderState,
    academicDocuments,
    personalDocuments,
    isGraduated,
    isDeactivated,
    noGrades,
  } = props;

  const disableProfileEdition =
    isGraduated &&
    student?.documentosValidadosEstudiante &&
    student?.documentosValidadosControlEscolar;

  return (
    <>
      <Header
        setIsEdit={setIsEdit}
        obligated={obligated}
        isEdit={isEdit}
        disablePlatformButton={isGraduated || isDeactivated}
        disableEditButton={disableProfileEdition}
      />
      {isEdit ? (
        <Box
          display='flex'
          flexDirection='column'
          padding={['16px', '32px']}
          style={{
            background: '#FFF',
            borderRadius: '0 0 8px 8px',
            rowGap: '32px',
          }}>
          <Edicion
            back={() => setIsEdit(false)}
            obligated={obligated}
            listaFaltantes={messages}
            setListaFaltantes={setMessages}
            setObligated={setObligated}
          />
        </Box>
      ) : (
        <>
          <StudentInfo
            studentInfo={studentInfo}
            studentAvatar={Persona?.fotografiaOficial}
          />
          <Box
            display='flex'
            flexDirection='column'
            padding={['16px', '40px 32px']}
            alignItems='center'
            style={{
              background: '#FFF',
              borderRadius: '0 0 8px 8px',
              rowGap: '40px',
            }}>
            <StudentAlerts
              headerState={headerState}
              setHeaderState={setHeaderState}
              setOpenConfirmDocuments={setOpenConfirmDocuments}
              setOpenSelectGraduatedDocuments={setOpenSelectGraduatedDocuments}
            />
            <GeneralAcademicInfo generalInfo={generalInfo} />
            <Box
              display='flex'
              gridColumnGap='48px'
              gridRowGap='32px'
              flexDirection={['column', 'column', 'row']}
              style={{ width: '100%' }}>
              <Subjects currentSubjects={currentSubjects} />
              <Documents
                academicDocuments={academicDocuments}
                personalDocuments={personalDocuments}
                student={student}
                disableUploadDocuments={disableProfileEdition}
                disableDownloadHistory={noGrades}
              />
            </Box>
            <AcademicHistory student={student} />
            <PaymentsHistory student={student} />
            <DiscordButton />
          </Box>
        </>
      )}
      {openConfirmDocuments && (
        <ConfirmDocumentsModal
          openConfirmDocuments={openConfirmDocuments}
          setOpenConfirmDocuments={setOpenConfirmDocuments}
          modifyStudent={modifyStudent}
        />
      )}
      {openSelectGraduatedDocuments && (
        <SelectGraduatedDocuments
          open={openSelectGraduatedDocuments}
          setOpen={setOpenSelectGraduatedDocuments}
          headerState={headerState}
          setHeaderState={setHeaderState}
        />
      )}
    </>
  );
};
