import React from 'react';
import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import { BootstrapSelect } from './components/BootstrapSelect';
import { BootstrapInput } from './components/BootstrapInput';

export const ReferencePayment = ({ paymentData, setPaymentData }) => {
  const { amount, currency, showPaymentInput } = paymentData;
  return (
    <Box display='flex' flexDirection='column' gridRowGap='16px'>
      {showPaymentInput && (
        <Box display='flex' gridColumnGap='24px' alignItems='center'>
          <Box display='flex' flexDirection='column' gridRowGap='4px'>
            <Typography variant='body2'>Cantidad</Typography>
            <BootstrapInput
              type='number'
              value={amount}
              onChange={(e) =>
                setPaymentData({ ...paymentData, amount: e.target.value })
              }
            />
          </Box>
          <Box display='flex' flexDirection='column' gridRowGap='4px'>
            <Typography variant='body2'>Moneda</Typography>
            <Select
              value={currency}
              onChange={(e) =>
                setPaymentData({ ...paymentData, currency: e.target.value })
              }
              input={<BootstrapSelect />}>
              <MenuItem value='mxn'>MXN</MenuItem>
              <MenuItem value='usd'>USD</MenuItem>
            </Select>
          </Box>
        </Box>
      )}
      <Typography variant='body1'>
        Esta referencia es válida una sola vez y no debe intentar pagar con ella
        en caso de haber expirado o haber sido usada anteriormente. Además de la
        información que será mostrada aquí, se enviará una copia al correo que
        tenga el estudiante registrado.
      </Typography>
    </Box>
  );
};
