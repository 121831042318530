import React, { Suspense } from 'react';
import { SesionProvider } from '../../../../utils/inscripcionesStore';
import Prepago from '../../../../componentes/Pagos/Prepago/Prepago';

export const PaymentFlow = ({ headerState, setHeaderState }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SesionProvider>
        <Prepago
          headerState={headerState}
          back={() => {
            setHeaderState({ ...headerState, showPaymentFlow: false });
            window.location.reload(true);
          }}
        />
      </SesionProvider>
    </Suspense>
  );
};
