import React, { lazy } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Aviso } from '../components/Aviso';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useStyles } from '../styles';

const Input = lazy(() => import('../../../../componentes/utils/Input'));
const InputTel = lazy(() => import('../../../../componentes/utils/InputTel'));

export const TutorInfoForm = ({
  handleChange,
  data,
  listaFaltantes,
  errors,
  handleDateChange,
}) => {
  const classes = useStyles();
  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
      gridGap='24px'
      padding='24px'
      borderRadius='8px'
      border='1px solid #E0E0E0'>
      <Box display='flex' flexDirection='column'>
        <Typography variant='h6' style={{ color: '#328677', fontWeight: 700 }}>
          Datos del Tutor(a) o Madre/Padre de Familia
        </Typography>
        <Typography variant='subtitle1'>
          En caso de que el/la estudiante sea mayor de edad, puede colocar aquí
          los mismos datos.
        </Typography>
      </Box>
      <Box display='flex' flexDirection={['column', 'row']} gridGap='24px'>
        <Box
          display='flex'
          flexDirection='column'
          gridGap='8px'
          width={['100%', '50%']}>
          <Input
            id='tnombre'
            label='Nombre Completo'
            name='tnombre'
            type='text'
            fullWidth
            value={data.nombreTutor || ''}
            onChange={handleChange('nombreTutor')}
          />
          <Aviso
            value='Nombre del tutor'
            message={errors.nombreTutor}
            listaFaltantes={listaFaltantes}
          />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          gridGap='8px'
          width={['100%', '50%']}>
          <Input
            id='tsexo'
            label='Sexo'
            name='tsexo'
            value={data.sexoTutor || ''}
            select
            options={[
              { value: 'Masculino', name: 'Masculino' },
              { value: 'Femenino', name: 'Femenino' },
            ]}
            onChange={handleChange('sexoTutor')}
            type='text'
            fullWidth
          />
          <Aviso
            value='Sexo del tutor'
            message={errors.sexoTutor}
            listaFaltantes={listaFaltantes}
          />
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        gridGap='8px'
        width={['100%', 'calc(50% - 12px)']}>
        <KeyboardDatePicker
          autoOk
          fullWidth
          variant='inline'
          inputVariant='outlined'
          format='dd/MM/yyyy'
          margin='normal'
          id='tfechaNacimiento'
          label='Fecha de Nacimiento'
          value={data.fechaNacimientoTutor}
          onChange={handleDateChange}
          className={classes.datePicker}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <Aviso
          value='Fecha de nacimiento del tutor'
          message={errors.fechaNacimientoTutor}
          listaFaltantes={listaFaltantes}
        />
      </Box>
      <Box display='flex' flexDirection='column' width='100%' gridGap='16px'>
        <Typography
          variant='subtitle1'
          style={{ fontWeight: 700, color: '#328677' }}>
          Contacto
        </Typography>
        <Box display='flex' flexDirection={['column', 'row']} gridGap='24px'>
          <Box
            display='flex'
            flexDirection='column'
            gridGap='8px'
            width={['100%', '50%']}>
            <Input
              id='tcorreoTutor'
              label='correoTutor'
              name='tcorreoTutor'
              type='correoTutor'
              fullWidth
              value={data.correoTutor || ''}
              onChange={handleChange('correoTutor')}
            />
            <Aviso
              value='correoTutor'
              message={errors.correoTutor}
              listaFaltantes={listaFaltantes}
            />
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            gridGap='8px'
            width={['100%', '50%']}>
            <InputTel
              id='ttelefono'
              label='Teléfono'
              name='ttel'
              value={data.telefonoTutor}
              onChange={handleChange('telefonoTutor')}
            />
            <Aviso
              value='Teléfono del tutor'
              message={errors.telefonoTutor}
              listaFaltantes={listaFaltantes}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
