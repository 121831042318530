import * as R from 'ramda';
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  Link,
  Box,
} from '@material-ui/core';
import React from 'react';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { ReinscripcionContext } from './ReinscripcionContext';
import Button from '../../../../componentes/utils/Button';

const MainText = ({ metodoPago, data }) => {
  if (R.isNil(data)) return null;
  switch (metodoPago) {
    case 'Oxxo':
      return <TextoOxxo data={data} />;
    case 'Spei':
      return <TextoSpei data={data} />;
    default:
      return <TextoTarjeta data={data} />;
  }
};

const Resultado = () => {
  const [state, setState] = React.useContext(ReinscripcionContext);
  const resultadoPago = state.resultadoPago;
  const metodoPago = resultadoPago?.metodoPago;
  const dataPago = resultadoPago?.data;
  const history = useHistory();

  return (
    <Grid
      item
      container
      spacing={3}
      alignItems='stretch'
      direction='row'
      justifyContent='center'
      style={{ height: '100%', width: '100%', padding: 8 }}>
      <Grid item xs={12}>
        <Typography variant='h5' style={{ fontWeight: 700, color: '#21594F' }}>
          Resumen de Pago
        </Typography>
        <Typography variant='subtitle2'>
          Estamos por finalizar el proceso de inscripción
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        container
        alignItems='stretch'
        justifyContent='center'
        spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h6' align='center'>
            {`Tipo de pago: ${metodoPago}`}
          </Typography>
        </Grid>
        <MainText metodoPago={metodoPago} data={dataPago} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='body1' style={{ marginBottom: '32px' }}>
          ¿Necesitas factura? Revisa tu correo electrónico, cada que realizas un
          pago con nosotros te mandamos una liga para obtenerla.
        </Typography>
        <Box display='flex' flexDirection='column' gridRowGap='16px'>
          <Typography variant='body1'>
            Únete a nuestro servidor de Discord, donde podrás conocer a tus
            compañeros de estudio, a tus profesores y donde podrás resolver
            cualquier duda sobre tu proceso de inscripción.
          </Typography>
          <Box>
            <Link
              href='https://discord.gg/NJMAaQt3qb'
              color='secondary'
              underline='always'
              target='_blank'
              rel='noreferrer'>
              <Button size='small' color='secondary'>
                Unirse a Discord
              </Button>
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} />
      <Grid item container xs={6} direction='column' alignItems='flex-end'>
        <Button
          size='small'
          onClick={() => {
            history.push('/cuenta');
            window.location.reload(true);
          }}>
          Regresar al perfil
        </Button>
      </Grid>
    </Grid>
  );
};

const TextoTarjeta = ({ data }) => (
  <>
    <Grid item xs={12}>
      <Typography variant='body1' align='center'>
        Verifica tu información de pago.
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='body1'>
        Al confirmarse, tu lugar estará apartado y podrás completar la
        información de inscripción desde tu cuenta.
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='body1'>
        Banco: <strong>{data.issuer?.toUpperCase()}</strong>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='body1'>
        Marca: <strong>{data.brand?.toUpperCase()}</strong>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='body1'>
        Tarjeta ({data.type}): <strong>{`**** **** **** ${data.last4}`}</strong>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='h6'>
        <strong>¡El pago ha sido procesado con éxito!</strong>
      </Typography>
    </Grid>
  </>
);

const TextoSpei = ({ data }) => (
  <>
    <Grid item xs={12}>
      <Typography variant='body1'>
        Realiza tu pago a través de SPEI con la siguiente información. Al
        confirmarse, tu lugar estará apartado y podrás completar la información
        de inscripción desde tu cuenta.
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='body1'>
        <strong>Banco: </strong> {data.bank}
        <br />
        <strong>CLABE: </strong> {data.clabe}
        <br />
        <strong>Expira en 3 días</strong>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Alert variant='filled' severity='warning'>
        Esta referencia de pago es exclusiva para el pago de Inscripción .
      </Alert>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='body1' align='center'>
        <strong>Instrucciones:</strong>
      </Typography>
      <Typography variant='body1' component='div'>
        Se ha enviado la información a su correo.
        <ul>
          <li>Accede a tu banca en línea.</li>
          <li>Da de alta la CLABE de esta ficha.</li>
          <li>
            Realiza la transferencia por la cantidad exacta, de lo contrario tu
            pago se rechazará.
          </li>
          <li>
            Al confirmar tu pago, tu banco generará un comprobante digital.
            ¡Consérvalo!
          </li>
          <li>
            Al completar estos pasos, recibirás un correo electrónico
            confirmando tu pago.
          </li>
        </ul>
      </Typography>
    </Grid>
  </>
);

const TextoOxxo = ({ data }) => {
  const url = data.barcode_url;
  return (
    <>
      <Grid item xs={12}>
        <Typography variant='body1'>
          Realiza tu pago a través de Oxxo con la siguiente información. Al
          confirmarse, tu lugar estará apartado y podrás completar la
          información de inscripción desde tu cuenta.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card height='100%' width='100%' elevation={0}>
          <CardActionArea style={{ textAlign: 'end', display: 'block' }}>
            <CardMedia component='img' alt='barcode' height='100' image={url} />
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1'>
          <strong>Referencia:</strong> {data.reference}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='body1'>
          <strong>Expira en 3 días</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1' align='center'>
          <b>Instrucciones:</b>
        </Typography>
        <Typography variant='body1' component='div'>
          <strong>Se ha enviado la información a su correo.</strong>
          <ul>
            <li>Acude a la tienda OXXO preferida.</li>
            <li>Solicita en caja un pago de OXXOPay.</li>
            <li>Dicta al cajero el número de referencia en esta ficha.</li>
            <li>Realiza el pago con dinero en efectivo.</li>
            <li>
              Al confirmar tu pago, el cajero te entregará un comprobante
              impreso. Verifica que se haya hecho correctamente.
            </li>
            <li>
              Conserva el comprobante de pago. Al completar estos pasos
              recibirás un correo confirmando tu pago.
            </li>
          </ul>
        </Typography>
      </Grid>
    </>
  );
};

export default Resultado;
