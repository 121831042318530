import { isNil } from 'ramda';
import React, { Fragment, useEffect, Suspense, lazy } from 'react';
import Loading from '@material-ui/core/LinearProgress';
import { StateProvider } from '../subvistas/Inscripcion/Components/State';
import Mantenimiento from '../subvistas/Common/Mantenimiento';
import { PERIODO_INSCRIPCION } from '../API';

const ArrowDown = lazy(() => import('../componentes/utils/ArrowDown'));
const Inscripcion = lazy(() => import('../subvistas/Inscripcion'));

const Inscripciones = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [inscripcionAbierta, setInscripcionAbierta] = React.useState(null);

  React.useEffect(() => {
    const fetch = async () => {
      if (isNil(inscripcionAbierta)) {
        try {
          const response = await PERIODO_INSCRIPCION();
          setInscripcionAbierta(!!response.data.periodo);
        } catch (error) {
          console.log('Error', error.response.data?.message || error);
          setInscripcionAbierta(false);
        }
      }
    };
    fetch();
  }, [inscripcionAbierta]);

  return (
    <Fragment>
      <Suspense fallback={<Loading />}>
        <StateProvider>
          {inscripcionAbierta ? <Inscripcion /> : <Mantenimiento />}
        </StateProvider>
        <ArrowDown />
      </Suspense>
    </Fragment>
  );
};

export default Inscripciones;
