import axios from 'axios';
import * as R from 'ramda';

const backend = `${process.env.PREPA_SERVER}`;
const url = `${backend}estudiantes/`;

export const REGISTA_USUARIOS = (
  nombre,
  apellidoPaterno,
  apellidoMaterno,
  password,
  telefono,
  email,
  nombreTutor,
  correoTutor,
  telefonoTutor
) => {
  return axios.post(`${url}`, {
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    password,
    telefono,
    email,
    nombreTutor,
    correoTutor,
    telefonoTutor,
  });
};

export const LOGEA_USUARIO = (matricula, password) => {
  return axios.post(`${url}signin`, {
    usuario: matricula,
    password,
  });
};

export const VERIFICA_USUARIO = (correo, password) => {
  return axios.post(`${url}signin`, {
    usuario: correo,
    password,
  });
};

export const ENVIA_BIENVENIDA = (matricula, token) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.get(`${url}${matricula}/sendBienvenida`, headers);
};

export const ENVIA_VERIFICACION = (matricula, token) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.get(`${url}${matricula}/sendValidation`, headers);
};

// Esta no esta testeada
export const VALIDA_USUARIO = (matricula, hash) => {
  return axios.put(`${url}${matricula}/validate?codigoDeValidacion=${hash}`);
};

// Esta testeado, pero no implementado
export const PRUEBA_VALIDACION = (matricula) => {
  return axios.get(`${url}${matricula}/checkValidation`);
};

export const ACTUALIZA_ESTUDIANTE = (matricula, token, body) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.put(
    `${url}${matricula}/`,
    {
      ...body,
    },
    headers
  );
};

export const OBTIENE_MATERIAS = () => {
  return axios.get(`${backend}materias`);
};

export const OBTIENE_ESTUDIANTE = (matricula, hash) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${hash}`,
    },
  };
  return axios.get(`${url}${matricula}`, headers);
};

export const CREA_USUARIO_MOODLE = (matricula) => {
  console.log(matricula);
  return new Promise((r) => setTimeout(r, 5000));
  // return axios.post(`${url}admin/moodle/${matricula}/create_user`);
};

export const ENROLA_USUARIO_MOODLE = (userId, claves) => {
  console.log(userId, claves);
  return new Promise((r) => setTimeout(r, 5000));
  // return axios.post(`${url}admin/moodle/${userId}/enrol_users`, {
  //   claves,
  // });
};

export const CREA_ESTUDIANTE = (data) => {
  return axios.post(`${url}`, data);
};

export const PAGO_OXXO_2 = (matricula, importe, moneda, concepto, periodo) => {
  return axios.post(`${url}${matricula}/pagoOxxo`, {
    importe,
    moneda,
    concepto,
    periodo,
  });
};

export const UPLOAD_DOCUMENTS = (acta, curp, foto, certificado, jwt) => {
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();

  const isNotNull = (val) => R.not(R.isNil(val));
  const append = (formData, type) => (val) => formData.append(type, val);
  R.when(isNotNull, append(formData, 'acta_de_nacimiento'))(acta);
  R.when(isNotNull, append(formData, 'fotografia'))(foto);
  R.when(isNotNull, append(formData, 'curp'))(curp);
  R.when(isNotNull, append(formData, 'certificado_secundaria'))(certificado);
  return axios.post(`${backend}documentos`, formData, config);
};

export const UPLOAD_FOTO = (matricula, image, jwt) => {
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('fotografia', image);
  return axios.post(`${url}${matricula}/uploadFoto`, formData, config);
};

export const UPLOAD_ACTA = (matricula, acta, jwt) => {
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('documento', acta);
  return axios.post(
    `${url}${matricula}/uploadDocumento?tipo=acta_de_nacimiento`,
    formData,
    config
  );
};

export const UPLOAD_CURP = (matricula, curp, jwt) => {
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('documento', curp);
  return axios.post(
    `${url}${matricula}/uploadDocumento?tipo=curp`,
    formData,
    config
  );
};

export const USER_EXISTS = (value, tipo) => {
  const suffix =
    tipo === 'CURP'
      ? `curp=${value}`
      : tipo === 'correo'
      ? `correo=${value}`
      : `matricula=${value}`;
  const url = `${backend}estudiantes/existe?${suffix}`;
  return axios.get(url);
};

export const CREATE_USER = (correo, password) => {
  const url = `${backend}usuarios`;
  return axios.post(url, {
    correo,
    password,
  });
};

export const UPDATE_USER = (
  { correo, nombre, apellidoP, apellidoM, PaisIdNacimiento, CURP, telefono },
  jwt
) => {
  const url = `${backend}personas`;
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
  return axios.post(
    url,
    {
      correo,
      nombre,
      apellidoP,
      apellidoM,
      PaisIdNacimiento,
      CURP,
      telefono,
    },
    config
  );
};

export const UPDATE_ADDRESS = (
  {
    PaisId,
    estado,
    ciudad,
    municipio,
    colonia,
    calle,
    numInt,
    numExt,
    codigoPostal,
  },
  jwt
) => {
  const url = `${backend}direcciones`;
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
  return axios.post(
    url,
    {
      PaisId,
      estado,
      ciudad,
      municipio,
      colonia,
      calle,
      numInt,
      numExt,
      codigoPostal,
    },
    config
  );
};

export const UPDATE_STUDENT_TUTOR = (
  { nombreTutor, correoTutor, telefonoTutor },
  jwt
) => {
  const url = `${backend}estudiantes`;
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
  return axios.post(url, { nombreTutor, correoTutor, telefonoTutor }, config);
};

export const POST_PASSWORD_STUDENT = (
  matricula,
  password,
  confirmacion,
  jwt
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
  return axios.post(
    `${backend}usuarios/${matricula}/nuevoPassword`,
    { password, confirmacion },
    config
  );
};

export const MODIFY_STUDENT = (matricula, body, jwt) => {
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
  return axios.put(`${url}${matricula}`, body, config);
};
