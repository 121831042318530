import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { Typography, Grid, Slide } from '@material-ui/core';

//Styles
import { makeStyles } from '@material-ui/core/styles';
import { EnrollmentBanner } from '../../componentes/Home/EnrollmentBanner';
import { PERIODO_SIGUIENTE } from '../../API';
import { globalStore } from '../../utils/globalStore';

const useStyles = makeStyles((theme) => ({
  contenido: {
    opacity: 1,
    zIndex: 1,
    height: '100%',
    position: 'relative',
    padding: '0 10%',
    width: '100vw',
    overflow: 'hidden',
  },
}));

const Portada = ({
  titulo,
  descripcion,
  img,
  img_alt,
  bg_color,
  color,
  height,
  direction,
}) => {
  const [appear, setAppear] = useState(false);

  const global = useContext(globalStore);
  const { state, dispatch } = global;

  const nextTerm = state.nextTerm;
  const isEnrollment = state.isEnrollment;

  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      setAppear(true);
    }, 250);
  }, [true]);

  useEffect(() => {
    if (!state.nextTerm) {
      PERIODO_SIGUIENTE().then((res) => {
        if (res.data) {
          const { data } = res;
          dispatch({
            type: 'SET_NEXT_TERM',
            payload: {
              registrationStartDate: data.periodo.fechaInicioInscripciones,
              registrationEndDate: data.periodo.fechaFinInscripciones,
            },
          });
        }
      });
    }
  }, []);

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: bg_color ?? 'white',
        height: height ?? '100vh',
        padding: '0% 0% 0 0%',
        overflow: 'hidden',
      }}>
      {(nextTerm || isEnrollment) && (
        <EnrollmentBanner
          isEnrollment={isEnrollment}
          enrollmentDate={nextTerm?.registrationStartDate}
        />
      )}
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        className={classes.contenido}
        style={{ marginTop: nextTerm ? '70px' : '0' }}
        spacing={3}>
        <Grid item xs={12} sm={12} md={6} style={{ textAlign: 'center' }}>
          <Slide in={appear} timeout={350} direction='up'>
            <img alt={img_alt ?? ''} src={img} className='portada-img' />
          </Slide>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            variant='h1'
            style={{
              textAlign: direction ?? 'left',
              color: color ?? 'white',
              marginTop: '0%',
              opacity: 1,
              fontWeight: 'bolder',
            }}
            className={clsx({ hidden: !appear, shown: appear })}
            align='center'>
            {titulo ?? ''}
          </Typography>
          <br />
          <Typography
            variant='h6'
            style={{
              color: color ?? 'white',
              textAlign: direction ?? 'left',
            }}
            className={clsx({ hidden: !appear, shown: appear })}
            align='center'>
            {descripcion ?? ''}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Portada;
