// ucs-2 string to base64 encoded ascii
function utoa(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
}
// base64 encoded ascii to ucs-2 string
function atou(str) {
    return decodeURIComponent(escape(window.atob(str)));
}

const getObject = (name) => {
    if(localStorage.getItem(name)) 
        return atob(localStorage.getItem(name))
    else return false
}

const setObject = (name, object) => {
    localStorage.setItem(name, btoa(object))
    return true
}

const getObjects = (name) => {
    if(localStorage.getItem(name))
        return JSON.parse(atob(localStorage.getItem(name)))
    else return false
}

const setObjects = (name, objects) => {
    localStorage.setItem(name, btoa(JSON.stringify(objects)))
    return true
}

const getItem = (name) => {
    if(sessionStorage.getItem(name)) 
        return atob(sessionStorage.getItem(name))
    else return false
}

const setItem = (name, object) => {
    sessionStorage.setItem(name, btoa(object))
    return true
}

const getItems = (name) => {
    if(sessionStorage.getItem(name)){
        return JSON.parse(decodeURI(atob(sessionStorage.getItem(name))))
    }else return false
}

const setItems = (name, objects) => {
    sessionStorage.setItem(name, btoa(encodeURI(JSON.stringify(objects))))
    return true
}

export{getObject, setObject, getObjects, setObjects, getItem, getItems, setItem, setItems};