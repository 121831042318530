import * as R from 'ramda';
import React from 'react';
import 'date-fns';
import * as store from '../../../utils/localStore';
import axios from 'axios';
import { sesionStore } from '../../../utils/sesionStore';
import { backend, handleAlerts } from '../../../utils';
import useForm from '../../../utils/useForm';
import { validations } from './utils';

export const useEdicion = ({ setObligated }) => {
  const global = React.useContext(sesionStore);
  const globalState = global.state;
  const globalDispatch = global.dispatch;
  const { sesion } = globalState;

  const { successMessage, errorMessage } = handleAlerts();

  const initialState = {
    fechaNacimiento: new Date(sesion.Persona.fechaNacimiento),
    centroEducativo: sesion.CentroEducativoId || 1,
    nombre: sesion.Persona?.nombre,
    discapacidades: R.map((discapacidad) => discapacidad.clave)(
      sesion.Persona.Discapacidades
    ),
    religion: sesion.Persona?.ReligionId || 24,
    esDiscapacidad: sesion.Persona.tieneDiscapacidad ? 'Sí' : 'No',
    tieneDiscapacidad: sesion.Persona?.Discapacidades?.length > 0,
    esReligion:
      sesion.Persona?.ReligionId === 24 || !sesion.Persona?.ReligionId
        ? 'No'
        : 'Sí',
    congregacionReligiosa: sesion.Persona?.congregacionReligiosa ? 'Sí' : 'No',
    comentarioDiscapacidades: sesion.Persona?.comentarioDiscapacidades || '',
    apellidoPaterno: sesion.Persona?.apellidoPaterno,
    apellidoMaterno: sesion.Persona?.apellidoMaterno,
    sexo: sesion.Persona?.sexo,
    email: sesion.Persona?.Contacto?.email,
    telefono: sesion.Persona?.Contacto?.telefono,
    curp: sesion.Persona?.CURP || '',
    nombreTutor: sesion.nombreTutor,
    correoTutor: sesion.correoTutor,
    fechaNacimientoTutor: new Date(sesion.fechaNacimientoTutor),
    sexoTutor: sesion.sexoTutor,
    telefonoTutor: sesion.telefonoTutor,
    calle: sesion.Persona.Usuario?.Direccion?.calle,
    numExt: sesion.Persona.Usuario?.Direccion?.numExt || '',
    numInt: sesion.Persona.Usuario?.Direccion?.numInt || '',
    ciudad: sesion.Persona.Usuario?.Direccion?.ciudad || '',
    colonia: sesion.Persona.Usuario?.Direccion?.colonia || '',
    codigoPostal: sesion.Persona.Usuario?.Direccion?.codigoPostal || '',
    municipio: sesion.Persona.Usuario?.Direccion?.municipio || '',
    estado: sesion.Persona.Usuario?.Direccion?.estado || '',
    PaisId: sesion.Persona.Usuario?.Direccion?.PaisId || '',
    NacionalidadId: sesion.Persona.Usuario?.Direccion?.PaisId || '',
  };

  const toUserData = (data) => ({
    nombre: data.nombre,
    apellidoPaterno: data.apellidoPaterno,
    apellidoMaterno: data.apellidoMaterno,
    fechaNacimiento: data.fechaNacimiento,
    sexo: data.sexo,
    tieneDiscapacidad: data.esDiscapacidad === 'Sí',
    telefono: data.telefono,
    CURP: data.curp,
    comentarioDiscapacidades:
      data.esDiscapacidad === 'Sí' ? data.comentarioDiscapacidades : '',
    discapacidades: data.esDiscapacidad === 'Sí' ? data.discapacidades : [],
    religion: data.esReligion === 'Sí' ? data.religion : 24,
    congregacionReligiosa: data.congregacionReligiosa === 'Sí',
    centroEducativo: data.centroEducativo,
    nombreTutor: data.nombreTutor,
    sexoTutor: data.sexoTutor,
    correoTutor: data.correoTutor,
    telefonoTutor: data.telefonoTutor,
    fechaNacimientoTutor: data.fechaNacimientoTutor,
    PaisId: data.NacionalidadId,
    email: data.email,
  });

  const toDireccionData = (data) => ({
    data: {
      calle: data.calle,
      numInt: data.numInt,
      numExt: data.numExt,
      codigoPostal:
        R.length(data.codigoPostal) === 5 || R.length(data.codigoPostal) === 6
          ? data.codigoPostal
          : '00000',
      colonia: data.colonia,
      municipio: data.municipio,
      ciudad: data.ciudad,
      estado: data.estado,
      PaisId: data.PaisId,
    },
  });

  const onSubmit = async (data) => {
    const userData = toUserData(data);

    const direccionData = toDireccionData(data);
    const { user, hash } = store.getItems('educsesion');
    const headers = { headers: { Authorization: `Bearer ${hash}` } };

    try {
      const urlEst = `${backend}estudiantes/${user}`;
      const urlDir = `${backend}estudiantes/${user}/direccion`;
      const putEst = axios.put(urlEst, userData, headers);
      const putDir = axios.put(urlDir, direccionData, headers);
      const responses = await axios.all([putEst, putDir]);
      globalDispatch({
        type: 'UPDATE_USER',
        payload: responses[0].data.estudiante,
      });
      setObligated(false);
      successMessage(
        'Los datos del usuario han sido actualizados correctamente'
      );
      window.location.reload();
    } catch (error) {
      console.log(
        'Error',
        error.response?.data?.message || error.message || error
      );
      errorMessage(error.response?.data?.message || error.message);
    }
  };

  const { data, handleChange, handleChangeCustom, handleSubmit, errors } =
    useForm({
      onSubmit: onSubmit,
      initialValues: initialState,
      validations: validations,
    });

  React.useEffect(() => {
    if (!R.isEmpty(errors)) {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 3500);
    }
  }, [errors]);

  const handleDateChange = (date) => {
    handleChangeCustom('fechaNacimiento', date);
  };

  const handleDateChangeT = (date) => {
    handleChangeCustom('fechaNacimientoTutor', date);
  };

  const dispatch = ({ type, payload }) => {
    handleChange(type)({ target: { value: payload } });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [true]);

  return {
    data,
    handleChange,
    handleChangeCustom,
    handleSubmit,
    errors,
    handleDateChange,
    handleDateChangeT,
    dispatch,
  };
};
