import React from 'react';
import * as R from 'ramda';
import { useStyles } from '../styles';
import { Box, Typography } from '@material-ui/core';

export const Aviso = ({ value, message, listaFaltantes }) => {
  const classes = useStyles();
  return (
    ((R.includes(value, listaFaltantes || []) || message) && (
      <Box className={classes.box}>
        <Typography variant='caption'>
          <b>{message || '¡Debes completar tus datos!'}</b>
        </Typography>
      </Box>
    )) || <></>
  );
};
