import React from 'react';
import { Box, Link } from '@material-ui/core';
import { CustomButton } from '../../../../componentes/CustomButton';

export const Header = ({
  setIsEdit,
  obligated,
  isEdit,
  disablePlatformButton,
  disableEditButton,
}) => {
  return (
    <Box
      padding={['0 16px', '0 16px', '0 32px']}
      alignItems='center'
      justifyContent={['center', 'end']}
      display='flex'
      style={{
        height: '150px',
        backgroundColor: '#B5E3DB',
        borderRadius: '8px 8px 0 0',
      }}>
      <Box
        display='flex'
        gridColumnGap='16px'
        justifyContent={['space-between']}>
        {!obligated && (
          <CustomButton
            disabled={disableEditButton}
            onClick={() => {
              !disableEditButton && setIsEdit(!isEdit);
            }}>
            {isEdit ? 'Regresar al perfil' : 'Editar perfil'}
          </CustomButton>
        )}
        <CustomButton variant='secondary' disabled={disablePlatformButton}>
          {disablePlatformButton ? (
            'Ir a plataforma'
          ) : (
            <Link
              color='inherit'
              href='https://plataforma.prepaenlinea.mx/'
              target='_blank'
              rel='noreferrer'>
              Ir a Plataforma
            </Link>
          )}
        </CustomButton>
      </Box>
    </Box>
  );
};
