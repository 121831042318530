import React from 'react';
import { TEST } from '../../../../utils';

export const ReinscripcionContext = React.createContext();

export const ReinscripcionStateProvider = (props) => {
  const [state, setState] = React.useState({
    vista: 'materias',
    jwt: null,
    resultadoPago: null,
    status: null,
  });

  return (
    <ReinscripcionContext.Provider value={[state, setState]}>
      {props.children}
    </ReinscripcionContext.Provider>
  );
};
