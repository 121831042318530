import { MODIFY_STUDENT } from '../../Inscripcion/Components/api';
import * as store from '../../../utils/localStore';
import { handleAlerts } from '../../../utils';

export const useModifyStudent = () => {
  const { errorMessage } = handleAlerts();
  const modifyStudent = async (data) => {
    const { user, hash } = store.getItems('educsesion');
    try {
      await MODIFY_STUDENT(user, data, hash);
      window.location.reload();
    } catch (error) {
      errorMessage(error.response.data?.message || error?.message);
    }
  };

  return { modifyStudent };
};
