import React from 'react';
import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import { BootstrapInput } from './components/BootstrapInput';
import { BootstrapSelect } from './components/BootstrapSelect';

export const PaymentWithCard = ({ paymentData, setPaymentData }) => {
  const {
    amount,
    currency,
    showPaymentInput,
    cardUser,
    cardNumber,
    cardExpirationMonth,
    cardExpirationYear,
    cardCVC,
  } = paymentData;

  const years = new Date().getFullYear();
  const yearsArray = Array.from({ length: 10 }, (_, i) => years + i);

  return (
    <Box display='flex' flexDirection='column' gridRowGap='24px'>
      {showPaymentInput && (
        <Box display='flex' gridColumnGap='24px' alignItems='center'>
          <Box display='flex' flexDirection='column' gridRowGap='4px'>
            <Typography variant='body2'>Cantidad</Typography>
            <BootstrapInput
              type='number'
              value={amount}
              onChange={(e) =>
                setPaymentData({ ...paymentData, amount: e.target.value })
              }
            />
          </Box>
          <Box display='flex' flexDirection='column' gridRowGap='4px'>
            <Typography variant='body2'>Moneda</Typography>
            <Select
              value={currency}
              onChange={(e) =>
                setPaymentData({ ...paymentData, currency: e.target.value })
              }
              input={<BootstrapSelect />}>
              <MenuItem value='mxn'>MXN</MenuItem>
              <MenuItem value='usd'>USD</MenuItem>
            </Select>
          </Box>
        </Box>
      )}
      <Box display='flex' flexDirection='column' gridRowGap='24px'>
        <Typography variant='subtitle1' style={{ fontWeight: 700 }}>
          Por favor ingrese los datos de su tarjeta de crédito o débito.
        </Typography>
        <Box display='flex' flexDirection='column' gridRowGap='16px'>
          <Box
            display='flex'
            gridRowGap='16px'
            alignItems='center'
            flexDirection='column'>
            <Box
              display='flex'
              flexDirection='column'
              gridRowGap='4px'
              width='100%'>
              <Typography variant='body2'>Nombre del titular</Typography>
              <BootstrapInput
                type='text'
                value={cardUser}
                onChange={(e) =>
                  setPaymentData({ ...paymentData, cardUser: e.target.value })
                }
              />
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              gridRowGap='4px'
              width='100%'>
              <Typography variant='body2'>Número de tarjeta</Typography>
              <BootstrapInput
                type='text'
                value={cardNumber}
                inputProps={{ maxLength: 16 }}
                onChange={(e) =>
                  setPaymentData({ ...paymentData, cardNumber: e.target.value })
                }
              />
            </Box>
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            gridColumnGap='24px'>
            <Box
              display='flex'
              flexDirection='column'
              gridRowGap='4px'
              width='100%'>
              <Typography variant='body2'>Mes</Typography>
              <Select
                value={cardExpirationMonth}
                onChange={(e) =>
                  setPaymentData({
                    ...paymentData,
                    cardExpirationMonth: e.target.value,
                  })
                }
                input={<BootstrapSelect />}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
                  <MenuItem value={month} key={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              gridRowGap='4px'
              width='100%'>
              <Typography variant='body2'>Año</Typography>
              <Select
                value={cardExpirationYear}
                onChange={(e) =>
                  setPaymentData({
                    ...paymentData,
                    cardExpirationYear: e.target.value,
                  })
                }
                input={<BootstrapSelect />}>
                {yearsArray.map((year) => (
                  <MenuItem value={year} key={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              gridRowGap='4px'
              width='100%'>
              <Typography variant='body2'>CVC</Typography>
              <BootstrapInput
                type='text'
                value={cardCVC}
                inputProps={{ maxLength: 3 }}
                onChange={(e) =>
                  setPaymentData({ ...paymentData, cardCVC: e.target.value })
                }
              />
            </Box>
          </Box>
        </Box>
        <Typography variant='subtitle2' style={{ fontWeight: 700 }}>
          Sus datos son procesados de manera segura a través de{' '}
          <a target='_blank' href='https://www.conekta.com/'>
            conekta
          </a>
          . prepaenlinea.mx no guarda la información para su protección.
        </Typography>
      </Box>
    </Box>
  );
};
