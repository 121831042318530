import { makeStyles } from '@material-ui/core';

export const studentInfoStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    height: '300px',
    '@media (min-width: 768px)': {
      height: '200px',
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: '-12%',
    gridRowGap: '16px',
  },
  infoPContainer: {
    display: 'flex',
    alignItems: 'center',
    gridColumnGap: '8px',
    color: '#6E6779',
  },
  infoP: {
    margin: 0,
    fontFamily: 'Quicksand',
    fontSize: '16px',
    fontWeight: 400,
    color: '#6E6779',
  },
  status: {
    margin: 0,
    fontFamily: 'Quicksand',
    fontSize: '16px',
    fontWeight: 700,
    color: '#B261C4',
  },
  editButton: {
    width: 25,
    height: 25,
    border: 'none',
    borderRadius: '50%',
    backgroundColor: '#EAE9EC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  avatar: {
    width: 100,
    height: 100,
    backgroundColor: '#D4A6DE',
  },
  editIcon: {
    color: '#6E6779',
    width: 18,
    height: 18,
  },
}));
