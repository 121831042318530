import * as R from 'ramda';
import axios from 'axios';
import { ReinscripcionContext } from './ReinscripcionContext';
import {
  Box,
  Button,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  PERIODO_REINSCRIPCION,
  PAGO_SPEI,
  PAGO_OXXO,
  PAGO_TARJETA,
} from '../../../../API';
import Input from '../../../../componentes/utils/Input';
import americanexpress from '../../../../imagenes/american-express.svg';
import mastercard from '../../../../imagenes/mastercard.svg';
import oxxo from '../../../../imagenes/oxxopay_brand.png';
import spei from '../../../../imagenes/spei_brand.png';
import visa from '../../../../imagenes/visa.svg';
import React from 'react';
import { handleAlerts } from '../../../../utils';
require('conekta-card-tokenizer');

const precioUSD = process.env.PRECIO_USD_MATERIAS;
const precioMXN = process.env.PRECIO_MXN_MATERIAS;
const precioStrUSD = process.env.PRECIO_USD_MATERIAS_S;
const precioStrMXN = process.env.PRECIO_MXN_MATERIAS_S;

const priceByCurrency = (moneda, numMaterias) =>
  moneda === 'usd' ? numMaterias * precioUSD : numMaterias * precioMXN;

const priceByModal = (modalidad, precio) =>
  modalidad === 'monthly' ? precio / 4 : precio;

const priceStr = (quantity) => `\$${quantity.toFixed(2)}`;

const priceNum = (modalidad, moneda, numMaterias) =>
  priceByModal(modalidad, priceByCurrency(moneda, numMaterias));

const priceTot = (modalidad, moneda, numMaterias) =>
  priceStr(priceNum(modalidad, moneda, numMaterias));

const useStyles = makeStyles(() => ({
  tarjetaImg: {
    width: '40px',
    height: '40px',
    margin: '15px 5px 5px 5px',
  },
  paymentImg: {
    width: '160px',
    maxHeight: '160px',
    marginTop: '15px',
  },
  speiImg: {
    width: '90px',
    marginTop: '15px',
  },
}));

const Pago = () => {
  const [state, setState] = React.useContext(ReinscripcionContext);
  const tipos = [
    { value: 'Tarjeta (Crédito/Débito)' },
    { value: 'Oxxo' },
    { value: 'Spei' },
  ];
  const [tipoPago, setTipoPago] = React.useState(tipos[0].value);
  const [moneda, setMoneda] = React.useState(state.monedaPago || 'mxn');
  const [modalidad, setModalidad] = React.useState('single');
  const [dataCard, setDataCard] = React.useState({
    numCard: '',
    cvc: '',
    month: '',
    year: '',
    name: '',
  });
  const [materias, setMaterias] = React.useState(state.materiasElegidas);
  const [disabled, setDisabled] = React.useState(false);
  const [periodoInscripcion, setPeriodoInscripcion] = React.useState(null);
  const [error, setError] = React.useState(null);
  const usuario = state.usuario;
  const jwt = state.jwt;
  const login = !!jwt;

  const { errorMessage, warningMessage } = handleAlerts();

  React.useEffect(() => {
    const fetch = async () => {
      const response = await PERIODO_REINSCRIPCION();
      setPeriodoInscripcion(response.data.periodo.periodo);
    };
    fetch();
  }, []);

  const pagoTarjeta = async (tokens) => {
    try {
      const matricula = usuario.matricula;
      const jwt = state.jwt;
      const importe = priceNum(modalidad, moneda, R.length(materias));
      const { conekta, stripe } = tokens;
      const monedaPago = moneda.toUpperCase();
      const response = await PAGO_TARJETA(
        matricula,
        conekta,
        stripe,
        jwt,
        importe,
        'Reinscripción',
        monedaPago,
        periodoInscripcion
      );
      if (response.data?.order.charges.data[0].status === 'paid') {
        const { issuer, brand, last4, type } =
          response.data.order.charges.data[0].payment_method;
        setState({
          ...state,
          vista: 'resultado',
          resultadoPago: {
            metodoPago: 'Tarjeta (Crédito/Débito)',
            data: { issuer, brand, last4, type },
          },
          metodoPago: tipoPago,
        });
        return;
      } else {
        throw 'El pago ha fallado. No se ha hecho ningun cargo a su tarjeta.';
      }
    } catch (error) {
      console.log('Error', error.response?.data?.message || error);
      errorMessage(error.response?.data?.message || error?.message);
      setError(error.response?.data?.message || error?.message);
    }
  };

  const generateTokens = async (tarjeta, setError) => {
    Conekta.setPublicKey(process.env.CONEKTA_KEY);
    warningMessage(
      'No recargues la página hasta que se termine de procesar tu pago.'
    );
    const dataCard = {
      card: {
        number: tarjeta.numCard,
        name: tarjeta.name,
        exp_year: tarjeta.year,
        exp_month: tarjeta.month,
        cvc: tarjeta.cvc,
      },
    };
    const errorHandler = async (error) => {
      const { message_to_purchaser } = error;
      setError(message_to_purchaser);
    };

    const successHandler = async (token) => {
      const url = 'https://api.stripe.com/v1/tokens';
      const card = {
        'card[number]': dataCard.card.number,
        'card[exp_month]': dataCard.card.exp_month,
        'card[exp_year]': dataCard.card.exp_year,
        'card[cvc]': dataCard.card.cvc,
      };
      const encoded = R.map(
        (prop) =>
          `${encodeURIComponent(prop)}=${encodeURIComponent(card[prop])}`
      )(Object.keys(card));
      const body = R.join('&')(encoded);
      const { data } = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${process.env.STRIPE_KEY}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      await pagoTarjeta({ conekta: token.id, stripe: data.id });
    };
    Conekta.Token.create(dataCard, successHandler, errorHandler);
  };

  const speiPayment = async (matricula, moneda, importe, jwt) => {
    warningMessage(
      'No recargues la página hasta que se termine de procesar tu pago.'
    );
    const { data } = await PAGO_SPEI(
      matricula,
      jwt,
      importe,
      'Reinscripción',
      moneda.toUpperCase(),
      periodoInscripcion
    );
    const { bank, expires_at, clabe } =
      data.order.charges.data[0].payment_method;
    return { metodoPago: 'Spei', data: { bank, expires_at, clabe } };
  };

  const oxxoPayment = async (matricula, moneda, importe, jwt) => {
    warningMessage(
      'No recargues la página hasta que se termine de procesar tu pago.'
    );
    try {
      const response = await PAGO_OXXO(
        matricula,
        jwt,
        importe,
        'Reinscripción',
        moneda.toUpperCase(),
        periodoInscripcion
      );
      const { barcode_url, expires_at, reference } =
        response.data.order.charges.data[0].payment_method;
      return {
        metodoPago: 'Oxxo',
        data: { barcode_url, expires_at, reference },
      };
    } catch (error) {
      console.log('Error', error.response.data?.message || error);
      return null;
    }
  };

  const submitPago = async () => {
    setDisabled(true);
    const matricula = usuario.matricula;
    const importe = priceNum(modalidad, moneda, R.length(materias));
    let result;
    if (tipoPago === 'Oxxo') {
      result = await oxxoPayment(matricula, moneda, importe, jwt);
      setState({
        ...state,
        vista: 'resultado',
        resultadoPago: result,
        metodoPago: tipoPago,
      });
      return;
    } else if (tipoPago === 'Spei') {
      result = await speiPayment(matricula, moneda, importe, jwt);
      setState({
        ...state,
        vista: 'resultado',
        resultadoPago: result,
        metodoPago: tipoPago,
      });
      return;
    } else {
      if (validate(dataCard)) {
        await generateTokens(dataCard, setError);
      } else {
        warningMessage('Verifique que los datos de su tarjeta sean correctos');
        setDisabled(false);
      }
    }
  };

  const checkOnlyNumbers = (string) => /^\d+$/.test(string);

  const validate = (dataCard) => {
    const numCard = dataCard.numCard;
    const month = dataCard.month;
    const year = dataCard.year;
    const cvc = dataCard.cvc;
    const owner = dataCard.owner;
    const validNumCard = checkOnlyNumbers(numCard) && R.length(numCard) === 16;
    const validMonth = checkOnlyNumbers(month) && R.length(month) === 2;
    const validYear = checkOnlyNumbers(year) && R.length(year) === 4;
    const validCvc = checkOnlyNumbers(cvc) && R.length(cvc) == 3;
    return validNumCard && validMonth && validYear && validCvc;
  };

  React.useEffect(() => {
    if (login) {
      setMaterias(state.materiasElegidas);
    }
  }, [state]);

  return (
    <Grid
      item
      container
      spacing={3}
      alignItems='center'
      direction='row'
      justifyContent='center'>
      <Grid item xs={12}>
        <Typography variant='h5' style={{ fontWeight: 700, color: '#21594F' }}>
          Formato de Pago
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={3} align='center'>
        <Grid item xs={12} sm={6} container spacing={1} align='stretch'>
          <Grid item xs={12}>
            <Typography variant='body1'>
              Si realizas tu pago con OXXO o con SPEI,{' '}
              <b>
                No cierres ni recargues esta ventana hasta terminar todo el
                proceso de inscripcion,
              </b>{' '}
              de otro modo tendrás que repetir el proceso.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              label='Selecciona tipo de pago'
              fullWidth
              value={tipoPago}
              options={tipos}
              select
              onChange={(evt) => {
                setTipoPago(evt.target.value);
                setDisabled(false);
              }}
            />
          </Grid>
          {tipoPago === 'Tarjeta (Crédito/Débito)' && (
            <PagoTarjeta data={dataCard} setData={setDataCard} />
          )}
          {tipoPago === 'Oxxo' && <PagoOxxo />}
          {tipoPago === 'Spei' && <PagoSpei />}
        </Grid>
        <Grid item xs={12} sm={6} container spacing={3} alignItems='stretch'>
          <Grid item xs={12}>
            <Typography variant='body1'>
              Si su método de pago requiere especificar un beneficiario, se
              deberá ingresar:&nbsp;<b>Educazion práctica para la vida.</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' style={{ fontWeight: 700 }}>
              Resumen de pago.
            </Typography>
            <br />
            <Box width='90%' component={Paper} square elevation={1}>
              <CardContent>
                <Grid container spacing={3} alignItems='stretch'>
                  <Grid item xs={12}>
                    <Typography variant='body1'>
                      {`Total: ${priceTot(
                        modalidad,
                        moneda,
                        R.length(materias)
                      )} ${moneda.toUpperCase()}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '16px',
                        marginBottom: '16px',
                      }}>
                      <FormLabel id='currency'>
                        <Typography variant='body1'>
                          Realizar pago en:
                        </Typography>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby='currency'
                        defaultValue='mxn'
                        name='radio-buttons-group'
                        onChange={(event) => {
                          setMoneda(event.target.value);
                        }}
                        value={moneda}>
                        <FormControlLabel
                          value='mxn'
                          control={<Radio />}
                          label='Pesos mexicanos'
                        />
                        <FormControlLabel
                          value='usd'
                          control={<Radio />}
                          label='Dólares'
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '16px',
                      }}>
                      <FormLabel id='typeOfPayment'>
                        <Typography variant='body1'>
                          Elige la forma de pago:
                        </Typography>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby='type-of-payment'
                        name='typeOfPayment'
                        onChange={(event) => {
                          setModalidad(event.target.value);
                        }}
                        value={modalidad}>
                        <FormControlLabel
                          value={'monthly'}
                          control={<Radio />}
                          label='A meses'
                        />
                        <FormControlLabel
                          value={'single'}
                          control={<Radio />}
                          label='En una sola exhibición'
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant='body1'
              gutterBottom
              style={{ width: '90%', fontWeight: 600, marginTop: 16 }}>
              Guarda tu comprobante de pago para solicitar cualquier aclaración,
              sin dicho comprobante no se te podrá atender.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6} container direction='column' alignItems='flex-end'>
          <Button
            size='large'
            onClick={submitPago}
            disabled={disabled}
            variant='contained'
            color='secondary'>
            {tipoPago === 'Oxxo' || tipoPago === 'Spei'
              ? 'Generar Referencia'
              : 'Realizar Pago con Tarjeta'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PagoTarjeta = ({ data, setData }) => {
  const classes = useStyles();
  const [errors, setErrors] = React.useState({});

  const checkOnlyNumbers = (string) => /^\d+$/.test(string);

  const isNotValid = (field, length) => () => {
    if (!checkOnlyNumbers(data[field]) || !(R.length(data[field]) === length)) {
      setErrors({
        ...errors,
        [field]: `El formato debe ser ${'N'.repeat(length)}`,
      });
    } else if (!!errors[field]) {
      const newErrors = { ...errors };
      delete newErrors[field];
      setErrors(newErrors);
    }
  };

  const setInfo = (key) => (event) =>
    setData({ ...data, [key]: event.target.value });

  return (
    <>
      <Grid item xs={12}>
        <Input
          label='Número de Tarjeta'
          fullWidth
          value={data.numCard}
          onChange={setInfo('numCard')}
          onBlur={isNotValid('numCard', 16)}
          helperText={errors.numCard}
          required
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          label='Mes'
          fullWidth
          value={data.month}
          onChange={setInfo('month')}
          onBlur={isNotValid('month', 2)}
          helperText={errors.month}
          required
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          label='Año'
          fullWidth
          value={data.year}
          onChange={setInfo('year')}
          onBlur={isNotValid('year', 4)}
          helperText={errors.year}
          required
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          label='CVC'
          fullWidth
          value={data.cvc}
          onChange={setInfo('cvc')}
          onBlur={isNotValid('cvc', 3)}
          helperText={errors.numCard}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label='Nombre del Titular'
          fullWidth
          value={data.name}
          onChange={setInfo('name')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1' gutterBottom>
          Si eliges pago con tarjeta,{' '}
          <b>revisa tu estado de cuenta para confirmar</b> que el cobro se te{' '}
          <b>ha aplicado de manera correcta.</b> <br />
          <br />
          <strong>
            No recargues esta página hasta que se complete el pago.
          </strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <img src={visa} className={classes.tarjetaImg} />
        <img src={mastercard} className={classes.tarjetaImg} />
        <img src={americanexpress} className={classes.tarjetaImg} />
      </Grid>
    </>
  );
};

const PagoOxxo = () => {
  const classes = useStyles();
  return (
    <>
      <Grid item container spacing={3} alignItems='stretch'>
        <Grid item xs={12}>
          <Typography variant='body1' style={{ fontWeight: 600 }}>
            Genera una referencia para realizar el pago.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>
            El pago en efectivo genera una referencia que sólo es válida para
            pagar en OXXO.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <img src={oxxo} className={classes.paymentImg} />
      </Grid>
    </>
  );
};

const PagoSpei = () => {
  const classes = useStyles();
  return (
    <>
      <Grid item container spacing={3} alignItems='stretch'>
        <Grid item xs={12}>
          <Typography variant='body1' style={{ fontWeight: 600 }}>
            Genera una referencia para realizar el pago.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>
            El pago por transferencia genera una referencia la cual deberá ser
            pagada accediendo a su portal bancario donde deberá especificar los
            datos generados.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <img src={spei} className={classes.speiImg} />
      </Grid>
    </>
  );
};

const textoOxxo = (
  <>
    <li>Acude a la tienda OXXO preferida.</li>
    <li>Solicita en caja un pago de OXXOPay.</li>
    <li>Dicta al cajero el número de referencia en esta ficha.</li>
    <li>Realiza el pago con dinero en efectivo.</li>
    <li>
      Al confirmar tu pago, el cajero te entregará un comprobante impreso.
      Verifica que se haya hecho correctamente.
    </li>
    <li>
      Conserva el comprobante de pago. Al completar estos pasos recibirás un
      correo confirmando tu pago.
    </li>
  </>
);

const textoSpei = (
  <>
    <li>Accede a tu banca en línea.</li>
    <li>Da de alta la CLABE de esta ficha.</li>
    <li>
      Realiza la transferencia por la cantidad exacta, de lo contrario tu pago
      se rechazará.
    </li>
    <li>
      Al confirmar tu pago, tu banco generará un comprobante digital.
      ¡Consérvalo!
    </li>
    <li>
      Al completar estos pasos, recibirás un correo electrónico confirmando tu
      pago.
    </li>
  </>
);

export default Pago;
