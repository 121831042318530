import React, { useEffect, useState } from 'react';

import { Typography, Box, Snackbar } from '@material-ui/core';
import { SelectPaymentMethod } from './SelectPaymentMethod';
import { PaymentMethodWrapper } from './PaymentMethodWrapper';
import { Loader } from './components/Loader';
import { PaymentResult } from './PaymentResult';
import { Alert } from '@material-ui/lab';
require('conekta-card-tokenizer');

const Prepago = ({ back, headerState }) => {
  const [selected, setSelected] = useState('oxxo');
  const [responseData, setResponseData] = useState({
    paymentMethod: '',
    error: null,
    result: null,
    status: 'idle',
  });
  const [paymentData, setPaymentData] = useState(headerState.paymentInfo);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (responseData.status === 'error') {
      setTimeout(() => {
        setResponseData({ ...responseData, status: 'idle' });
      }, 3000);
    }
  }, [responseData]);

  return (
    <>
      {responseData.status === 'loading' && <Loader />}
      <Box
        padding={['24px', '32px']}
        display='flex'
        flexDirection='column'
        gridRowGap='24px'
        style={{ background: '#fff' }}>
        {responseData.status === 'success' ? (
          <PaymentResult responseData={responseData} back={back} />
        ) : (
          <>
            <Typography
              variant='h2'
              style={{ fontSize: '24px', color: '#21594F' }}>
              Selecciona tu forma de pago
            </Typography>
            <Box display='flex' gridColumnGap='24px'>
              <Typography variant='body1' style={{ fontWeight: 700 }}>
                Total a pagar:
                <span style={{ color: '#B261C4' }}>
                  {' '}
                  ${paymentData.amount} {paymentData.currency.toUpperCase()}
                </span>
              </Typography>
              <Typography variant='body1' style={{ fontWeight: 700 }}>
                Concepto:{' '}
                <span style={{ color: '#B261C4' }}>{paymentData.concept}</span>
              </Typography>
            </Box>
            <SelectPaymentMethod
              setSelected={setSelected}
              selected={selected}
            />
            <PaymentMethodWrapper
              selected={selected}
              paymentData={paymentData}
              setPaymentData={setPaymentData}
              setResponseData={setResponseData}
              responseData={responseData}
              back={back}
            />
          </>
        )}
      </Box>
      <Snackbar
        open={responseData.status === 'error'}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity='error' variant='filled' sx={{ width: '100%' }}>
          {responseData.error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Prepago;
