import { createTheme } from '@material-ui/core/styles';

//Fonts
import QuicksandTtf from './fuentes/Quicksand/Quicksand-Regular.ttf';

const quicksand = {
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Quicksand'),
    local('Quicksand-Regular'),
    url(${QuicksandTtf}) format('ttf')
  `,
};

const theme = createTheme({
  palette: {
    primary: {
      light: '#B5E3DB',
      main: '#61C4B2',
      dark: '#3DA491',
      contrastText: '#fff',
    },
    secondary: {
      light: '#C588D3',
      main: '#B261C4',
      dark: '#773286',
      contrastText: '#fff',
    },
    grey: {
      main: '#ACA7B4',
      dark: '#6E6779',
      light: '#D6D3D9',
    },
    warning: {
      main: '#FFC107',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Quicksand',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [quicksand],
      },
    },
  },
});

theme.typography.h1 = {
  color: '#1D1B20',
  lineHeight: '1.167',
  fontWeight: '300',
  fontFamily: 'Poppins',
  [theme.breakpoints.up('xl')]: {
    fontSize: '6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '4.2rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '3.7rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '2.1rem',
  },
};

theme.typography.h2 = {
  color: '#1D1B20',
  lineHeight: '1.2',
  fontFamily: 'Poppins',
  [theme.breakpoints.down('xl')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.2rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '1.75rem',
  },
};

theme.typography.h3 = {
  color: '#1D1B20',
  lineHeight: '1.2',
  fontFamily: 'Poppins',
  [theme.breakpoints.down('xl')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '2.2rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2.2rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '1.3rem',
  },
};

theme.typography.h4 = {
  color: '#1D1B20',
  lineHeight: '1.235',
  fontWeight: '400',
  fontFamily: 'Poppins',
  [theme.breakpoints.down('xl')]: {
    fontSize: '2.2rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.75rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '1.1rem',
  },
};

theme.typography.h5 = {
  color: '#1D1B20',
  lineHeight: '1.334',
  fontWeight: '400',
  fontFamily: 'Poppins',
  [theme.breakpoints.down('xl')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.7rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.75rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '1rem',
  },
};

theme.typography.h6 = {
  color: '#1D1B20',
  fontSize: '1.25rem',
  lineHeight: '1.6',
  fontWeight: '700',
  fontFamily: 'Poppins',
  [theme.breakpoints.down('xl')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '.9rem',
  },
};

theme.typography.body1 = {
  color: '#1D1B20',
  fontSize: '1rem',
  lineHeight: '1.6',
  fontWeight: '500',
  fontFamily: 'Quicksand',
  [theme.breakpoints.down('xl')]: {
    fontSize: '1.25rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '.9rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '.85rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '.8rem',
  },
};

theme.typography.caption = {
  color: '#1D1B20',
  fontSize: '0.95rem',
  lineHeight: '1.66',
  fontWeight: '400',
  fontFamily: 'Lato',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.85rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '0.75rem',
  },
};

export default theme;
