import React from 'react';

//Components
import Button from '@material-ui/core/Button';

//Styles
import {
  createTheme,
  withStyles,
  ThemeProvider,
} from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: { main: '#61C4B2' },
    secondary: { main: '#B261C4' },
  },
  overrides: {
    MuiButton: {
      contained: {
        color: 'white',
        backgroundColor: '#999ef5',
        '&:hover': {
          backgroundColor: '#6c71c8',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: '#6c71c8',
          },
        },
      },
    },
  },
});

const BootstrapButton = withStyles((theme) => ({
  root: {
    color: 'white',
    padding: '8px 16px',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 14px',
    },
    '&:hover': {
      borderWidth: '2px',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
}))(Button);

const CustomButton = ({
  color,
  type,
  disabled,
  onClick,
  required,
  children,
  ...props
}) => {
  //const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <BootstrapButton
        color={color ? color : 'primary'}
        variant='contained'
        type={type ? type : 'button'}
        disabled={disabled}
        onClick={onClick}
        required={required}
        {...props}>
        {children}
      </BootstrapButton>
    </ThemeProvider>
  );
};

export default CustomButton;
