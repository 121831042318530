import React, { useEffect, useContext, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { sesionStore } from '../utils/sesionStore';
import { NavLink as Link } from 'react-router-dom';
import Button from '../componentes/utils/Button';
import Input from '../componentes/utils/Input';

//Styles
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(245,245,245)',
    padding: '5% 5%',
    width: '100%',
  },
}));

const Validation = (props) => {
  const global = useContext(sesionStore);
  const { dispatch } = global;

  const [usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');

  const { history } = props;
  const { hash, matricula } = useParams();

  const [valid, setValid] = useState(false);

  const classes = useStyles();

  const validate = async () => {
    const axios = await import('axios');

    try {
      const { data } = await axios.put(
        `${process.env.PREPA_SERVER}estudiantes/${matricula}/validate?hash=${hash}`
      );

      console.log(data);
      setValid(true);
    } catch (error) {
      console.log(error);
      dispatch({
        type: 'ALERT',
        payload: {
          open: true,
          message: error.response.data.message,
          type: 'warning',
        },
      });
    }
  };

  useEffect(() => {
    if (!hash || !matricula) history.push('/');
    else {
      validate();
    }
  }, []);

  const fetchData = async (user, password) => {
    const axios = await import('axios');
    const body = {
      usuario: user,
      password: password,
    };

    try {
      const { data } = await axios.post(
        process.env.PREPA_SERVER + 'estudiantes/signin',
        body
      );

      dispatch({ type: 'FETCH_SESION', payload: data });
      history.push('/inscripciones');
    } catch (error) {
      dispatch({ type: 'CLEAR_SESION' });
      dispatch({
        type: 'ALERT',
        payload: {
          open: true,
          message: error.response.data.message,
          type: 'warning',
        },
      });
    }
  };

  const login = async (e) => {
    e.preventDefault();

    if (usuario == '') {
      dispatch({
        type: 'ALERT',
        payload: {
          open: true,
          message: 'Ingrese el usuario por favor',
          type: 'error',
        },
      });
      return false;
    } else if (password == '') {
      dispatch({
        type: 'ALERT',
        payload: {
          open: true,
          message: 'Ingrese su password por favor',
          type: 'error',
        },
      });
      return false;
    }

    fetchData(usuario, password);
  };

  return valid ? (
    <div className={classes.root}>
      <Typography variant='h3'>
        Tu correo ha sido validado con éxito. Por favor, inicia sesión para
        comenzar con la selección de materias.
      </Typography>
      <br />
      <form style={{ padding: '5% 10% 0 10%' }} onSubmit={login}>
        <Typography variant='h3'>
          <b>Inicia Sesión</b>
        </Typography>
        <br />
        <Input
          id='user'
          label='Usuario'
          name='user'
          onChange={(event) => setUsuario(event.target.value)}
          fullWidth
        />
        <br />
        <br />
        <Input
          id='password'
          type='password'
          label='Contraseña'
          name='password'
          onChange={(event) => setPassword(event.target.value)}
          fullWidth
        />
        <br />
        <Typography variant='caption' align='right'>
          ¿Olvidaste tu contraseña? Por favor, comunicate con nuestro equipo de
          soporte.
        </Typography>
        <br />
        <br />
        <Button type='submit' onClick={login}>
          Iniciar Sesión
        </Button>
        <br />
        <br />
      </form>
    </div>
  ) : (
    <div className={classes.root}>
      <Typography variant='h3'>Tu cuenta ya ha sido verificada</Typography>
      <Typography variant='h6'>
        No es necesario que utilices este link para entrar a tu cuenta, desde{' '}
        <Link className='link' to='/cuenta'>
          aquí
        </Link>{' '}
        para continuar con tu selección de materias.
      </Typography>
    </div>
  );
};

export default Validation;
