import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@material-ui/core';
import { gradesComponentsStyles } from './styles';

const StyledTableCell = styled(TableCell)(() => ({
  border: 'none',
  padding: '16px 0',
}));

export const TableHeaders = ({ headers }) => {
  const classes = gradesComponentsStyles();
  const defineAlign = (index) => {
    if (index === 0) {
      return 'left';
    } else if (index === headers.length - 1 && headers.length > 3) {
      return 'right';
    } else {
      return 'center';
    }
  };
  return (
    <TableHead>
      <TableRow>
        {headers.map((header, index) => (
          <StyledTableCell key={header} align={defineAlign(index)}>
            <Typography variant='body2' className={classes.tableHeader}>
              {header}
            </Typography>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

TableHeaders.propTypes = {
  headers: PropTypes.array.isRequired,
};
