import * as R from 'ramda';
import {
  Box,
  CardHeader,
  CardContent,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import React from 'react';
import SeleccionMaterias from './SeleccionMaterias';
import Resultado from './Resultado';
import Pago from './Pago';
import HashLoader from 'react-spinners/HashLoader';
import {
  ReinscripcionStateProvider,
  ReinscripcionContext,
} from './ReinscripcionContext';
import { sesionStore } from '../../../../utils/sesionStore';
import { PERIODO_REINSCRIPCION } from '../../../../API';
import * as localStore from '../../../../utils/localStore';

const Acciones = () => {
  return (
    <Box
      style={{ width: '100%', backgroundColor: '#fff', borderRadius: '8px' }}
      elevation={2}>
      <Header />
      <CardContent>
        <ReinscripcionStateProvider>
          <View />
        </ReinscripcionStateProvider>
      </CardContent>
      <Footer />
    </Box>
  );
};

const View = () => {
  const { state: sesionState, dispatch } = React.useContext(sesionStore);
  const { sesion } = sesionState;
  const [state, setState] = React.useContext(ReinscripcionContext);
  const { vista } = state;
  const [periodoReinscripcion, setPeriodoReinscripcion] = React.useState(null);
  const { hash } = localStore.getItems('educsesion');

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const response = await PERIODO_REINSCRIPCION();
        setPeriodoReinscripcion(response.data.periodo);
      } catch (error) {
        console.log('Error', error.response.data?.message || error);
      }
    };
    fetch();
  }, []);

  React.useEffect(() => {
    if (sesion.EstatusId === 11 && periodoReinscripcion != null) {
      const periodo = periodoReinscripcion.periodo;
      const dataPeriodo = R.find((p) => p.Periodo.periodo === periodo)(
        sesion.Periodos
      );
      const materias = dataPeriodo.Materias;
      setState({
        ...state,
        vista: 'pago',
        materiasElegidas: materias,
        usuario: sesion,
        jwt: hash,
      });
    }
  }, [periodoReinscripcion, sesion]);

  const selectView = R.cond([
    [() => vista === 'materias', R.always(<SeleccionMaterias />)],
    [() => vista === 'pago', R.always(<Pago />)],
    [() => vista === 'resultado', R.always(<Resultado />)],
    [(R.T, R.always(<Loading text='Cargando...' />))],
  ]);

  return selectView();
};

const Loading = ({ text }) => {
  return (
    <Box
      style={{
        display: 'flex',
        width: '100%',
        top: '50%',
        left: '50%',
        marginTop: '64px',
      }}>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
        direction='row'>
        <Grid item xs={12}>
          <HashLoader
            loading={true}
            size={100}
            cssOverride={{ width: 'auto' }}
            color='#61C4B2'
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5' align='center'>
            <strong>{text}</strong>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const HeaderText = ({ children }) => {
  return (
    <p
      style={{
        margin: 0,
        textAlign: 'right',
        color: '#6E6779',
        fontSize: '12px',
      }}>
      {children}
    </p>
  );
};

const Header = () => {
  return (
    <CardHeader
      title={
        <Box display='flex' flexDirection='column' gridRowGap='4px'>
          <HeaderText>BACHILLERATO EDUCACION PRACTICA PARA LA VIDA</HeaderText>
          <HeaderText>
            RVOE <b>13/990</b> CCT <b>21PBH3845N</b>
          </HeaderText>
          <HeaderText>DIRECCION GENERAL DE BACHILLERATO</HeaderText>
          <HeaderText>
            OPCIÓN EDUCATIVA <b>VIRTUAL</b> MODALIDAD <b>NO ESCOLARIZADA</b>
          </HeaderText>
        </Box>
      }
    />
  );
};

const Footer = () => {
  return (
    <Box padding='16px' display='flex' justifyContent='end'>
      <Typography variant='overline' align='center'>
        <strong>
        ¿Problemas con tu inscripción?,{' '}
          <Link
            href='https://discord.com/channels/916299310558216222/1006232509425664091'
            color='secondary'
            underline='always'
            target='_blank'
            rel='noreferrer'>
            Escríbenos en el canal #inscripciones-a-prepaenlinea en Discord.
          </Link>
        </strong>
      </Typography>
    </Box>
  );
};

export default Acciones;
