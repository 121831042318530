import React from 'react';
import { TableCell, TableRow, styled } from '@material-ui/core';
import PropTypes from 'prop-types';

const StyledTableRow = styled(TableRow)(() => ({
  border: 'none',
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: 'none',
  padding: '16px 0',
  '&:first-child': {
    width: '60%',
  },
}));

export const SubjectRow = ({ subject }) => {
  return (
    <StyledTableRow>
      <StyledTableCell align='left'>{subject.nombre}</StyledTableCell>
      <StyledTableCell align='center'>{subject.calificacion}</StyledTableCell>
      <StyledTableCell align='center'>{subject.grado}</StyledTableCell>
    </StyledTableRow>
  );
};

SubjectRow.propTypes = {
  subject: PropTypes.object.isRequired,
};
