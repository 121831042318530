import React from 'react';
import { Box } from '@material-ui/core';
import { useGetStudent } from './useGetStudent';
import { StudentProfile } from './views/StudentProfile';
import { PaymentFlow } from './views/PaymentFlow';
import { ReinscripcionView } from './views/ReinscripcionView';

const Portada = () => {
  const {
    student,
    Persona,
    generalInfo,
    studentInfo,
    currentSubjects,
    isEdit,
    setIsEdit,
    obligated,
    messages,
    setMessages,
    setObligated,
    hasDebt,
    headerState,
    setHeaderState,
    noGrades,
  } = useGetStudent();

  const hasActa = Persona?.documentoOficial;
  const hasCurp = Persona?.documentoCurp;
  const hasCert = student?.documentoCertificadoSecundaria;

  const isGraduated = student?.EstatusId === 5;
  const isDeactivated = student?.EstatusId === 6;

  const personalDocuments = [
    { title: 'Acta de nacimiento', hasDocument: hasActa, key: 'acta' },
    { title: 'CURP', hasDocument: hasCurp, key: 'curp' },
    {
      title: 'Certificado de secundaria',
      hasDocument: hasCert,
      key: 'certificado',
    },
  ];

  const academicDocuments = [
    { title: 'Historial', key: 'historial' },
    { title: 'Credencial', key: 'credencial' },
    { title: 'Constancia digital', key: 'constancia' },
  ];

  if (isGraduated) {
    academicDocuments.push({
      title: 'Constancia de finalización',
      key: 'finalizacion',
      notEnglish: true,
    });
  }

  const views = () => {
    if (headerState.showPaymentFlow) {
      return (
        <PaymentFlow
          headerState={headerState}
          setHeaderState={setHeaderState}
        />
      );
    } else if (headerState.showReinscripcionFlow) {
      return <ReinscripcionView />;
    } else {
      return (
        <StudentProfile
          {...{
            isEdit,
            setIsEdit,
            obligated,
            messages,
            setMessages,
            setObligated,
            hasDebt,
            headerState,
            setHeaderState,
            student,
            Persona,
            generalInfo,
            studentInfo,
            currentSubjects,
            academicDocuments,
            personalDocuments,
            isDeactivated,
            isGraduated,
            noGrades,
          }}
        />
      );
    }
  };

  return (
    <Box
      style={{
        marginTop: '90px',
        padding: 0,
        height: '100%',
        background: '#F3F4F6',
        width: '100%',
      }}>
      <Box
        padding={['0', '0', '0', '50px 0']}
        style={{
          maxWidth: headerState.showPaymentFlow ? '600px' : '1280px',
          margin: '24px auto 0',
          borderRadius: '8px',
        }}>
        {views()}
      </Box>
    </Box>
  );
};

export default Portada;
