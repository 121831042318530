import React, { Suspense, lazy, useEffect } from 'react';
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { SesionProvider } from './utils/sesionStore.js';

//Views
import Home from './vistas/Home';
import Informacion from './vistas/Informacion';
import Curricula from './vistas/Curricula';
import Inscripciones from './vistas/Inscripciones';
import Cuenta from './vistas/Cuenta';
import Maintenance from './vistas/Maintenance';
import Terminos from './vistas/Terminos';
import Validation from './vistas/Validation';
import AvisoPrivacidad from './vistas/AvisoPrivacidad';
import NotFound from './404';
import Precios from './vistas/Precios';
import ChangePassword from './vistas/ChangePassword.js';

//Components
import Header from './componentes/Header';
import { CssBaseline, LinearProgress as Loading } from '@material-ui/core';
import Dial from './componentes/Dial';

const Footer = lazy(() => import('./componentes/Footer'));
const Alertas = lazy(() => import('./subvistas/Common/Alertas'));

import MainLoading from './componentes/MainLoading';

//Theme
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { GlobalProvider } from './utils/globalStore.js';

const App = () => {
  useEffect(() => {
    if (!process.env.TEST)
      import('react-gtm-module').then((TagManager) => {
        TagManager.initialize({
          gtmId: 'GTM-PZFRBBP',
        });
      });
  }, []);

  return process.env.MAINTENANCE ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<MainLoading />}>
        <Maintenance />
      </Suspense>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <GlobalProvider>
        <SesionProvider>
          <CssBaseline />
          <Suspense fallback={<Loading />}>
            <Alertas />
          </Suspense>
          <Router>
            <Header>
              <Switch>
                <Route path='/inicio' component={Home} />
                <Route path='/informacion' component={Informacion} />
                <Route path='/curricula' component={Curricula} />
                <Route exact path='/inscripciones' component={Inscripciones} />
                <Route path='/inscripciones/:id' component={Inscripciones} />
                <Route
                  path='/validation/:matricula/:hash'
                  component={Validation}
                />
                <Route path='/precios' component={Precios} />
                <Route path='/terminos-y-condiciones' component={Terminos} />
                <Route
                  path='/aviso-de-privacidad'
                  component={AvisoPrivacidad}
                />
                <Route path='/cuenta' component={Cuenta} />
                <Route path='/change-password' component={ChangePassword} />
                <Route path='/' exact>
                  <Redirect to='/inicio' component={Home} />
                </Route>
                <Route path='*' component={NotFound} />
              </Switch>
            </Header>
            <Dial />
            <Suspense fallback={<Loading />}>
              <Footer />
            </Suspense>
          </Router>
        </SesionProvider>
      </GlobalProvider>
    </ThemeProvider>
  );
};

export default App;
