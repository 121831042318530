import React, { Fragment, useEffect, useContext, useState, lazy } from 'react';

import { sesionStore } from '../utils/sesionStore';
import Login from '../subvistas/Cuenta/Login';
import Portada from '../subvistas/Cuenta/Portada';
const Soporte = lazy(() => import('../subvistas/Cuenta/Soporte'));
const Plataforma = lazy(() => import('../subvistas/Cuenta/Plataforma'));

const Cuenta = () => {
  const global = useContext(sesionStore);
  const { state } = global;
  const { sesion } = state;

  const [logged, setLogged] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLogged(sesion != null);
  }, [sesion]);

  return <Fragment>{!logged ? <Login /> : <Portada />}</Fragment>;
};

export default Cuenta;
