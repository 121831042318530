import React from 'react';
import { Box } from '@material-ui/core';
import { listStyles } from './List.style';

export const List = ({ title, rows, type, maxHeight }) => {
  const classes = listStyles({ type, maxHeight });
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <p className={classes.title}>{title}</p>
      </Box>
      <Box className={classes.rows}>{rows}</Box>
    </Box>
  );
};
