import { makeStyles } from '@material-ui/core';

export const generalAcademicInfoStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    columnGap: '16px',
    flexWrap: 'wrap',
    rowGap: '16px',
    justifyContent: 'center',
    '@media (min-width: 768px)': {
      columnGap: '32px',
      flexWrap: 'nowrap',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '8px 16px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    width: 'calc((100% / 2) - 8px)',
    '@media (min-width: 768px)': {
      width: 'calc(100% / 4)',
    },
  },
  value: {
    margin: 0,
    fontFamily: 'Quicksand',
    fontSize: '16px',
    fontWeight: 700,
    color: '#B261C4',
  },
  label: {
    margin: 0,
    fontFamily: 'Quicksand',
    fontSize: '16px',
    color: '#1D1B20',
  },
}));
