import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { ReferencePayment } from './ReferencePayment';
import { CustomButton } from '../../CustomButton';
import { PaymentWithCard } from './PaymentWithCard';
import { useCardPayments } from './useCardPayments';
import { useReferencePayment } from './useReferencePayment';

export const PaymentMethodWrapper = ({
  selected,
  paymentData,
  setPaymentData,
  responseData,
  setResponseData,
  back,
}) => {
  const [disabledSubmit, setDisabledSubmit] = React.useState(true);
  const { generateTokens } = useCardPayments({ paymentData, setResponseData });
  const { offlinePayment } = useReferencePayment({
    paymentData,
    setResponseData,
    paymentType: selected,
  });

  const buttonTitle = {
    oxxo: 'Generar referencia',
    spei: 'Generar referencia',
    card: 'Pagar',
  };
  const submitFunctions = {
    card: generateTokens,
    oxxo: offlinePayment,
    spei: offlinePayment,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseData({ ...responseData, status: 'loading' });
    await submitFunctions[selected]();
  };

  useEffect(() => {
    if (selected === 'card') {
      if (
        paymentData.amount &&
        paymentData.currency &&
        paymentData.cardUser &&
        paymentData.cardNumber &&
        paymentData.cardExpirationMonth &&
        paymentData.cardExpirationYear &&
        paymentData.cardCVC
      ) {
        setDisabledSubmit(false);
      } else {
        setDisabledSubmit(true);
      }
    } else {
      if (paymentData.amount && paymentData.currency) {
        setDisabledSubmit(false);
      } else {
        setDisabledSubmit(true);
      }
    }
  }, [paymentData, selected]);

  return (
    <form onSubmit={handleSubmit}>
      <Box display='flex' flexDirection='column' gridRowGap='24px'>
        {selected === 'oxxo' && (
          <ReferencePayment
            paymentData={paymentData}
            setPaymentData={setPaymentData}
          />
        )}
        {selected === 'spei' && (
          <ReferencePayment
            paymentData={paymentData}
            setPaymentData={setPaymentData}
          />
        )}
        {selected === 'card' && (
          <PaymentWithCard
            paymentData={paymentData}
            setPaymentData={setPaymentData}
          />
        )}
        <Box display='flex' justifyContent='end' gridColumnGap='24px'>
          <CustomButton onClick={back} variant='secondary'>
            Regresar al perfil
          </CustomButton>
          <CustomButton type='submit' disabled={disabledSubmit}>
            {buttonTitle[selected]}
          </CustomButton>
        </Box>
      </Box>
    </form>
  );
};
