import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Avatar,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { handleImage } from '../../../../utils/utils';
import { sesionStore } from '../../../../utils/sesionStore';
import { fileToBase64, handleAlerts } from '../../../../utils';
import * as store from '../../../../utils/localStore';
import axios from 'axios';

export const ImageSelector = ({
  image,
  setImage,
  openDialog,
  setOpenDialog,
}) => {
  const global = useContext(sesionStore);
  const { state, dispatch } = global;
  const { sesion } = state;
  const { successMessage, errorMessage } = handleAlerts();

  const [dialogStepper, setDialogStepper] = useState(0);

  const uploadImage = async () => {
    const fotob64 = await fileToBase64(image.raw);
    if (fotob64.error) {
      errorMessage(
        'Ha ocurrido un error al convertir la imagen, por favor intente de nuevo o con otro archivo.'
      );
    } else {
      try {
        const { hash } = store.getItems('educsesion');
        const config = {
          headers: {
            Authorization: `Bearer ${hash}`,
            'content-type': 'multipart/form-data',
          },
        };
        const formData = new FormData();
        formData.append('fotografia', image?.raw);
        const { data } = await axios.post(
          `${process.env.PREPA_SERVER}estudiantes/${sesion.matricula}/uploadFoto/`,
          formData,
          config
        );
        successMessage(data.message);
        setOpenDialog(false);
      } catch (error) {
        errorMessage(error.response.data?.message || error);
      }
    }
  };

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setDialogStepper(0);
        }}
        fullWidth>
        <DialogTitle>Actualizar foto</DialogTitle>
        <Stepper orientation='vertical' activeStep={dialogStepper}>
          <Step key={0}>
            <StepLabel>Considera que la fotografía</StepLabel>
            <StepContent>
              <ul>
                <li>Debe estar en formato vertical</li>
                <li>Aparecerá así en los documentos oficiales</li>
                <li>La fotogrqfía es responsabilidad del alumno</li>
              </ul>
            </StepContent>
          </Step>
          <Step key={1}>
            <StepLabel>Selecciona tu foto</StepLabel>
            <Grid container component={StepContent} justifyContent='center'>
              <input
                type='file'
                id='imginput'
                name='img'
                onChange={(event) => {
                  handleImage(event.target.files[0], dispatch, setImage);
                }}
                hidden
              />
              {image ? (
                <Grid item xs={12}>
                  <Avatar
                    style={{
                      width: 100,
                      height: 100,
                      backgroundColor: '#D4A6DE',
                    }}
                    src={image.url}
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  component={IconButton}
                  style={{
                    width: '250px',
                    height: '250px',
                  }}
                  onClick={() => {
                    document.getElementById('imginput').click();
                  }}>
                  <Add />
                </Grid>
              )}
            </Grid>
          </Step>
          <Step key={2}>
            <StepLabel>Confirmar</StepLabel>
            <Box
              sx={{ display: 'flex', height: '250px' }}
              fullWidth
              component={StepContent}>
              <CircularProgress />
            </Box>
          </Step>
        </Stepper>
        <DialogActions>
          {dialogStepper == 2 ? (
            <></>
          ) : (
            <Button
              onClick={() => {
                switch (dialogStepper) {
                  case 0:
                    setDialogStepper((curr) => curr + 1);
                    break;
                  case 1:
                    uploadImage();
                    setDialogStepper((curr) => curr + 1);
                    break;
                }
              }}
              disabled={dialogStepper == 1 && image == null}>
              {['Lo entiendo', 'Cargar fotografía'][dialogStepper]}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};
