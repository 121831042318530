import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { CustomButton } from '../../../../../componentes/CustomButton';
import { WarningAlert } from './components/WarningAlert';
import { CallToActionAlert } from './components/CallToActionAlert';
import { MessageAlert } from './components/MessageAlert';

/**
 * StudentAlerts component displays various alerts and call-to-action messages
 * based on the current state of the header.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setHeaderState - Function to update the header state.
 * @param {Object} props.headerState - The current state of the header.
 * @param {string} props.headerState.nameOfTheComponentToShow - The name of the component to show.
 * @param {Object} [props.headerState.paymentInfo] - Payment information.
 * @param {number} [props.headerState.paymentInfo.amount] - The amount to be paid.
 * @param {string} [props.headerState.paymentInfo.currency] - The currency of the payment.
 * @param {Array<string>} [props.headerState.missingDocuments] - List of missing documents.
 * @param {string} [props.headerState.documentTrackId] - The tracking ID for the documents.
 * @param {string} [props.headerState.reinscripcionLabel] - The label for the re-enrollment button.
 * @param {boolean} [props.headerState.showPaymentFlow] - Flag to show the payment flow.
 * @param {boolean} [props.headerState.showReinscripcionFlow] - Flag to show the re-enrollment flow.
 * @returns {JSX.Element} The rendered component.
 */

export const StudentAlerts = ({
  setHeaderState,
  headerState,
  setOpenConfirmDocuments,
  setOpenSelectGraduatedDocuments,
}) => {
  const { nameOfTheComponentToShow } = headerState;
  return (
    <>
      {/*This component displays an alert when the student needs to pay the tuition before registering.*/}
      {nameOfTheComponentToShow === 'payBeforeRegister' && (
        <WarningAlert>
          <Box display='flex' flexDirection='column' gridGap='16px'>
            <Box>
              <Typography
                variant='body2'
                style={{ color: '#fff', fontWeight: 700 }}>
                Para poder reinscribirte, primero debes pagar tu colegiatura.
              </Typography>
              <Typography
                variant='body2'
                style={{ color: '#fff', fontWeight: 700 }}>
                El monto a pagar es de ${headerState.paymentInfo.amount}{' '}
                {headerState.paymentInfo.currency.toUpperCase()}
              </Typography>
            </Box>
            <CustomButton
              style={{ backgroundColor: '#60C3B0' }}
              onClick={() => {
                setHeaderState({ ...headerState, showPaymentFlow: true });
              }}>
              Pagar colegiatura
            </CustomButton>
            <p
              style={{
                color: '#fff',
                fontWeight: 700,
                fontSize: '12px',
                margin: 0,
              }}>
              ** Una vez realizado el pago, pide ayuda a control escolar
            </p>
          </Box>
        </WarningAlert>
      )}
      {/*This component displays the button for paying the tuition*/}
      {nameOfTheComponentToShow === 'payTuitionButton' && (
        <CallToActionAlert
          message='Da click en el botón para pagar tu colegiatura'
          buttonText='Pagar colegiatura'
          onClickAction={() => {
            setHeaderState({ ...headerState, showPaymentFlow: true });
          }}
        />
      )}
      {/*This component displays the button for re-enrolling in the next school year*/}
      {nameOfTheComponentToShow === 'reinscripcionButton' && (
        <CallToActionAlert
          message='Reinscríbete para el siguiente ciclo escolar'
          buttonText={headerState.reinscripcionLabel}
          onClickAction={() =>
            setHeaderState({ ...headerState, showReinscripcionFlow: true })
          }
        />
      )}
      {/**
       * The following alerts correpond to the different states of the student's graduation process.
       * Important: The following alerts are commented out until further notice.
       **/}
      {nameOfTheComponentToShow === 'incompleteDocuments' && (
        <WarningAlert>
          <Typography variant='subtitle2' style={{ color: '#fff' }}>
            Para poder tramitar tu certificado, debes subir los siguientes
            documentos: &nbsp;
            <span style={{ fontWeight: 700 }}>
              {headerState.missingDocuments?.join(', ')}
            </span>
          </Typography>
        </WarningAlert>
      )}
      {nameOfTheComponentToShow === 'validateDocuments' && (
        <CallToActionAlert
          message='Para solicitar tus documentos de graduado debes confirmar que tus documentos personales, tu dirección, tu correo electrónico y el de tu tutor sean correctos.'
          buttonText='Confirmar'
          onClickAction={() => {
            setOpenConfirmDocuments(true);
          }}
        />
      )}
      {nameOfTheComponentToShow === 'validateDocumentsControl' && (
        <MessageAlert message='Control Escolar está revisando tu información. Espera el siguiente aviso por correo electrónico o por Discord.' />
      )}
      {nameOfTheComponentToShow === 'payDocumentButton' && (
        <CallToActionAlert
          message='Paga tus documentos de graduación'
          onClickAction={() => setOpenSelectGraduatedDocuments(true)}
          buttonText='Adquirir documentos de graduación'
        />
      )}
      {nameOfTheComponentToShow === 'waitingMessage' && (
        <MessageAlert message='Espera a que control escolar envíe tus documentos. El número de guía aparecerá aquí.' />
      )}
      {nameOfTheComponentToShow === 'documentsTrackId' && (
        <MessageAlert
          message={`Tu número de guía de envío de tu documentación es: ${
            headerState.documentTrackId || ''
          }`}
        />
      )}
    </>
  );
};
