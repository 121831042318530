import React from 'react';
import * as R from 'ramda';
import {
  Box,
  Table,
  TableBody,
  styled,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import { List } from '../components/List/List';
import { SectionHeader } from './Grades/components/SectionHeader';
import { TableHeaders } from './Grades/components/TableHeaders';

const StyledTableRow = styled(TableRow)(() => ({
  border: 'none',
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: 'none',
  padding: '16px 0',
  '&:first-child': {
    width: '25%',
  },
  '&:nth-child(2)': {
    width: '25%',
  },
  '&:nth-child(3)': {
    width: '25%',
  },
  '&:nth-child(4)': {
    width: '25%',
  },
}));

export const PaymentsHistory = ({ student }) => {
  const [movementsByTerms, setMovementsByTerms] = React.useState([]);

  React.useEffect(() => {
    const terms = student.Periodos;
    if (terms) {
      const termsWithMovements = R.sort(
        R.descend(R.compose(R.prop('periodo'), R.prop('Periodo')))
      )(R.filter((periodo) => !R.isEmpty(periodo.Movimientos))(terms));
      setMovementsByTerms(termsWithMovements);
    }
  }, [student]);

  const headers = ['Concepto', 'Monto', 'Fecha de Pago', 'Estatus'];

  const translateStatus = (status) => {
    switch (status) {
      case 'paid':
        return 'Pagado';
      case 'succeeded':
        return 'Pagado';
      case 'pending_payment':
        return 'Pendiente';
      case 'rejected':
        return 'Rechazado';
      default:
        return status;
    }
  };

  const formatDatePayment = (date) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(date).toLocaleDateString('es-MX', options);
  };

  return (
    <Box style={{ width: '100%' }}>
      <List
        title='Historial de Pagos'
        type='payments'
        maxHeight='600px'
        rows={
          <Box padding='16px 24px'>
            {movementsByTerms.length === 0 ? (
              <Typography variant='subtitle2'>
                No hay movimientos registrados
              </Typography>
            ) : (
              movementsByTerms.map((term) => (
                <Box sx={{ overflow: 'auto' }} key={term.Periodo.periodo}>
                  <Box
                    padding='16px 0'
                    key={term.Periodo.periodo}
                    borderBottom='1px solid #aca7b475'>
                    <SectionHeader title={term.Periodo.periodo} />
                    <Table>
                      <TableHeaders headers={headers} />
                      <TableBody>
                        {term.Movimientos.map((movement) => (
                          <StyledTableRow key={movement.id}>
                            <StyledTableCell align='left'>
                              <p>{movement.concepto}</p>
                            </StyledTableCell>
                            <StyledTableCell align='center'>
                              ${movement.cantidad} {movement.moneda}
                            </StyledTableCell>
                            <StyledTableCell align='center'>
                              {formatDatePayment(movement.fechaDeOperacion)}
                            </StyledTableCell>
                            <StyledTableCell
                              align='right'
                              style={{
                                color:
                                  movement.status === 'paid' ||
                                  movement.status === 'succeeded'
                                    ? '#61C480'
                                    : '#C46173',
                                fontWeight: 700,
                              }}>
                              {translateStatus(movement.status)}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              ))
            )}
          </Box>
        }
      />
    </Box>
  );
};
