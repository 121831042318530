import React from 'react';
// Libs
import { useFormik } from 'formik';
import * as yup from 'yup';
// Components
import { Box, IconButton, InputAdornment, Typography } from '@material-ui/core';
import Input from '../../componentes/utils/Input';
import { CustomButton } from '../../componentes/CustomButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
// API
import { POST_PASSWORD_STUDENT } from '../Inscripcion/Components/api';
// Utils
import { sesionStore } from '../../utils/sesionStore';
import { handleAlerts } from '../../utils';
import * as store from '../../utils/localStore';

const FormSchema = yup.object().shape({
  pass: yup
    .string()
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .matches(/[0-9]/, 'La contraseña debe tener al menos un número')
    .matches(/[a-z]/, 'La contraseña debe tener al menos una letra minúscula')
    .matches(/[A-Z]/, 'La contraseña debe tener al menos una letra mayúscula')
    .matches(/[^\w]/, 'La contraseña debe tener al menos un caracter especial'),
  confirm: yup
    .string()
    .oneOf([yup.ref('pass'), null], 'Las contraseñas no coinciden'),
});

export const ChangePasswordForm = () => {
  const global = React.useContext(sesionStore);
  const student = global.state.sesion;
  const { successMessage, errorMessage } = handleAlerts();
  const { hash } = store.getItems('educsesion');

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      const response = await POST_PASSWORD_STUDENT(
        student.Persona.UsuarioId,
        values.pass,
        values.confirm,
        hash
      );
      successMessage(response.data.message);
    } catch (error) {
      errorMessage(error.message || error.response?.data?.message || error);
    }
  };

  const formik = useFormik({
    initialValues: {
      pass: '',
      confirm: '',
    },
    validateOnChange: true,
    validationSchema: FormSchema,
    onSubmit: (values) => {
      handleSubmit(values);
      formik.resetForm();
    },
  });

  return (
    <Box
      display='flex'
      flexDirection='column'
      padding='48px'
      width={['90%', '500px']}
      margin='20vh auto 10vh'
      borderRadius='8px'
      gridRowGap='32px'
      style={{ backgroundColor: 'white' }}>
      <Box display='flex' flexDirection='column' gridRowGap='16px'>
        <Typography variant='h6' style={{ color: '#328677', fontWeight: 700 }}>
          Cambio de contraseña
        </Typography>
        <Box display='flex' flexDirection='column' gridRowGap='8px'>
          <Typography
            variant='subtitle1'
            style={{ color: '#B261C4', fontWeight: 700 }}>
            Por favor, ingresa tu nueva contraseña.
          </Typography>
          <Typography variant='body1'>
            Ésta debe tener al menos 8 caracteres, una letra mayúscula, una
            minúscula, un número y un caracter especial.{' '}
          </Typography>
        </Box>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box display='flex' flexDirection='column' gridRowGap='16px'>
          <Input
            id='password'
            label='Nueva contraseña'
            name='pass'
            type={showPassword ? 'text' : 'password'}
            onChange={formik.handleChange('pass')}
            value={formik.values?.pass}
            error={!!formik.errors.pass}
            helperText={formik.errors.pass}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Input
            id='confirm-password'
            label='Confirmar contraseña'
            name='confirm'
            type={showConfirm ? 'text' : 'password'}
            onChange={formik.handleChange('confirm')}
            value={formik.values?.confirm}
            error={!!formik.errors.confirm}
            helperText={formik.errors.confirm}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => {
                    setShowConfirm(!showConfirm);
                  }}>
                  {showConfirm ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
        <Box display='flex' justifyContent='end' marginTop='24px'>
          <CustomButton type='submit'>Guardar</CustomButton>
        </Box>
      </form>
    </Box>
  );
};
