import React from 'react';
import { Box } from '@material-ui/core';
import { List } from '../components/List/List';
import Grades from './Grades/Grades';

export const AcademicHistory = ({ student }) => {
  return (
    <Box style={{ width: '100%' }}>
      <List
        title='Historial académico'
        maxHeight='600px'
        type='academicHistory'
        rows={<Grades student={student} />}
      />
    </Box>
  );
};
