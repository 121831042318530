import React, { Fragment, useEffect, useContext, useState } from 'react';

import { sesionStore } from '../utils/sesionStore';
import Login from '../subvistas/Cuenta/Login';
import { ChangePasswordForm } from '../subvistas/Cuenta/ChangePasswordForm';

const ChangePassword = () => {
  const global = useContext(sesionStore);
  const { state } = global;
  const { sesion } = state;

  const [logged, setLogged] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLogged(sesion != null);
  }, [sesion]);

  return <Fragment>{!logged ? <Login /> : <ChangePasswordForm />}</Fragment>;
};

export default ChangePassword;
