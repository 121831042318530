import * as R from 'ramda';
import React from 'react';
import { sesionStore } from './sesionStore';

const mapInd = R.addIndex(R.map);

export const backend = process.env.PREPA_SERVER;
export const TEST = process.env.TEST;
export const BUCKET = process.env.BUCKET_S3;

export const hasHistorial = (estudiante) => {
  const historial = estudiante?.Periodos;
  return !R.isNil(historial) && !R.isEmpty(historial);
};

export const hasPeriodo = (estudiante, periodoId) =>
  hasHistorial(estudiante)
    ? !R.isNil(
        R.find((periodo) => periodo.Periodo.periodo === periodoId)(
          estudiante.Periodos
        )
      )
    : false;

export const cuentaOpcionales = (courses) =>
  R.transduce(
    R.compose(
      R.filter((materia) => materia.Calificacion?.valor >= 7),
      R.filter((materia) => materia.Materia.optativa === true),
      R.map((materia) => 1)
    ),
    R.add,
    [],
    courses
  );

export const cuentaCurriculares = (courses) =>
  R.transduce(
    R.compose(
      R.filter((materia) => materia.Calificacion?.valor >= 7),
      R.filter((materia) => materia.Materia.optativa !== true),
      R.map((materia) => 1)
    ),
    R.add,
    [],
    courses
  );

export const handleAlerts = () => {
  const context = React.useContext(sesionStore);
  const dispatch = context.dispatch;

  const successMessage = (message) =>
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        type: 'success',
        message: message,
      },
    });

  const errorMessage = (message) =>
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        type: 'error',
        message: message,
      },
    });

  const warningMessage = (message) =>
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        type: 'warning',
        message: message,
      },
    });

  return { successMessage, errorMessage, warningMessage };
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const CURPValidation = (curp) => {
  const re =
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
  const validado = curp.match(re);
  if (!validado) return false;

  const digitoVerificador = (curp17) => {
    // Digito verificador: https://todocurp.org.mx/sacar-curp/digito-verificador-curp/
    const dict = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
    const result = R.mathMod(
      R.sum(mapInd((k, idx) => R.indexOf(k, dict) * (18 - idx))(curp17))
    )(10);
    const verified = result != 0 ? 10 - result : 0;
    return verified;
  };

  return parseInt(validado[2]) === digitoVerificador(validado[1]);
};

export const isEmptyOrNil = (array) => {
  return R.isNil(array) || R.isEmpty(array);
};

export const format = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

export const emailPattern =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const formatDate = (stringDate) => {
  return new Date(stringDate).toLocaleDateString('es-MX', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};
