import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: '24px',
  },
  imageFrame: {
    width: '17.5vw',
    height: '17.5vw',
    minWidth: 125,
    minHeight: 125,
    maxWidth: 200,
    maxHeight: 200,
    borderRadius: '50%',
    display: 'inline-block',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
  },
  datePicker: {
    marginTop: 0,
    '& .MuiInputBase-input': {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
    '& label': {
      color: theme.palette.secondary.main,
      fontSize: '.9rem',
    },
    '& label.Mui-focused': {
      color: theme.palette.secondary.dark,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      borderWidth: 2,
      '& fieldset': {
        borderColor: theme.palette.secondary.main,
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.dark,
      },
      '& .MuiSelect-icon': {
        color: theme.palette.secondary.main + ' !important',
      },
    },
    '& .MuiIconButton-label': {
      color: theme.palette.secondary.main,
    },
  },
  box: {
    marginTop: 8,
    '& .MuiTypography-root': {
      background: theme.palette.secondary.main,
      color: 'white',
      borderRadius: 8,
      padding: 8,
    },
  },
}));
