import React, { useEffect } from "react";
import Timer from "react-compound-timer";

//Components
import { Typography } from "@material-ui/core";

const NotFound = ({ history }) => {
  useEffect(() => {
    setTimeout(() => history.push("/"), 5000);
  }, []);

  return (
    <div style={{ padding: "10% 20%" }}>
      <Typography variant="h1">:( Ups... </Typography>
      <br /> <br /> <br />
      <Typography variant="h3">
        La pagina que intentas visitar no existe.
        <br /> En un momento seras redireccionado al inicio.
      </Typography>
      <Typography variant="h2">
        <Timer initialTime={5100} direction="backward">
          <Timer.Seconds />
        </Timer>
      </Typography>
    </div>
  );
};

export default NotFound;
