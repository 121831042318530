import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Confirma from '../../../../utils/Confirma';
import { Warning } from '@material-ui/icons';

export const ConfirmDocumentsModal = ({
  openConfirmDocuments,
  setOpenConfirmDocuments,
  modifyStudent,
}) => {
  return (
    <Confirma
      open={openConfirmDocuments}
      setOpen={setOpenConfirmDocuments}
      titulo='Estás a punto de confirmar tus datos'
      confirmButtonText='Confirmar datos'
      cancelButtonText='Cancelar'
      action={() => {
        modifyStudent({
          documentosValidadosEstudiante: true,
        });
        setOpenConfirmDocuments(false);
      }}
      content={
        <Box display='flex' flexDirection='column' gridGap='8px'>
          <Typography
            variant='body1'
            style={{ color: '#44474f', textAlign: 'center' }}>
            Recuerda que puedes editar tus datos antes de confirmar que son correctos.
          </Typography>
          <Box
            display='flex'
            gridGap='8px'
            alignItems='center'
            justifyContent='center'>
            <Warning style={{ color: '#FFC107' }} />
            <Typography
              variant='body1'
              style={{
                color: '#44474f',
                textAlign: 'center',
                fontWeight: 700,
              }}>
              ¿La información registrada es la correcta?
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};
