import React, { Suspense, lazy } from 'react';
import Loading from '@material-ui/core/LinearProgress';
import { SesionProvider } from '../../../../utils/inscripcionesStore';
import Acciones from '../../../Reinscripciones/componentes/v2/Acciones';
const ArrowDown = lazy(() => import('../../../../componentes/utils/ArrowDown'));

export const ReinscripcionView = () => {
  return (
    <Suspense fallback={<Loading />}>
      <SesionProvider>
        <Acciones />
      </SesionProvider>
      <ArrowDown />
    </Suspense>
  );
};
