import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { formatDate } from '../../utils';

export const EnrollmentBanner = ({ isEnrollment, enrollmentDate }) => {
  return (
    <Box
      bgcolor='#1D1B20'
      padding='24px 16px 16px'
      position='fixed'
      zIndex={10}
      style={{ width: '100%' }}>
      <Typography variant='h6' align='center' style={{ color: '#B5E3DB' }}>
        {isEnrollment
          ? 'Inscripciones abiertas'
          : `Las próximas inscripciones comienzan el día ${formatDate(
              enrollmentDate
            )}`}
      </Typography>
    </Box>
  );
};
