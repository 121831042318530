export const useDetermineGraduatedState = (student) => {
  let status;
  let missingDocuments = [];

  /**
   * First, we check if the student has the required documents.
   * If the student is missing any of the required documents, their status is marked as incomplete.
   * If the student has all the required documents, we check if their documents have been validated.
   * If the student's documents have been validated, we check if the student has graduated.
   * If the student has graduated, we check if the student has a track ID for their graduation documents.
   * If the student has a track ID for their graduation documents, their status is marked as complete.
   */

  const verifyDocuments = (student) => {
    if (!student?.documentoCertificadoSecundaria) {
      missingDocuments.push('Certificado de secundaria');
    }
    if (!student?.Persona?.documentoCurp) {
      missingDocuments.push('CURP');
    }
    if (!student?.Persona?.documentoOficial) {
      missingDocuments.push('Acta de nacimiento');
    }
  };

  if (
    !student?.documentoCertificadoSecundaria ||
    !student?.Persona?.documentoCurp ||
    !student?.Persona?.documentoOficial
  ) {
    status = 'incompleteDocuments';
    verifyDocuments(student);
  } else {
    if (student.documentosValidadosEstudiante) {
      if (student.documentosValidadosControlEscolar) {
        if (student.documentosGraduado) {
          if (student.documentosGraduadoTrackId) {
            status = 'documentsTrackId';
          } else {
            status = 'waitingMessage';
          }
        } else {
          status = 'payDocumentButton';
        }
      } else {
        status = 'validateDocumentsControl';
      }
    } else {
      status = 'validateDocuments';
    }
  }
  return { status, missingDocuments };
};
