import React from 'react';

//Componentes
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

//Styles
import { makeStyles } from '@material-ui/core/styles';

//Icons
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

//Images
import prepaenlinea from '../imagenes/logo.svg';
import rvoe from '../imagenes/RVOE.png';
import florida from '../imagenes/FLORIDA.png';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    padding: '100px 10%',
    width: '100%',
  },
  iconImg: {
    width: '64px',
  },
  logo: {
    width: '172px',
  },
  logoContainer: {
    textAlign: 'center',
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      justifyContent='center'
      alignItems='center'>
      <Grid item xs={12} sm={4} md={5} lg={5}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} style={{ width: '100%' }}>
            <Typography variant='caption'>
              <b>Educación Práctica para la Vida A.C.</b>
              <br />
              3ra de León No.15 Col. Centro.
              <br />
              Zacatlán Pue. México. C.P. 73310
              <br />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ width: '100%' }}>
            <Typography variant='caption'>
              <b>Educazion.net International School Corp</b>
              <br />
              7901 4TH ST N STE 300
              <br />
              ST. PETERSBURG, FL 33702
              <br />
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Typography variant='caption'>
          <b>Horarios de Atención:</b> <br />
          Lunes a Viernes de las 9:00 a las 17:00 hora centro de la República
          Mexicana.
          <br />
          <div>
            <PhoneIcon
              fontSize='small'
              style={{ transform: 'translateY(4px)', opacity: '.8' }}
            />
            +52 (222) 454-0048
          </div>
          <div>
            <EmailIcon
              fontSize='small'
              style={{ transform: 'translateY(4px)', opacity: '.8' }}
            />
            inscripciones@prepaenlinea.mx
          </div>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Grid container alignItems='center' direction='column'>
          <Grid item>
            <a href='https://www.facebook.com/prepaenlineamx' target='_blank'>
              <IconButton>
                <Facebook style={{ fontSize: 64 }} />
              </IconButton>
            </a>
          </Grid>
          <Grid item>
            <a href='https://www.instagram.com/prepaenlineamx' target='_blank'>
              <IconButton>
                <Instagram style={{ fontSize: 64 }} />
              </IconButton>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.logoContainer} xs={12} sm={4} md={5} lg={3}>
        <img alt='' className={classes.logo} src={prepaenlinea} />
        <Typography variant='h5'>prepaenlinea.mx</Typography>
        <Typography variant='h7'>
          RVOE <b>13/990</b> CCT <b>21PBH3845N</b>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Grid container alignItems='center' direction='column' spacing={6}>
          <Grid item style={{ textAlign: 'center' }}>
            <a href='https://sirvoems.sep.gob.mx/sirvoems' target='_blank'>
              <img alt='' src={rvoe} style={{ width: '80%' }} />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
