import React from 'react';
import { Backdrop, makeStyles } from '@material-ui/core';
import { PacmanLoader } from 'react-spinners';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
  },
}));

export const Loader = () => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <PacmanLoader loading={true} color='white' />
    </Backdrop>
  );
};
