import React, { Fragment, useEffect, Suspense, lazy } from 'react';
import Loading from '@material-ui/core/LinearProgress';
import Portada from '../subvistas/Common/Portada';
import FlechaGuia from '../componentes/utils/ArrowDown';
import cover from '../imagenes/llega a donde quieras.svg';
import aprender from '../imagenes/La mejor forma de aprender.svg';
import quienes from '../imagenes/quienes somos.svg';
import vision from '../imagenes/vision.svg';

const Seccion = lazy(() => import('../subvistas/Common/Seccion'));
const Slogan = lazy(() => import('../subvistas/Home/Slogan'));

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Portada
        titulo='Llega donde tú quieras. Empieza estudiando en Prepaenlinea.mx'
        descripcion='A tu manera, sin descuidar lo que te gusta, respetando tus horarios y hobbies.'
        img={cover}
        img_alt='Prepaenlinea.mx estudia y conecta desde casa.'
        color='#434343'
        bg_color='#61C4B2'
      />
      <FlechaGuia />

      <Suspense fallback={<Loading />}>
        <Seccion
          titulo='La mejor forma de aprender'
          subtitulo='¿Estudiar en línea?'
          descripcion={
            <Fragment>
              La nueva normalidad nos trajo muchos cambios, uno de ellos en la
              manera de aprender. Con la educación online reevaluamos los
              métodos tradicionales. ¿Te imaginas aprender en una comunidad
              donde <b>tus intereses</b> sean tomados en cuenta? Con la guía de
              tutores, que solucionarán tus dudas hasta que seas totalmente
              autodidacta.
              <br />
              <br />
              Las puertas a <b>mejores oportunidades</b> se abrirán para ti como
              nunca habías imaginado.
            </Fragment>
          }
          img={aprender}
          img_alt='Prepa en linea donde quieras.'
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <Seccion
          titulo='¿Quiénes somos?'
          subtitulo='Educación Online / E-Learning'
          descripcion={
            <Fragment>
              Un conjunto de expertos con más de 15 años de experiencia, que
              buscan formar a los estudiantes desde el mundo digital, dándoles
              acceso a una educación basada en <b>conocimientos actuales</b> y
              en su <b>curiosidad innata</b>.
              <br />
              <br />A través de nuestra plataforma digital, formamos personas{' '}
              <b>autodidactas</b>, con pensamiento crítico y analítico,
              desarrollando <b>competencias digitales</b>. Facultades
              indispensables para enfrentar el mundo de hoy.
            </Fragment>
          }
          img={quienes}
          img_alt='La prepa en linea con tutores personalizados.'
          direction='row-reverse'
          bg_color='rgb(240,240,240)'
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <Slogan />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <Seccion
          titulo='Visión'
          subtitulo='Educación enfocada a la vida'
          descripcion={
            <Fragment>
              El mundo ha cambiado gracias a la tecnología, la forma de aprender
              también. Sabemos que los estudiantes necesitan acceder fácilmente
              a la información, con atención personalizada; por eso apostamos
              por una educación <b>humana</b> y <b>actualizada</b>, que llegó
              para quedarse.
              <br />
              <br />
              Como Asociación Civil sin fines de lucro, estamos comprometidos
              con el desarrollo educativo de la niñez y juventud de habla
              hispana, nuestra labor social es ofrecer una formación educativa
              de alto nivel a bajo costo, siendo nuestro único objetivo
              económico el que este portal sea autosustentable.
            </Fragment>
          }
          img={vision}
          img_alt='Estudia el bachillerato en linea seguro en casa.'
        />
      </Suspense>
    </Fragment>
  );
};

export default Home;
