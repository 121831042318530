import Button from '../../../../componentes/utils/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Document, Page } from 'react-pdf';

const DialogUpload = ({
  open,
  handleClose,
  handleUploadKey,
  message,
  document,
  fns,
}) => {
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const handleUploadFn = fns[handleUploadKey];

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  const changePage = (offset) =>
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md'>
      <DialogTitle>{message}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} alignItems='center' justifyContent='center'>
          <Grid item xs={12}>
            <div
              style={{
                height: 400,
                overflowX: 'hidden',
                overflowY: 'scroll',
                border: '1px solid #A9A9A9',
                borderRadius: '4px',
              }}>
              <Document file={document} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} width={700} />
              </Document>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton
                disabled={pageNumber <= 1}
                onClick={previousPage}
                style={{ marginRight: 10, border: '1px solid #A9A9A9' }}
                size='small'>
                <NavigateBeforeIcon />
              </IconButton>
              <Typography
                variant='body2'
                style={{ display: 'inline-block', marginTop: 8 }}>
                Página {pageNumber || (numPages ? 1 : '--')} de{' '}
                {numPages || '--'}
              </Typography>
              <IconButton
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                size='small'
                style={{ marginLeft: 10, border: '1px solid #A9A9A9' }}>
                <NavigateNextIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='contained' color='secondary'>
          Cancelar
        </Button>
        <Button onClick={handleUploadFn} variant='contained' color='primary'>
          Subir documento
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUpload;
