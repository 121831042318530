import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { CheckCircle, MoreVert, Warning } from '@material-ui/icons';
import { simpleRowStyles } from '../components/List/List.style';
import { List } from '../components/List/List';
import { DocumentMenu } from '../components/DocumentMenu';
import { AcademicDocumentMenu } from '../components/AcademicDocumentMenu';

export const Documents = ({
  personalDocuments,
  academicDocuments,
  student,
  disableUploadDocuments,
  disableDownloadHistory,
}) => {
  const classes = simpleRowStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [academicAnchorEl, setAcademicAnchorEl] = React.useState(null);
  const [documentKey, setDocumentKey] = React.useState('');
  const [notEnglish, setNotEnglish] = React.useState(false);
  const open = Boolean(anchorEl);
  const academicOpen = Boolean(academicAnchorEl);

  const handleClick = (event, key) => {
    setAnchorEl(event.currentTarget);
    setDocumentKey(key);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAcademicClick = (event, key, notEnglish) => {
    setAcademicAnchorEl(event.currentTarget);
    setDocumentKey(key);
    setNotEnglish(notEnglish);
  };
  const handleAcademicClose = () => {
    setAcademicAnchorEl(null);
  };

  return (
    <Box
      display='flex'
      gridColumnGap='48px'
      gridRowGap='32px'
      className={classes.column}
      flexDirection='column'>
      <List
        title='Documentos personales'
        type='documents'
        rows={personalDocuments.map((doc) => (
          <div className={classes.root} key={doc.title}>
            <p className={classes.title}>{doc.title}</p>
            <div className={classes.actionContainer}>
              {doc.hasDocument ? (
                <CheckCircle className={classes.check} />
              ) : (
                <Warning className={classes.warning} />
              )}
              <IconButton
                style={{ padding: 0 }}
                onClick={(event) => {
                  handleClick(event, doc.key);
                }}>
                <MoreVert />
              </IconButton>
            </div>
          </div>
        ))}
      />
      <List
        title='Documentos académicos'
        type='documents'
        rows={academicDocuments.map((doc) => (
          <div className={classes.root} key={doc.title}>
            <p className={classes.title}>{doc.title}</p>
            <div className={classes.actionContainer}>
              <IconButton
                disabled={doc.title === 'Historial' && disableDownloadHistory}
                style={{ padding: 0 }}
                onClick={(event) => {
                  handleAcademicClick(event, doc.key, doc?.notEnglish);
                }}>
                <MoreVert />
              </IconButton>
            </div>
          </div>
        ))}
      />
      <DocumentMenu
        anchorEl={anchorEl}
        estudiante={student}
        handleClose={handleClose}
        open={open}
        documentKey={documentKey}
        disableUploadDocuments={disableUploadDocuments}
      />
      <AcademicDocumentMenu
        anchorEl={academicAnchorEl}
        handleClose={handleAcademicClose}
        open={academicOpen}
        documentKey={documentKey}
        notEnglish={notEnglish}
      />
    </Box>
  );
};
