require('conekta-card-tokenizer');
import axios from 'axios';
import { PAGO_TARJETA } from '../../../API';
import * as localStore from '../../../utils/localStore';
import * as R from 'ramda';

export const useCardPayments = ({ paymentData, setResponseData }) => {
  const cardPay = async (
    tokens,
    { matricula, jwt, moneda, importe, periodo, concepto }
  ) => {
    try {
      const { conekta, stripe } = tokens;
      const response = await PAGO_TARJETA(
        matricula,
        conekta,
        stripe,
        jwt,
        importe,
        concepto,
        moneda,
        periodo
      );

      if (response.data?.order.charges.data[0].status === 'paid') {
        const { issuer, brand, last4, type } =
          response.data.order.charges.data[0].payment_method;
        const id = response.data.order.id;
        return { issuer, brand, last4, type, id };
      }
    } catch (error) {
      console.log('Error', error.response?.data?.message || error);
    }
    return null;
  };

  const generateTokens = async () => {
    const { hash } = localStore.getItems('educsesion');
    let result = null;
    Conekta.setPublicKey(process.env.CONEKTA_KEY);
    const dataCard = {
      card: {
        number: paymentData.cardNumber,
        name: paymentData.cardUser,
        exp_year: paymentData.cardExpirationYear,
        exp_month: paymentData.cardExpirationMonth,
        cvc: paymentData.cardCVC,
      },
    };

    const errorHandler = async (error) => {
      const { message_to_purchaser } = error;
      setResponseData({
        paymentMethod: 'card',
        error: message_to_purchaser,
        result: null,
        status: 'error',
      });
    };

    const successHandler = async (token) => {
      const url = 'https://api.stripe.com/v1/tokens';
      const card = {
        'card[number]': dataCard.card.number,
        'card[exp_month]': dataCard.card.exp_month,
        'card[exp_year]': dataCard.card.exp_year,
        'card[cvc]': dataCard.card.cvc,
      };
      const encoded = R.map(
        (prop) =>
          `${encodeURIComponent(prop)}=${encodeURIComponent(card[prop])}`
      )(Object.keys(card));
      const body = R.join('&')(encoded);
      try {
        const { data } = await axios.post(url, body, {
          headers: {
            Authorization: `Bearer ${process.env.STRIPE_KEY}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
        result = await cardPay(
          { conekta: token.id, stripe: data.id },
          {
            matricula: paymentData.studentId,
            jwt: hash,
            moneda: paymentData.currency,
            importe: paymentData.amount,
            periodo: paymentData.term,
            concepto: paymentData.concept,
          }
        );
        setResponseData({
          paymentMethod: 'card',
          result,
          error: null,
          status: 'success',
        });
      } catch (error) {
        console.log('Error', error.response);
        setResponseData({
          paymentMethod: 'card',
          error: error.response?.message_to_purchaser,
          result: null,
          status: 'error',
        });
        return;
      }
    };
    Conekta.Token.create(dataCard, successHandler, errorHandler);
  };
  return { generateTokens };
};
