import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { CustomButton } from '../../CustomButton';

export const TextoTarjeta = ({ data }) => (
  <>
    <Typography variant='body1' align='center'>
      Verifica tu información de pago.
    </Typography>
    <Typography variant='body1'>
      Banco: <strong>{data?.issuer?.toUpperCase()}</strong>
    </Typography>
    <Typography variant='body1'>
      Marca: <strong>{data?.brand?.toUpperCase()}</strong>
    </Typography>
    <Typography variant='body1'>
      Tarjeta ({data?.type}): <strong>{`**** **** **** ${data?.last4}`}</strong>
    </Typography>
  </>
);

export const TextoSpei = ({ data }) => (
  <>
    <Typography variant='body1'>
      Realiza tu pago a través de SPEI con la siguiente información.
    </Typography>
    <Typography variant='body1'>
      <strong>Banco: </strong> {data?.bank}
      <br />
      <strong>CLABE: </strong> {data?.clabe}
      <br />
      <strong>Expira en 3 días</strong>
    </Typography>
    <Typography variant='body1' component='div'>
      <strong>Se ha enviado la información a su correo.</strong>
      <ul>
        <li>Accede a tu banca en línea.</li>
        <li>Da de alta la CLABE de esta ficha.</li>
        <li>
          Realiza la transferencia por la cantidad exacta, de lo contrario tu
          pago se rechazará.
        </li>
        <li>
          Al confirmar tu pago, tu banco generará un comprobante digital.
          ¡Consérvalo!
        </li>
        <li>
          Al completar estos pasos, recibirás un correo electrónico confirmando
          tu pago.
        </li>
      </ul>
    </Typography>
  </>
);

export const TextoOxxo = ({ data }) => {
  const url = data?.barcode_url;

  return (
    <>
      <Typography variant='body1'>
        Realiza tu pago a través de Oxxo con la siguiente información.
      </Typography>
      <Card height='100%' width='100%' elevation={0}>
        <CardActionArea style={{ textAlign: 'end', display: 'block' }}>
          <CardMedia component='img' alt='barcode' height='100' image={url} />
        </CardActionArea>
        <Box
          padding='16px'
          bgcolor='#61C4B1'
          borderRadius='8px'
          color='#FFF'
          marginTop='24px'>
          <Typography variant='subtitle1' style={{ fontWeight: 700 }}>
            Toma una captura de pantalla a la referencia
          </Typography>
        </Box>
      </Card>
      <Typography variant='body1'>
        <strong>Referencia:</strong> {data?.reference}
      </Typography>
      <Typography variant='body1'>
        <strong>Expira en 3 días</strong>
      </Typography>
      <Typography variant='body1' component='div'>
        <strong>Se ha enviado la información a su correo.</strong>
        <ul>
          <li>Acude a la tienda OXXO preferida.</li>
          <li>Solicita en caja un pago de OXXOPay.</li>
          <li>Dicta al cajero el número de referencia en esta ficha.</li>
          <li>Realiza el pago con dinero en efectivo.</li>
          <li>
            Al confirmar tu pago, el cajero te entregará un comprobante impreso.
            Verifica que se haya hecho correctamente.
          </li>
          <li>
            Conserva el comprobante de pago. Al completar estos pasos recibirás
            un correo confirmando tu pago.
          </li>
        </ul>
      </Typography>
    </>
  );
};

export const PaymentResult = ({ responseData, back }) => {
  return (
    <Box display='flex' flexDirection='column' gridRowGap='24px'>
      <Typography variant='h2' style={{ fontSize: '24px', color: '#21594F' }}>
        {responseData.paymentMethod === 'card'
          ? 'Pago realizado con éxito'
          : 'Referencia generada'}
      </Typography>
      <Box display='flex' flexDirection='column' gridRowGap='16px'>
        {responseData.paymentMethod === 'oxxo' && (
          <TextoOxxo data={responseData.result} />
        )}
        {responseData.paymentMethod === 'spei' && (
          <TextoSpei data={responseData.result} />
        )}
        {responseData.paymentMethod === 'card' && (
          <TextoTarjeta data={responseData.result} />
        )}
        <Typography variant='body1'>
          ¿Necesitas factura? Revisa tu correo electrónico, cada que realizas un
          pago con nosotros te mandamos una liga para obtenerla.
        </Typography>
      </Box>
      <Box display='flex' justifyContent='end'>
        <CustomButton onClick={back}>Regresar al perfil</CustomButton>
      </Box>
    </Box>
  );
};
