import React from 'react';
import { Box } from '@material-ui/core';
import { generalAcademicInfoStyles } from './GeneralAcademicInfo.style';

export const GeneralAcademicInfo = ({ generalInfo }) => {
  const classes = generalAcademicInfoStyles();
  return (
    <Box className={classes.container} width='100%'>
      {generalInfo.map((item) => (
        <Box key={`card-${item.label}`} className={classes.card}>
          <p className={classes.value}>{item.value}</p>
          <p className={classes.label}>{item.label}</p>
        </Box>
      ))}
    </Box>
  );
};
