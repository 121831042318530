import * as R from 'ramda';
import React, { useReducer, createContext } from 'react';

const inscripcionStore = createContext(null);
const { Provider } = inscripcionStore;

const initialState = {
  materiasElegidas: [],
  total: 0,
  moneda: false,
  modalidad: true,
  nombre: '',
  email: '',
  telefono: '',
  tipoSeleccionado: '',
  token: '',
};

const sesionReducer = (store, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'UPDATE_STORE': {
      const sum = R.sum(
        R.map((m) => (store.modalidad ? m.precio : m.precio / 4))(payload)
      );
      return { ...store, materiasElegidas: payload, total: sum };
    }
    case 'CLEAR_STORE':
      return { ...store, materiasElegidas: [], total: 0 };
    case 'UPDATE_MONEDA':
      return { ...store, moneda: payload };
    case 'UPDATE_MODALIDAD':
      return { ...store, modalidad: payload };
    case 'UPDATE_REMODALIDAD': {
      const sum = R.sum(
        R.map((m) => (store.modalidad ? m.precio / 4 : m.precio))(payload)
      );
      return {
        ...store,
        materiasElegidas: payload,
        modalidad: !store.modalidad,
        total: sum,
      };
    }
    case 'UPDATE_NOMBRE':
      return { ...store, nombre: payload };
    case 'UPDATE_EMAIL':
      return { ...store, email: payload };
    case 'UPDATE_TELEFONO':
      return { ...store, telefono: payload };
    case 'UPDATE_TIPO':
      return { ...store, tipoSeleccionado: payload };
    case 'UPDATE_TOTAL':
      return { ...store, total: payload };
    case 'UPDATE_TOKEN':
      return { ...store, token: payload };
    default:
      throw new Error(`Acción no soportada ${type}`);
  }
};

const SesionProvider = ({ children }) => {
  const [store, attend] = useReducer(sesionReducer, initialState);

  return <Provider value={{ store, attend }}>{children}</Provider>;
};

export { inscripcionStore, SesionProvider };
