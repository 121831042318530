import React from 'react';
import {
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Box,
} from '@material-ui/core';
import Button from '../componentes/utils/Button';
import { ErrorOutline } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Confirma = (props) => {
  const {
    open,
    setOpen,
    titulo = '¿Deseas crear una cuenta de estudiante con este correo?',
    content,
    action,
    confirmButtonText = 'Confirmar correo',
    cancelButtonText = 'Editar correo',
    disableConfirm = false,
  } = props;

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        padding='24px 48px'
        flexDirection='column'
        gridGap={16}>
        <ErrorOutline style={{ color: '#21594F', fontSize: '2rem' }} />
        <Typography
          variant='h6'
          style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {titulo}
        </Typography>
      </Box>
      {content && (
        <DialogContent style={{ padding: '16px 48px 24px' }}>
          {content}
        </DialogContent>
      )}
      <DialogActions style={{ padding: '24px 48px', background: '#F7F7F7' }}>
        <Button
          onClick={handleClose}
          size='small'
          variant='contained'
          color='primary'>
          {cancelButtonText}
        </Button>
        <Button
          onClick={action}
          color='secondary'
          size='small'
          disabled={disableConfirm}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirma;
