import React from 'react';
import PropTypes from 'prop-types';
import { useGetDocument } from '../hooks/useGetDocument';
import { Box, Button, Typography } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { gradesComponentsStyles } from './styles';

export const SectionHeader = ({
  title,
  registrationId,
  hasScholarship,
  group,
}) => {
  const { getBoletaPDF } = useGetDocument(registrationId, title);
  const clasess = gradesComponentsStyles();

  const noGrades = group?.subjects?.every(
    (subject) => subject.calificacion === null
  );

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      marginBottom='16px'>
      <Box
        display='flex'
        alignItems={['start', 'center']}
        gridGap='16px'
        flexDirection={['column', 'row']}>
        <Typography variant='body1' className={clasess.sectionHeader}>
          Periodo {title}
        </Typography>
        {hasScholarship && (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderRadius='100px'
            padding='4px 8px'
            bgcolor='#61C4B2'>
            <p className={clasess.scholarship}>Con beca</p>
          </Box>
        )}
      </Box>
      {registrationId && (
        <Box display='flex' gridColumnGap='16px'>
          <Button
            variant='text'
            className={clasess.downloadEnglish}
            startIcon={<GetApp />}
            onClick={() => getBoletaPDF('en')}
            disabled={noGrades}>
            Boleta EN
          </Button>
          <Button
            variant='text'
            className={clasess.downloadSpanish}
            startIcon={<GetApp />}
            onClick={() => getBoletaPDF('es')}
            disabled={noGrades}>
            Boleta ES
          </Button>
        </Box>
      )}
    </Box>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  registrationId: PropTypes.string,
  hasScholarship: PropTypes.bool,
};
