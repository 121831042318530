import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Message } from '@material-ui/icons';

export const MessageAlert = ({ message }) => {
  return (
    <Box
      padding='16px'
      borderRadius='8px'
      style={{ backgroundColor: '#7F60C3' }}
      display='flex'
      gridGap='8px'>
      <Message style={{ color: '#FFD700' }} />
      <Typography
        variant='subtitle2'
        style={{ color: '#fff', fontWeight: 700 }}>
        {message}
      </Typography>
    </Box>
  );
};
