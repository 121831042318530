import React, { lazy } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Aviso } from '../components/Aviso';
import { Paises } from '../../../../utils/paises';

const Input = lazy(() => import('../../../../componentes/utils/Input'));

export const AddressInfoForm = ({
  handleChange,
  data,
  listaFaltantes,
  errors,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
      gridGap='24px'
      padding='24px'
      borderRadius='8px'
      border='1px solid #E0E0E0'>
      <Box display='flex' flexDirection='column'>
        <Typography variant='h6' style={{ color: '#328677', fontWeight: 700 }}>
          Datos de la dirección particular del estudiante
        </Typography>
        <Typography variant='subtitle1'>
          Si no hay información para algún campo, por favor, escribir N/A.
        </Typography>
      </Box>
      <Box display='flex' flexDirection={['column', 'row']} gridGap='24px'>
        <Box
          display='flex'
          flexDirection='column'
          gridGap='8px'
          width={['100%', '50%']}>
          <Input
            id='calle'
            label='Calle'
            name='calle'
            type='text'
            fullWidth
            value={data.calle || ''}
            onChange={handleChange('calle')}
          />
          <Aviso
            value='Calle'
            message={errors.calle}
            listaFaltantes={listaFaltantes}
          />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          gridGap='8px'
          width={['100%', '50%']}>
          <Input
            id='numInt'
            label='Número interior'
            name='numInt'
            type='text'
            fullWidth
            value={data.numInt || ''}
            onChange={handleChange('numInt')}
          />
          <Aviso
            value='Número interior'
            message={errors.numInt}
            listaFaltantes={listaFaltantes}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection={['column', 'row']} gridGap='24px'>
        <Box
          display='flex'
          flexDirection='column'
          gridGap='8px'
          width={['100%', '50%']}>
          <Input
            id='numExt'
            label='Número exterior'
            name='numExt'
            type='text'
            fullWidth
            value={data.numExt || ''}
            onChange={handleChange('numExt')}
          />
          <Aviso
            value='Número exterior'
            message={errors.numExt}
            listaFaltantes={listaFaltantes}
          />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          gridGap='8px'
          width={['100%', '50%']}>
          <Input
            id='colonia'
            label='Colonia'
            name='colonia'
            type='text'
            fullWidth
            value={data.colonia || ''}
            onChange={handleChange('colonia')}
          />
          <Aviso
            value='Colonia'
            message={errors.colonia}
            listaFaltantes={listaFaltantes}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection={['column', 'row']} gridGap='24px'>
        <Box
          display='flex'
          flexDirection='column'
          gridGap='8px'
          width={['100%', '50%']}>
          <Input
            id='municipio'
            label='Municipio'
            name='municipio'
            type='text'
            fullWidth
            value={data.municipio || ''}
            onChange={handleChange('municipio')}
          />
          <Aviso
            value='Municipio'
            message={errors.municipio}
            listaFaltantes={listaFaltantes}
          />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          gridGap='8px'
          width={['100%', '50%']}>
          <Input
            id='estado'
            label='Estado'
            name='estado'
            type='text'
            fullWidth
            value={data.estado || ''}
            onChange={handleChange('estado')}
          />
          <Aviso
            value='Estado'
            message={errors.estado}
            listaFaltantes={listaFaltantes}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection={['column', 'row']} gridGap='24px'>
        <Box
          display='flex'
          flexDirection='column'
          gridGap='8px'
          width={['100%', '50%']}>
          <Input
            id='codigoPostal'
            label='Código Postal'
            name='codigoPostal'
            type='text'
            fullWidth
            value={data.codigoPostal || ''}
            onChange={handleChange('codigoPostal')}
          />
          <Aviso
            value='Código Postal'
            message={errors.cp}
            listaFaltantes={listaFaltantes}
          />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          gridGap='8px'
          width={['100%', '50%']}>
          <Input
            id='pais'
            label='Pais'
            value={data.PaisId || ''}
            select
            options={Paises}
            fullWidth
            onChange={handleChange('PaisId')}
          />
          <Aviso
            value='País'
            message={errors.PaisId}
            listaFaltantes={listaFaltantes}
          />
        </Box>
      </Box>
    </Box>
  );
};
