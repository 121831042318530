import React, { lazy } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Aviso } from '../components/Aviso';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useStyles } from '../styles';
import { Paises } from '../../../../utils/paises';
import * as R from 'ramda';

const Input = lazy(() => import('../../../../componentes/utils/Input'));
const InputTel = lazy(() => import('../../../../componentes/utils/InputTel'));
const Discapacidades = lazy(() => import('../../Discapacidades'));
const Religiones = lazy(() => import('../../Religiones'));
const CentrosEducativos = lazy(() => import('../../CentrosEducativos'));

export const StudentInfoForm = ({
  handleChange,
  handleDateChange,
  data,
  listaFaltantes,
  errors,
  dispatch,
}) => {
  const classes = useStyles();
  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
      gridGap='24px'
      padding='24px'
      borderRadius='8px'
      border='1px solid #E0E0E0'>
      <Typography variant='h6' style={{ color: '#328677', fontWeight: 700 }}>
        Datos del estudiante
      </Typography>
      <Box display='flex' flexDirection={['column', 'row']} gridGap='24px'>
        <Input
          id='enombre'
          label='Nombre'
          name='enombre'
          type='text'
          fullWidth
          value={data.nombre || ''}
          onChange={handleChange('nombre')}
          disabled={true}
        />
        <Input
          id='apa'
          label='Apellido Paterno'
          name='apellidoPaterno'
          type='text'
          value={data.apellidoPaterno || ''}
          onChange={handleChange('apellidoPaterno')}
          fullWidth
          disabled={true}
        />
        <Input
          id='ama'
          label='Apellido Materno (Si no tienes apellido materno N/A)'
          name='apellidoMaterno'
          type='text'
          value={data.apellidoMaterno || ''}
          onChange={handleChange('apellidoMaterno')}
          fullWidth
          disabled={true}
        />
      </Box>
      <Box display='flex' flexDirection={['column', 'row']} gridGap='24px'>
        <Box display='flex' flexDirection='column' width='100%' gridGap='8px'>
          <Input
            id='esexo'
            label='Sexo'
            name='esexo'
            select
            value={data.sexo || ''}
            options={[
              { value: 'Masculino', name: 'Masculino' },
              { value: 'Femenino', name: 'Femenino' },
            ]}
            onChange={handleChange('sexo')}
            type='text'
            fullWidth
          />
          <Aviso value='Sexo del estudiante' message={errors.sexo} />
        </Box>
        <Box display='flex' flexDirection='column' width='100%' gridGap='8px'>
          <KeyboardDatePicker
            autoOk
            fullWidth
            variant='inline'
            inputVariant='outlined'
            color='secondary'
            format='dd/MM/yyyy'
            margin='normal'
            id='efechaNacimiento'
            label='Fecha de Nacimiento'
            value={data.fechaNacimiento || ''}
            onChange={handleDateChange}
            className={classes.datePicker}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <Aviso value='Fecha de nacimiento' message={errors.fechaNacimiento} />
        </Box>
      </Box>
      <Box display='flex' flexDirection={['column', 'row']} gridGap='24px'>
        <Box display='flex' flexDirection='column' width='100%' gridGap='8px'>
          <Input
            id='pais'
            label='Nacionalidad'
            value={data.NacionalidadId || ''}
            select
            options={Paises}
            fullWidth
            onChange={handleChange('NacionalidadId')}
          />
          <Aviso value='Nacionalidad' message={errors.NacionalidadId} />
        </Box>

        {!R.isNil(data.NacionalidadId) && data.NacionalidadId === 146 && (
          <Input
            id='ecurp'
            label='CURP'
            name='ecurp'
            type='text'
            value={data.curp || ''}
            onChange={handleChange('curp')}
            fullWidth
          />
        )}
      </Box>
      <Box display='flex' flexDirection='column' width='100%' gridGap='16px'>
        <Typography
          variant='subtitle1'
          style={{ fontWeight: 700, color: '#328677' }}>
          Contacto
        </Typography>
        <Box display='flex' flexDirection={['column', 'row']} gridGap='24px'>
          <Box display='flex' flexDirection='column' width='100%' gridGap='8px'>
            <InputTel
              id='etelefono'
              label='Teléfono'
              name='etel'
              value={data.telefono || ''}
              onChange={handleChange('telefono')}
            />
            <Aviso
              value='Telefono de contacto del estudiante'
              message={errors.telefono}
              listaFaltantes={listaFaltantes}
            />
          </Box>
          <Input
            id='email'
            label='Correo electrónico del estudiante'
            name='email'
            type='text'
            value={data.email || ''}
            onChange={handleChange('email')}
            fullWidth
          />
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        width={['100%', 'calc(50% - 12px)']}
        gridGap='16px'>
        <Typography
          variant='subtitle1'
          style={{ fontWeight: 700, color: '#328677' }}>
          Centro Educativo
        </Typography>
        <CentrosEducativos
          centroEducativo={data.centroEducativo}
          update={dispatch}
        />
      </Box>
      <Religiones
        esReligion={data.esReligion}
        dispatch={dispatch}
        religion={data.religion}
        congregacion={data.congregacionReligiosa}
      />

      <Discapacidades
        esDiscapacidad={data.esDiscapacidad}
        update={dispatch}
        comentarioDiscapacidades={data.comentarioDiscapacidades}
        discapacidadesData={data.discapacidades}
      />
    </Box>
  );
};
