import React from 'react';
import download from 'downloadjs';
import { handleAlerts } from '../../../../../../utils';
import { GET_DOCUMENT } from '../../../../../../API';
import { sesionStore } from '../../../../../../utils/sesionStore';
import * as store from '../../../../../../utils/localStore';

export const useGetDocument = (registrationId, term) => {
  const sesionGlobal = React.useContext(sesionStore);
  const { dispatch } = sesionGlobal;
  const { successMessage, errorMessage } = handleAlerts();
  const { hash } = store.getItems('educsesion');

  const downloadDocument = async (url, documentName, message) => {
    try {
      dispatch({ type: 'START_LOADING' });
      const response = await GET_DOCUMENT(url, hash);
      download(response.data, documentName, 'application/pdf');
      successMessage(message);
    } catch (error) {
      errorMessage(
        error.response?.data?.message || error.response?.statusText || error
      );
    } finally {
      dispatch({ type: 'STOP_LOADING' });
    }
  };

  const getBoletaPDF = async (lang) => {
    const url = `${process.env.PREPA_SERVER}estudiantes/${registrationId}/documentos?documento=boleta&idioma=${lang}&periodo=${term}&firma=true`;
    const document = `boleta_${term}_${registrationId}_${lang}.pdf`;
    const message = 'Se ha descargado la boleta correctamente';
    await downloadDocument(url, document, message);
  };

  return { getBoletaPDF };
};
