import React, { useState } from 'react';
import Confirma from '../../../../utils/Confirma';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';

export const SelectGraduatedDocuments = ({
  open,
  setOpen,
  headerState,
  setHeaderState,
}) => {
  const [state, setState] = useState({
    national: true,
    transcript: false,
  });

  const prices = {
    national: 500,
    transcript: 5000,
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { national, transcript } = state;
  const error = [national, transcript].filter((v) => v).length === 0;

  return (
    <Confirma
      open={open}
      setOpen={setOpen}
      titulo='Selecciona tus documentos de graduado'
      confirmButtonText='Confirmar documentos'
      disableConfirm={error}
      cancelButtonText='Cancelar'
      action={() => {
        setHeaderState({
          ...headerState,
          showPaymentFlow: true,
          paymentInfo: {
            ...headerState.paymentInfo,
            amount: Object.keys(state).reduce((acc, key) => {
              return state[key] ? acc + prices[key] : acc;
            }, 0),
          },
        });
        setOpen(false);
      }}
      content={
        <Box display='flex' flexDirection='column' gridGap='8px'>
          <FormGroup>
            <FormControlLabel
              label='Documentos de graduado (para continuar estudios en México o EEUU) $500 mxn'
              control={
                <Checkbox
                  name='national'
                  checked={national}
                  onChange={handleChange}
                />
              }
            />
            <FormControlLabel
              label='Apostillado (para continuar estudios en países que no son México ni EEUU) $5000 mxn'
              control={
                <Checkbox
                  name='transcript'
                  checked={transcript}
                  onChange={handleChange}
                />
              }
            />
          </FormGroup>
          {error && (
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              padding='8px'
              style={{ background: '#c461733b', borderRadius: '4px' }}>
              <Typography
                variant='body2'
                style={{ color: '#C46173', fontWeight: 700 }}>
                Selecciona al menos un documento
              </Typography>
            </Box>
          )}
        </Box>
      }
    />
  );
};
