import React, { Fragment, useEffect, Suspense, lazy } from 'react';
import Loading from '@material-ui/core/LinearProgress';

//Components
import Portada from '../subvistas/Common/Portada';
import ArrowDown from '../componentes/utils/ArrowDown';
import cover from '../imagenes/vanguardia educativa.svg';
import calidad from '../imagenes/calidad internacional.svg';
import certificaciones from '../imagenes/certificacion.svg';
import tutores from '../imagenes/tutores y conviencia social.svg';

const Seccion = lazy(() => import('../subvistas/Common/Seccion'));
const FAQ = lazy(() => import('../subvistas/Common/FAQ'));

const Informacion = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Portada
        titulo='Vanguardia Educativa'
        descripcion={
          <Fragment>
            Nuestra pedagogía y métodos tienen un sello característico, pues
            nace del análisis de las <b>necesidades reales</b> de los alumnos.
            Buscamos formarlos para entender la realidad del mundo, desde
            distintas perspectivas: la económica, social, política, cultural,
            microambiental, ética, filosófica y espiritual. Para así formarte de
            manera integral y competente, listo para el mundo académico y
            laboral, sin descuidar el bien común.
          </Fragment>
        }
        img={cover}
        img_alt='Estudiar la prepa en linea es mejor.'
        bg_color='#040fd9'
      />

      <Suspense fallback={<Loading />}>
        <Seccion
          titulo='Tutores y Convivencia Social'
          subtitulo='¿Y los maestros? / ¿Y los compañeros?'
          descripcion={
            <Fragment>
              ¿Quieres seguir socializando como en el aula? ¡En línea también
              hay maneras de convivir con tus compañeros! Nuestros alumnos y
              tutores están en contacto y comparten sus hobbies y aficiones a
              través de la plataforma Discord, un canal de atención directa y de
              interacción social, donde se realizan dinámicas, foros de
              discusión y ¡hasta se juegan videojuegos!
            </Fragment>
          }
          img={tutores}
          direction='row-reverse'
          img_alt='Preparatoria en linea con tutores personalizados.'
        />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <Seccion
          titulo='Calidad Nacional e Internacional'
          subtitulo='Estándares Globales'
          descripcion={
            <Fragment>
              Podrás continuar tus estudios sin límites, donde quieras, con
              nuestro <b>plan educativo</b> con <b>Certificado Internacional</b>{' '}
              avalado desde Estados Unidos, por el estado de Florida. Por lo que
              tu educación tendrá la mejor calidad con{' '}
              <b>estándares globales</b>.<br />
              <br />
              En México, contamos con{' '}
              <b>Reconocimiento de Validez Oficial de Estudios</b> (RVOE),
              emitido por la Dirección General de Bachilleratos de la SEP. Así
              que no te preocupes, tendrás cubiertas todas tus{' '}
              <b>necesidades educativas</b>.
            </Fragment>
          }
          img={calidad}
          img_alt='Prepa en linea con Certificación Internacional.'
          bg_color='rgb(240,240,240)'
        />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <Seccion
          titulo='Certificaciones'
          subtitulo='¿Qué documento se obtiene?'
          descripcion={
            <Fragment>
              <b>Preparatoria/Bachillerato (12vo)</b>
              <br />
              Al terminar el programa, podrás indicar el país en el que
              estudiarás la universidad o trabajarás, para decidir cuál de los
              siguientes certificados mejor te conviene:
              <br />
              <ul>
                <li>
                  Certificación de High School con validez en Estados Unidos,
                  desde nuestra sede en el mismo país.
                </li>
                <li>
                  Certificación SEP para residentes en México, desde nuestra
                  otra sede a través de nuestro RVOE (esto requiere cursar por
                  lo menos 6 cuatrimestres).
                </li>
                <li>
                  Transcript con Validez Nacional para estudiantes de otros
                  países del mundo.
                </li>
              </ul>
              <br />
            </Fragment>
          }
          img={certificaciones}
          direction='row-reverse'
          img_alt='Prepa en linea con validez ante la SEP.'
        />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <FAQ />
      </Suspense>
      <ArrowDown />
    </Fragment>
  );
};

export default Informacion;
