import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const colors = {
  primary: { background: '#61C4B2', hover: '#B5E3DB' },
  secondary: { background: '#B261C4', hover: '#D4A6DE' },
  accent: { background: '#958437', hover: '#D8CC97' },
};

export const CustomButton = ({
  children,
  variant = 'primary',
  disabled,
  ...props
}) => {
  const useStyles = makeStyles({
    root: {
      backgroundColor: colors[variant].background,
      color: '#fff',
      fontSize: '14px',
      fontWeight: 700,
      opacity: disabled ? 0.5 : 1,
      cursor: disabled ? 'not-allowed' : 'pointer',
      '@media (min-width: 768px': {
        fontSize: '16px',
      },
    },
  });

  const classes = useStyles();

  return (
    <Button className={classes.root} {...props}>
      {children}
    </Button>
  );
};
