import React from 'react';
import { CustomButton } from '../../../../componentes/CustomButton';
import { Typography, Box } from '@material-ui/core';

export const DiscordButton = () => {
  return (
    <Box
      style={{ width: '100%' }}
      display='flex'
      flexDirection='column'
      alignItems='end'
      gridRowGap='16px'>
      <Typography variant='body1' style={{ color: '#21594F', fontWeight: 700 }}>
        ¿Tienes dudas con tus materias o necesitas ayuda con algún trámite?{' '}
      </Typography>
      <CustomButton
        variant='secondary'
        target='_blank'
        component='a'
        href='https://discord.gg/NJMAaQt3qb'>
        Conéctate a Discord
      </CustomButton>
    </Box>
  );
};
