import React from 'react';

const getGrade = (nivel) => {
  // nivel++;
  switch (nivel) {
    case 1:
    case 3:
      return nivel + 'er';
    case 2:
      return nivel + 'do';
    case 4:
    case 5:
    case 6:
      return nivel + 'to';
    case 10:
    case 7:
      return nivel + 'mo';
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 8:
      return nivel + 'vo';
    case 9:
      return nivel + 'no';
  }
};

const getPaymentStatus = (status) => {
  switch (status) {
    case 'paid':
      return <b style={{ color: '#4caf50' }}>Pagado</b>;
    case 'pending_payment':
      return <b style={{ color: '#ffc107' }}>Pendiente</b>;
    case 'declined':
      return <b style={{ color: '#f44336' }}>Declinado</b>;
    case 'expired':
      return <b style={{ color: '#795548' }}>Expirado</b>;
    case 'refunded':
      return <b style={{ color: '#0288d1' }}>Reembolsado</b>;
    default:
      return <b style={{ color: '#757575' }}>Desconocido</b>;
  }
};

const getCuatrimestre = (month) => {
  switch (true) {
    case month < 4:
      return 1;
    case month > 3 && month < 8:
      return 2;
    case month > 7 && month < 12:
      return 3;
    default:
      return 1;
  }
};

const getPeriodicidad = (fecha) => {
  const now = fecha ? fecha : new Date();
  console.log(getCuatrimestre(now.getMonth()));
  switch (getCuatrimestre(now.getMonth())) {
    case 1:
      return now.getMonth() + 1;
    case 2:
      console.log(now.getMonth() - 3);
      return now.getMonth() - 3;
    case 3:
      return now.getMonth() - 7;
    default:
      return 0;
  }
};

const calculaAPagar = (cargos, periodicidad) => {
  return (cargos / 4) * periodicidad;
};

const getEstadoPagos = (deuda) => {
  return deuda > 0
    ? {
        message:
          'Tienes adeudos que pagar, por favor procede a Realizar tu pago.',
        status: 'error',
      }
    : {
        message: 'Tus pagos mensuales van al corriente.',
        status: 'info',
      };
};

const validate = (values) => {
  // if (process.env.TEST) return { status: true };
  for (var i in values) {
    const element = values[i];
    const { value, type, label } = element;
    switch (type) {
      case 'PASSWORD':
        let { a, b } = value;
        if (!a && !b) {
          a = value;
          b = a;
        }
        if (a.length < 8)
          return {
            status: false,
            message: 'La contraseña debe contener al menos 8 caracteres.',
          };
        else if (!/[A-Z]/.test(a))
          return {
            status: false,
            message: 'La contraseña debe contener al menos una mayúscula.',
          };
        else if (!/[a-z]/.test(a))
          return {
            status: false,
            message: 'La contraseña debe contener al menos una minúscula.',
          };
        else if (!/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(a))
          return {
            status: false,
            message: 'La contraseña debe contener un caracter especial.',
          };
        else if (!/\d/.test(a))
          return {
            status: false,
            message: 'La contraseña debe contener al menos un número.',
          };
        else if (a != b)
          return {
            status: false,
            message: 'Las contraseñas deben coincidir.',
          };
        else break;
      // else return { status: true };
      case 'TEXT':
        if (value == '') {
          return {
            status: false,
            message: 'Por favor, ingrese ' + label,
          };
        } else break;
      // else return { status: true };
      case 'EMAIL':
        if (value == '') {
          return {
            status: false,
            message: 'Por favor, ingrese ' + label,
          };
        } else if (value.indexOf('@') < -1) {
          return {
            status: false,
            message: 'Por favor, ingrese ' + label,
          };
        } else break;
      // } else return { status: true };
      case 'CP':
        if (value == 0) {
          return {
            status: false,
            message: 'Por favor, ingrese ' + label,
          };
        } else break;
      // else return { status: true };
      case 'TEL':
        if (value == '') {
          return {
            status: false,
            message: 'Por favor, ingrese ' + label,
          };
        } else break;
      // else return { status: true };
      case 'CAPTCHA':
        if (value == null || !value) {
          return {
            status: false,
            message: 'Debe pasar el captcha para continuar.',
          };
        } else break;
      case 'FILE':
        if (value == null || !value) {
          return {
            status: false,
            message: 'Por favor, cargue ' + label,
          };
        } else break;
      // else return { status: true };
      case 'CHECK':
        if (!value) {
          return {
            status: false,
            message: 'Por favor, acepte ' + label,
          };
        } else break;
      // else return { status: true };
    }
  }
  return { status: true };
};

const handleImage = (file, dispatch, assign) => {
  const { type, size } = file;
  if (
    ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'].indexOf(type) === -1
  ) {
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        message:
          'Los formatos soportados para este archivo son: JPEG, JPG, PNG, BMP',
        type: 'error',
      },
    });
  } else if (size > 2000000) {
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        message:
          'El peso de la fotografía no puede ser mayor a 2mb. (La suya pesa ' +
          size / 1000000 +
          'mb)',
        type: 'error',
      },
    });
  } else {
    assign({
      url: URL.createObjectURL(file),
      raw: file,
    });
    // setFotografia({
    //   url: URL.createObjectURL(file),
    //   raw: file,
    // });
  }
};

const handleDoc = (file, dispatch, assign) => {
  const { type, size } = file;
  if (['application/pdf'].indexOf(type.toString()) === -1) {
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        message: 'El formato soportado para este archivo es PDF',
        type: 'error',
      },
    });
  } else if (size > 5000000) {
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        message:
          'El peso del documento no puede ser mayor a 5mb. (El suyo pesa ' +
          size / 1000000 +
          'mb)',
        type: 'error',
      },
    });
  } else {
    assign({
      url: URL.createObjectURL(file),
      raw: file,
    });
  }
};

const downloadFile = async (type, user, dispatch) => {
  dispatch({ type: 'START_LOADING' });
  const axios = await import('axios');
  // const { data } = await axios.post(
  //   process.env.PREPA_SERVER + "estudiantes/" + type ?? "constancia",
  //   params ?? null
  // );
  try {
    const url =
      type === 'boleta'
        ? `${process.env.PREPA_SERVER}estudiantes/${user.matricula}/${
            type ?? 'constancia'
          }`
        : `${process.env.PREPA_SERVER}estudiantes/${user}/${
            type ?? 'constancia'
          }`;

    const body =
      type === 'boleta'
        ? {
            formulario: {
              grado: user.periodo,
            },
          }
        : {};

    const { data } = await axios.post(url, body);

    dispatch({ type: 'STOP_LOADING' });

    dispatch({
      type: 'CLOSE_DIALOG',
    });

    var hiddenElement = document.createElement('a');
    hiddenElement.href = `${process.env.PREPA_SERVER}estudiantes/download/${nPath}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = type + '_prepaenlinea.pdf';
    hiddenElement.click();
  } catch (error) {
    dispatch({
      type: 'ALERT',
      payload: {
        open: true,
        message: data.error,
        type: 'error',
      },
    });
  }

  // if (data.error)
  // dispatch({
  //   type: "ALERT",
  //   payload: {
  //     open: true,
  //     message: data.error,
  //     type: "error",
  //   },
  // });
  // else {
  // dispatch({
  //   type: "CLOSE_DIALOG",
  // });
  // var nPath = btoa(data);
  // var hiddenElement = document.createElement("a");
  // hiddenElement.href =
  //   process.env.PREPA_SERVER + "estudiantes/download/" + nPath;
  // hiddenElement.target = "_blank";
  // hiddenElement.download = type + "_prepaenlinea.pdf";
  // hiddenElement.click();
  // }
};

const padEnd = (str, size) => {
  if (str.length > size) return str;
  var spectedPadding = size - str.length;
  return str + ' '.repeat(spectedPadding);
};

const shortenText = (text, size) => {
  if (text === undefined) return '';
  return text.length > size
    ? text.substring(0, size - 10) + ' ...'
    : padEnd(text, size);
};

export {
  getGrade,
  getPaymentStatus,
  getCuatrimestre,
  getPeriodicidad,
  calculaAPagar,
  getEstadoPagos,
  validate,
  handleImage,
  handleDoc,
  downloadFile,
  shortenText,
};
