import * as R from 'ramda';
import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { sesionStore } from '../utils/sesionStore';
import {
  AppBar,
  Button,
  Collapse,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Fade,
  IconButton,
  List,
  ListItem,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
  withWidth,
  MenuItem,
  styled,
  Menu,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//Icons
import {
  Dehaze,
  Phone as WhatsAppIcon,
  AccountCircle as UserIcon,
  ExpandMore,
} from '@material-ui/icons';

import { PERIODO_INSCRIPCION } from '../API';

//Images
import prepaenlinea from '../imagenes/logo.svg';
import { globalStore } from '../utils/globalStore';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    display: 'none',
    color: '#1D1B20',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  phone: {
    flexGrow: 3,
    display: 'none',
    color: '#1D1B20',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
    },
  },
  menuOption: {
    display: 'none',
    color: '#1D1B20',
    textDecoration: 'none !important',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  menuOptionA: {
    fontSize: '1rem',
    color: '#1D1B20',
    display: 'block',
    padding: '16px 0',
    borderBottom: '1px solid #e0e0e0',
  },
  appbar: {
    backgroundColor: 'white',
  },
  toolbar: {
    minHeight: 94,
    padding: '0 5%',
  },
  container: {
    marginTop: 84,
  },
  mainLogo: {
    width: 52,
    height: 52,
    opacity: 0,
  },
  appear: {
    opacity: 1,
    transitionProperty: 'all !important',
    transitionDuration: '1.5s',
    transitionDelay: '.5s',
  },
  list: {
    width: 300,
    height: '100%',
  },
  fullList: {
    width: 'auto',
  },
  mobileMenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    rowGap: ' 32px',
    padding: '0 32px',
  },
  accordion: {
    padding: 0,
    boxShadow: 'none',
    borderBottom: '1px solid #e0e0e0',
  },
  accordionSummary: {
    padding: '4px 0',
    fontSize: '1rem',
  },
  logoutButton: {
    color: '#1D1B20',
    fontSize: '1rem',
    textDecoration: 'none',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    padding: '10px 24px',
    textAlign: 'left',
    fontFamily: 'QuickSand',
  },
  accordionDetails: {
    background: '#f5f5f5',
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      horizontal: 'left',
    }}
    transformOrigin={{
      horizontal: 'left',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    top: '60px !important',
  },
}));

const MenuOption = ({ link, classes, children, onClick }) => {
  return (
    <NavLink to={link} onClick={onClick}>
      <ListItem button className={classes} variant='text'>
        {children}
      </ListItem>
    </NavLink>
  );
};

const ElevationScroll = ({ children, window }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Header = (props) => {
  const global = React.useContext(sesionStore);
  const { state, dispatch } = global;
  const { sesion, intro } = state;

  const [logged, setLogged] = React.useState(null);
  const [intro0, setIntro0] = React.useState(false);
  const [intro2, setIntro2] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const cerrarSesion = () => {
    dispatch({ type: 'CLEAR_SESION' });
    window.location.href = '/cuenta';
  };

  const open = Boolean(anchorEl);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [position, setPosition] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setPosition({ ...position, [anchor]: open });
  };

  React.useEffect(() => {
    setLogged(sesion);
    if (!intro) {
      setTimeout(() => {
        setIntro0(true);
        dispatch({ type: 'SET_INTRO', payload: true });
      }, 900);
      setTimeout(() => {
        setIntro2(true);
      }, 1200);
    }
  }, [sesion, intro]);

  const anchor = 'right';

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      onKeyDown={toggleDrawer(anchor, false)}>
      <List className={classes.mobileMenu}>
        {logged !== null ? (
          <Accordion className={classes.accordion}>
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={<ExpandMore />}
              aria-controls='account-menu'
              id='account-menu'>
              <NavLink to={'/cuenta'}>
                <Box
                  display='flex'
                  alignItems='center'
                  gridColumnGap='8px'
                  color='#1D1B20'>
                  <UserIcon />
                  {logged?.Persona?.nombre}
                </Box>
              </NavLink>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Box display='flex' flexDirection='column' gridRowGap='16px'>
                <NavLink
                  className={classes.logoutButton}
                  to={'/change-password'}
                  onClick={toggleDrawer(anchor, false)}>
                  Cambiar contraseña
                </NavLink>
                <button className={classes.logoutButton} onClick={cerrarSesion}>
                  Cerrar sesión
                </button>
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : (
          <MenuOption
            link='/cuenta'
            onClick={toggleDrawer(anchor, false)}
            classes={classes.menuOptionA}>
            INGRESA
          </MenuOption>
        )}
        <MenuOption
          link='/inicio'
          onClick={toggleDrawer(anchor, false)}
          classes={classes.menuOptionA}>
          INICIO
        </MenuOption>
        <MenuOption
          link='/informacion'
          onClick={toggleDrawer(anchor, false)}
          classes={classes.menuOptionA}>
          INFORMACIÓN
        </MenuOption>
        <MenuOption
          link='/curricula'
          onClick={toggleDrawer(anchor, false)}
          classes={classes.menuOptionA}>
          CURRÍCULA
        </MenuOption>
        <MenuOption
          link='/precios'
          onClick={toggleDrawer(anchor, false)}
          classes={classes.menuOptionA}>
          PRECIOS
        </MenuOption>
        <MenuInscripcionORe
          user={logged}
          menuClass={classes.menuOptionA}
          toggleDrawer={toggleDrawer(anchor, false)}
        />
      </List>
    </div>
  );

  return (
    <>
      <Drawer
        anchor={anchor}
        open={position[anchor]}
        onClose={toggleDrawer(anchor, false)}>
        {list(anchor)}
      </Drawer>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar className={classes.appbar}>
          <Collapse in={intro0} timeout={1000}>
            <Toolbar className={classes.toolbar}>
              <NavLink to={'/inicio'}>
                <Slide in={intro2} timeout={1500} direction='down'>
                  <IconButton
                    edge='start'
                    color='inherit'
                    aria-label='open drawer'>
                    <img
                      className={clsx(classes.mainLogo, {
                        [classes.appear]: intro2,
                      })}
                      src={prepaenlinea}
                    />
                  </IconButton>
                </Slide>
              </NavLink>
              <Typography className={classes.title} variant='h6' noWrap>
                prepaenlinea.mx
              </Typography>
              <div className={classes.phone}>
                <WhatsAppIcon
                  fontSize='small'
                  style={{ transform: 'translateY(4px)', opacity: '.8' }}
                />
                <a href='tel:+522224540048'>
                  <Typography
                    variant='body1'
                    style={{ display: 'inline', paddingBottom: 10 }}>
                    +52 (222) 454 0048
                  </Typography>
                </a>
              </div>
              {props.width === 'sm' || props.width === 'xs' ? (
                <>
                  <div style={{ textAlign: 'center', width: '60%' }}>
                    <Typography variant='body1'>
                      <a href='tel:+522224540048'>
                        +52 (222) 454 0048
                        <WhatsAppIcon
                          style={{ transform: 'translateY(6px)' }}
                        />
                      </a>
                    </Typography>
                  </div>
                  <IconButton
                    style={{
                      display: 'block',
                      position: 'absolute',
                      right: '5%',
                    }}
                    onClick={toggleDrawer(anchor, true)}>
                    <Dehaze fontSize='large' />
                  </IconButton>
                </>
              ) : (
                <>
                  <MenuOption
                    link='/inicio'
                    classes={classes.menuOption}
                    time={1000}>
                    INICIO
                  </MenuOption>
                  <MenuOption
                    link='/informacion'
                    classes={classes.menuOption}
                    time={1250}>
                    INFORMACIÓN
                  </MenuOption>
                  <MenuOption
                    link='/curricula'
                    classes={classes.menuOption}
                    time={1500}>
                    CURRÍCULA
                  </MenuOption>
                  <MenuOption
                    link='/precios'
                    classes={classes.menuOption}
                    time={1500}>
                    PRECIOS
                  </MenuOption>
                  <MenuInscripcionORe
                    user={logged}
                    menuClass={classes.menuOption}
                    toggleDrawer={toggleDrawer(anchor, false)}
                  />
                  <Fade in={intro2} timeout={1000}>
                    <NavLink to={'/cuenta'}>
                      {logged !== null ? (
                        <div>
                          <Button
                            className={classes.menuOption}
                            variant='text'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            startIcon={<UserIcon />}
                            onClick={handleClick}>
                            {logged?.Persona?.nombre}
                          </Button>
                          <StyledMenu
                            id='account-menu'
                            anchorEl={anchorEl}
                            onClose={handleCloseMenu}
                            open={open}>
                            <MenuItem onClick={handleCloseMenu}>
                              <NavLink to={'/change-password'}>
                                Cambiar contraseña
                              </NavLink>
                            </MenuItem>
                            <MenuItem onClick={cerrarSesion}>
                              Cerrar sesión
                            </MenuItem>
                          </StyledMenu>
                        </div>
                      ) : (
                        <Button className={classes.menuOption} variant='text'>
                          INGRESA
                        </Button>
                      )}
                    </NavLink>
                  </Fade>
                </>
              )}
            </Toolbar>
          </Collapse>
        </AppBar>
      </ElevationScroll>
      <div className={classes.container}>{props.children}</div>
    </>
  );
};

const MenuInscripcionORe = ({ user, menuClass, toggleDrawer }) => {
  const [inPeriodo, setInPeriodo] = React.useState(false);
  const global = useContext(globalStore);
  const { dispatch } = global;

  React.useEffect(() => {
    const puedeReinscribir = async () => {
      let inscripcion = undefined;
      try {
        inscripcion = (await PERIODO_INSCRIPCION()).data;
      } catch (error) {
        console.log('Error', error.response?.data?.message || error);
      }
      if (!inscripcion) {
        // caso no hay periodos activos
        setInPeriodo(false); // inPeriodo deshabilita todo el botón
        dispatch({
          type: 'IS_ENROLLMENT',
          payload: false,
        });
        return;
      }
      if (!user && !inscripcion) {
        // No hay periodo de inscripción y no hay usuario
        setInPeriodo(false);
        dispatch({
          type: 'IS_ENROLLMENT',
          payload: false,
        });
        return;
      }
      if (!user && inscripcion) {
        // Hay periodo de inscripción y no hay usuario
        setInPeriodo(true);
        dispatch({
          type: 'IS_ENROLLMENT',
          payload: true,
        });
        return;
      }
      if (user && inscripcion) {
        setInPeriodo(false);
        dispatch({
          type: 'IS_ENROLLMENT',
          payload: false,
        });
      }
    };
    puedeReinscribir();
  }, [user]);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const tienePagoCompletado = (student) => {
    const periodo = R.last(student?.Periodos);
    // Si tiene pagoCompletado o estado "ConMaterias" se devuelve true
    return periodo?.pagoCompletado || R.includes(student?.EstatusId, [10, 11]);
  };

  return inPeriodo ? (
    <>
      <MenuOption
        link='inscripciones'
        onClick={(event) => {
          if (!R.isNil(user) && !tienePagoCompletado(user)) {
            setOpen(true);
            event.preventDefault();
          }
          toggleDrawer(event);
        }}
        classes={menuClass}
        time={1500}>
        INSCRIPCIÓN
      </MenuOption>
      <Mensaje open={open} handleClose={handleClose} menuClass={menuClass} />
    </>
  ) : null;
};

const Mensaje = ({ open, handleClose, menuClass }) => {
  return (
    <Dialog open={open} maxWidth='md' className={menuClass}>
      <DialogTitle>¡Aviso!</DialogTitle>
      <DialogContent>
        <Typography variant='body1'>
          Antes de reinscribirte, debes terminar de pagar tu colegiatura.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='contained' color='primary'>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withWidth()(Header);
