import { makeStyles } from '@material-ui/core';

export const gradesComponentsStyles = makeStyles(() => ({
  sectionHeader: {
    margin: 0,
    fontSize: '16px',
    fontWeight: 700,
    color: '#B261C4',
  },
  downloadEnglish: {
    margin: 0,
    fontSize: '14px',
    fontWeight: 700,
    color: '#77692c',
  },
  downloadSpanish: {
    margin: 0,
    fontSize: '14px',
    fontWeight: 700,
    color: '#21594f',
  },
  tableHeader: {
    margin: 0,
    fontSize: '16px',
    fontWeight: 700,
    color: '#6E6779',
  },
  scholarship: {
    margin: 0,
    fontSize: '14px',
    fontWeight: 700,
    color: '#fff',
  },
}));
