import React from 'react';
import { Box } from '@material-ui/core';
import { WarningOutlined } from '@material-ui/icons';

export const WarningAlert = ({ children }) => {
  return (
    <Box
      padding='16px'
      borderRadius='8px'
      style={{ backgroundColor: '#C46173' }}
      gridGap='8px'
      alignItems='start'
      display='flex'>
      <WarningOutlined style={{ color: '#fff' }} />
      {children}
    </Box>
  );
};
