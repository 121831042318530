import React, { useState } from 'react';
import { Avatar, Box, Typography, Badge } from '@material-ui/core';
import { AssignmentInd, Edit, Email, Phone } from '@material-ui/icons';
import { studentInfoStyles } from './StudentInfo.style';
import { BUCKET } from '../../../../../utils';
import * as R from 'ramda';
import { ImageSelector } from '../../components/ImageSelector';

export const StudentInfo = ({ studentInfo, studentAvatar }) => {
  const [studentImage, setStudentImage] = useState(null);
  const [openUploadImageDialog, setOpenUploadImageDialog] = useState(false);

  const classes = studentInfoStyles();
  const { name, dateOfEnrollment, email, phone, schoolId, status } =
    studentInfo;

  const defineStatus = (status) => {
    switch (status) {
      case 'Baja':
        return 'Baja';
      case 'Graduado':
        return 'Graduado';
      case 'Inscrito':
        return 'Inscrito';
      default:
        return 'No inscrito';
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.infoContainer}>
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <button
              onClick={() => setOpenUploadImageDialog(true)}
              className={classes.editButton}>
              <Edit className={classes.editIcon} />
            </button>
          }>
          <Avatar
            className={classes.avatar}
            alt={name}
            src={
              studentImage
                ? studentImage.url
                : `${BUCKET}/images/alumnos/${R.last(
                    studentAvatar?.split('/') || ['standard.png']
                  )}`
            }
          />
        </Badge>
        <Typography variant='h6'>{name}</Typography>
        <Typography variant='subtitle2' className={classes.status}>
          {defineStatus(status)}
        </Typography>
        <p className={classes.infoP}>Estudiante desde {dateOfEnrollment}</p>
        <Box
          display='flex'
          gridColumnGap='48px'
          flexDirection={['column', 'row']}
          gridRowGap='8px'
          width={['100%', 'auto']}
          alignItems='center'>
          <Box className={classes.infoPContainer}>
            <Email />
            <p className={classes.infoP}>{email}</p>
          </Box>
          <Box className={classes.infoPContainer}>
            <Phone />
            <p className={classes.infoP}>{phone}</p>
          </Box>
          <Box className={classes.infoPContainer}>
            <AssignmentInd />
            <p className={classes.infoP}>{schoolId}</p>
          </Box>
        </Box>
      </Box>
      <ImageSelector
        image={studentImage}
        setImage={setStudentImage}
        openDialog={openUploadImageDialog}
        setOpenDialog={setOpenUploadImageDialog}
      />
    </Box>
  );
};
