import React, { useReducer, createContext } from 'react';
import * as store from './localStore';

const sesionStore = createContext(null);
const { Provider } = sesionStore;

const initialState = {
  sesion: null,
  presesion: null,
  intro: false,
  loading: false,
  lang: 'esp',
  snackbar: {
    open: false,
    message: '',
    type: 'success',
  },
  dialog: {
    dialog_open: false,
    dialog_message: '',
    process: null,
    title: '',
    buttonText: false,
  },
  clienteSeleccionado: null,
  nuevaCuenta: null,
  metodoSeleccionado: '',
  metodoPropiedades: null,
  opcionPagos: 0,
  refreshMetodos: true,
};

const sesionReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'FETCH_SESION':
      const { estudiante, jwt } = payload;
      const { matricula } = estudiante;
      store.setItems('educsesion', { user: matricula, hash: jwt });
      return { ...state, sesion: estudiante };
    case 'UPDATE_USER':
      return { ...state, sesion: payload };
    case 'CLEAR_SESION':
      store.setItems('educsesion', null);
      return { ...state, sesion: null };
    case 'SET_INTRO':
      return { ...state, intro: payload };
    case 'ALERT':
      return { ...state, snackbar: payload };
    case 'OPEN_DIALOG':
      return { ...state, dialog: payload };
    case 'CLOSE_DIALOG':
      return {
        ...state,
        dialog: {
          dialog_open: false,
          dialog_message: '',
          process: null,
          title: '',
        },
      };
    case 'START_LOADING':
      return { ...state, loading: true };
    case 'STOP_LOADING':
      return { ...state, loading: false };
    case 'FETCH_NUEVA_CUENTA':
      return { ...state, nuevaCuenta: payload };
    case 'FETCH_CLIENTE_SELECCIONADO':
      return { ...state, clienteSeleccionado: payload };
    case 'SELECT_METODO':
      const { id } = payload;
      return { ...state, metodoSeleccionado: id, metodoPropiedades: payload };
    case 'SELECT_METODO_SINGLE':
      const { propiedades, dialog } = payload;
      return {
        ...state,
        metodoSeleccionado: propiedades.id,
        metodoPropiedades: propiedades,
        dialog: dialog,
      };
    case 'SET_OPCION_PAGO':
      return { ...state, opcionPagos: payload };
    case 'REFRESH_METODOS':
      const { refreshMetodos } = state;
      return { ...state, refreshMetodos: !refreshMetodos };
  }
};

const SesionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sesionReducer, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { sesionStore, SesionProvider };
