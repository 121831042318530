import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

const Terminos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        padding: '10vh 7.5vw 10vh 7.5vw',
      }}
    >
      <Typography variant='h5' align='justify'>
        <b>
          CONTRATO Y TÉRMINOS DE USO DE LA PLATAFORMA EDUCATIVA DENOMINADA
          PREPAENLINEA.MX (EN ADELANTE “LA PLATAFORMA”), QUE CELEBRAN EDUCACIÓN
          PRÁCTICA PARA LA VIDA (EN ADELANTE “LA PROVEEDORA”), A TRAVÉS DE SU
          REPRESENTANTE LEGAL, EN SU CARÁCTER DE PROVEEDORA DEL SERVICIO, Y, POR
          LA OTRA, EL USUARIO DEL SERVICIO (EN ADELANTE “USUARIO” O “USUARIOS”),
          EN SU CARÁCTER DE PADRE, MADRE, TUTOR DEL ESTUDIANTE Y/O ESTUDIANTE,
          ASÍ COMO LOS CENTROS EDUCATIVOS DIGITALES, INDISTINTAMENTE, DE NOMBRE
          _________________, QUIEN CURSARÁ EL _________ GRADO, EQUIVALENTE AL
          _________ GRADO, SUJETÁNDOSE AMBAS PARTES A LO ESTABLECIDO EN EL TEXTO
          DEL PRESENTE CONTRATO:
        </b>
      </Typography>
      <br />
      <br />
      <br />
      <br />
      <Typography variant='h5' align='center'>
        DECLARACIONES
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant='h5' align='justify'>
        <b>1.- DECLARA LA PROVEEDORA LO SIGUIENTE:</b>
        <br />
        <br />
        1.1- Que es una Asociación Civil constituida conforme a las leyes
        mexicanas relativas a las de su tipo, y que, conforme a su objeto
        social, ofrece a los padres educadores en el hogar, a través de
        <b> prepaenlinea.mx</b>, institución educativa debidamente registrada en
        Miami, Florida, U.S.A, contenidos educativos de nivel medio superior
        (preparatoria), denominación correspondiente a la vigente Ley General de
        Educación.
        <br />
        <br />
        1.2.- Que es legalmente usuaria de los derechos de autor respecto de
        dichos contenidos educativos, los cuales se encuentran debidamente
        registrados y protegidos a favor de su autora por las leyes mexicanas,
        norteamericanas y tratados internacionales sobre la materia, por lo que
        hace a la propiedad intelectual de los mismos.
        <br />
        <br />
        1.3.- Que los contenidos educativos se encuentran alojados dentro de la
        respectiva plataforma denominada <b>prepaenlinea.mx</b>, a la cual
        accederá el usuario, dependiendo del grado y nivel al que se inscriba o
        sea inscrito.
        <br />
        <br />
        1.4.- Que a fin de cumplir con su objeto social, ofrece sus servicios a
        todas las personas interesadas en ellos, sin distinción o discriminación
        motivada por origen étnico o nacional, género, edad, discapacidades,
        condición social, condiciones de salud, religión, opiniones preferencias
        sexuales, estado civil o cualquier otra que atente contra la dignidad
        humana del <b>usuario</b>; mostrando <b>la proveedora</b> en todo
        momento, a través de sus integrante y colaboradores el debido respeto a
        sus Derechos Fundamentales.
        <br />
        <br />
        1.5.- Que al término del grado o ciclo que el estudiante curse,
        <b> la proveedora</b> se compromete a entregarle al <b>usuario</b> las
        evidencias del desempeño de su hijo, pupilo o estudiante, según sea el
        caso, en el uso de <b>la plataforma</b>, a fin de que pueda tramitar la
        revalidación o reconocimiento de validez oficial de estudios en su
        respectivo país de residencia; de conformidad con las respectivas leyes
        y disposiciones aplicables al caso concreto.
        <br />
        <br />
        1.6.- Que conforme a la legislación mexicana vigente y tratados
        internacionales al respecto, se compromete y obliga a resguardar y a no
        revelar los datos personales del <b>usuario</b>, salvo por mandato
        judicial debidamente fundado y motivado, en el que se cumplan las
        formalidades esenciales establecidas en la ley de la materia.
        <br />
      </Typography>
      <br />
      <br />
      <Typography variant='h5' align='justify'>
        <b>2.- DECLARA EL USUARIO LO SIGUIENTE:</b>
        <br />
        <br />
        2.1- Su expreso deseo de usar <b>la plataforma de la proveedora</b>, y
        que así mismo aceptan los términos del presente contrato en todas y cada
        una de sus partes.
        <br />
        <br />
        2.2.- Que conoce el contenido y acepta en su totalidad el Reglamento de
        Uso de las Plataformas.
        <br />
        <br />
        2.3.- Que conoce el funcionamiento de las plataformas y que, en su
        defecto, antes de que el <b>usuario</b> acceda a resolver los contenidos
        educativos, se compromete y obliga, a través de su padre, madre, tutor o
        el propio estudiante, según sea el caso, a aprender su uso apoyado en
        las explicaciones escritas, así como en los vídeos respectivos que para
        ese efecto se ponen a su disposición dentro del sitio web de{' '}
        <b>la proveedora</b>, denominado <b>prepaenlinea.mx</b>.
        <br />
        <br />
        2.4.- Que junto con su hijo, pupilo o el propio estudiante, según sea el
        caso, pondrá todo su empeño en lograr que el aprendizaje se cumpla, y
        que para este efecto se podrá apoyar de material externo a los
        contenidos educativos, complementando la teoría con la práctica; tal y
        como visitas a museos, clases prácticas en el campo, visitas a plantas
        industriales, oficinas gubernamentales y todas aquellas actividades que
        sean susceptibles de apoyar dentro del proceso educativo.
        <br />
        <br />
        2.6.- Que se compromete a respetar siempre a todos los demás usuarios y
        compañeros de estudio, así como a no hacer mal uso del nombre de usuario
        y contraseña que se le asigne; tal y como compartirlo con otra u otras
        personas, ya que esto, conforme al Reglamento de Uso de las Plataformas,
        será motivo de expulsión de la institución educativa, sin derecho a
        reinscripción.
        <br />
        <br />
        2.7.- Que se compromete y obliga a contar tanto con el equipo de cómputo
        requerido, así como con la conexión a Internet suficiente para que el
        hijo, estudiante o pupilo, según sea el caso, pueda acceder, resolver e
        interactuar con <b>la plataforma</b>. Siendo siempre su exclusiva
        responsabilidad la falta de capacidad en uno y otro caso, y no así de{' '}
        <b>la proveedora</b>, resultando imposible volver a abrir evaluaciones o
        contenidos que el estudiante debió atender en su momento y que por
        alguna razón no lo hizo.
        <br />
        <br />
        Las partes declaran que al no existir error, dolo, mala fe o cualquier
        otro vicio de la voluntad que pudiera nulificar la validez del presente
        instrumento, ambas acuerdan en sujetarse al tenor de lo establecido en
        las siguientes:
        <br />
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant='h5' align='center'>
        <b>
          CLÁUSULAS
          <br />
          CAPÍTULO ÚNICO TÉRMINOS Y CONDICIONES PARA EL USO DE LAS PLATAFORMAS
          <br />
          DE LA INSCRIPCIÓN
        </b>
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant='h5' align='justify'>
        PRIMERA.- El <b>usuario</b> se obliga a realizar personalmente el
        procedimiento de inscripción al cuatrimestre de su elección, tal y como
        se indica en las instrucciones; de tal manera que no es responsabilidad
        de <b>la proveedora</b> si por alguna omisión o error dicho
        procedimiento no se concluye con éxito.
        <br />
        <br />
        Debiendo revisar minuciosamente y corregir lo necesario, y siendo
        igualmente su responsabilidad tanto la veracidad, así como la
        oportunidad de los datos asentados en el procedimiento. En caso de
        realizar el procedimiento de inscripción por medio de interpósita
        persona, el <b>usuario</b> asume por completo la responsabilidad de
        dicho trámite para el caso de hacerlo de manera incorrecta.
        <br />
        <br />
        SEGUNDA.- En caso de los Centros Educativos Digitales, siempre será
        responsabilidad de la persona titular del mismo la veracidad y
        oportunidad de los datos de sus estudiantes, así como de su proceso de
        inscripción y aprendizaje, conforme al artículo que antecede.
        <br />
        <br />
        Ambos usuarios, tanto los estudiantes como los padres o tutores, así
        como las personas titulares de los Centros Educativos Digitales, se
        comprometen a hacer siempre buen uso tanto de <b>la plataforma</b>, así
        como de los contenidos educativos del cuatrimestre al cual inscribieron
        a sus respectivos hijos, pupilos o alumnos.
        <br />
        <br />
        TERCERA.- El <b>usuario</b> reconoce y acepta que bajo ningún motivo
        operará a su favor la devolución de las cantidades correspondientes a la
        inscripción y/o pago de la plataforma, mensualidad, colegiatura o
        recargos por pago extemporáneo,
        <br />
        <br />
        Por tal razón, es su exclusiva responsabilidad la realización correcta
        de dichos procedimientos de pago.
        <br />
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant='h5' align='center'>
        <b>DE LOS RECARGOS POR PAGO EXTEMPORÁNEO</b>
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant='h5' align='justify'>
        CUARTA.- Se denominan recargos a los pagos que, además de la colegiatura
        o mensualidad, debe pagar el <b>usuario</b>, cuando no realice el pago
        dentro del término de los primeros tres días naturales del mes
        correspondiente, que es el plazo otorgado para la realización del pago a
        favor de <b>la proveedora</b> por el uso de la plataforma.
        <br />
        <br />
        QUINTA.- Cuando el <b>usuario</b> no realice el pago de la colegiatura o
        mensualidad dentro de los primeros tres días naturales del mes que
        corresponda, deberá cubrir recargo de ____________ (número y letra de la
        cantidad), para los usuarios con residencia en los Estados Unidos
        Mexicanos, y de ____________ (número y letra de la cantidad en dólares
        estadounidenses), para los <b>usuarios</b> que no tengan su residencia
        en los Estados Unidos Mexicanos.
        <br />
        <br />
        SEXTA.- Si el <b>usuario</b> continuara omitiendo el pago de la
        colegiatura o mensualidad dentro de los primeros tres días naturales del
        mes que corresponda, a partir del día once de dicho mes no se permitirá
        el acceso a <b>la plataforma</b>, y no volverá a permitírsele, sino
        hasta una vez que realice el pago de la colegiatura o mensualidad
        correspondiente, así como sus respectivos recargos. Debiendo dar aviso
        del referido pago a _____ (nombre de la unidad a quien deberá dar
        aviso), acompañado del soporte documental que acredite el haberlo
        realizado. Atendiéndose, en el turno que sea recibida su petición, por
        el personal de apoyo para permitirle una vez más el acceso a la
        plataforma.
        <br />
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant='h5' align='center'>
        <b>DISPOSICIONES FINALES</b>
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant='h5' align='justify'>
        SÉPTIMA.- En virtud del presente contrato, la proveedora ofrece al
        usuario, el soporte técnico y académico para atender todos los asunto de
        esa naturaleza, mismo servicio que se tiene que solicitar por los medios
        institucionales y a través de los cuales se brindará la atención de
        manera oportuna.
        <br />
        <br />
        OCTAVA.- Las partes declaran que al no existir error, dolo, mala fe o
        cualquier otro vicio de la voluntad que pudiera nulificar la validez del
        presente instrumento, ambas acuerdan en sujetarse al tenor de lo
        estipulado en el mismo.
        <br />
        <br />
        NOVENA.- En caso de que se presente una controversia que derive del
        presente contrato o se relacione con él, las partes se obligan a tratar
        de llegar a una transacción amistosa de esa controversia mediante la
        mediación. Ésta tendrá lugar de conformidad con la Ley del Centro
        Estatal de Mediación del Estado de Puebla y su Reglamento.
        <br />
        <br />
        De no ser así, las partes en el presente contrato señalan expresamente
        la competencia de los Tribunales del distrito judicial de Zacatlán,
        Puebla, México, renunciando a cualquier otro fuera que pudiera
        corresponderles en razón de sus domicilios presentes o futuros, quedando
        este acuerdo de voluntades a la interpretación conforme a las leyes de
        lo Estados Unidos Mexicanos y los tratados internacionales aplicables.
        <br />
        <br />
        DÉCIMA.- Al no tener cláusula contraria a Derecho, las partes en el
        presente contrato se obligan a estar y pasar por él en todo tiempo, como
        si se tratase de cosa juzgada, firmándolo de conformidad a los ________
        días del mes de ________ del año ________.
        <br />
        <br />
        <br />
        <br />
        <br />
      </Typography>
      <Typography variant='h5' align='center'>
        ________________
        <br />
        LICENCIADO LEOPOLDO FERNÁNDEZ ARROYO, EN SU CARÁCTER DE REPRESENTANTE
        LEGAL DE EDUCACIÓN PRÁCTICA PARA LA VIDA.
        <br />
        <br />
        <br />
        <br />
        _____________
        <br />
        NOMBRE DEL USUARIO
      </Typography>
    </div>
  );
};

export default Terminos;
