export const validations = {
  fechaNacimiento: {
    required: {
      message: 'La fecha de nacimiento es requerida',
    },
    custom: {
      isValid: (value) => {
        const fechaNac = new Date(value);
        const now = new Date();
        const time = now.getTime() - fechaNac.getTime();
        return time > 441504000000;
      },
      message:
        'Verifica la fecha de nacimiento, no puedes tener menos de 14 años',
    },
  },
  centroEducativo: {
    required: {
      message: 'Es requerido el centro educativo',
    },
  },
  discapacidades: {
    required: {
      message: 'Las discapacidades no pueden ser nulas',
    },
  },
  religion: {
    required: {
      message: 'Es requerida la religión',
    },
  },
  sexo: {
    required: {
      message: 'Es requerido el sexo del estudiante',
    },
  },
  email: {
    required: {
      message: 'Es requerido el correo electrónico',
    },
    pattern: {
      value:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    },
  },
  telefono: {
    required: {
      message: 'Es requerido el teléfono',
    },
  },
  nombreTutor: {
    required: {
      message: 'Es requerido el nombre del tutor',
    },
  },
  correoTutor: {
    required: {
      message: 'Es requerido el correo del tutor',
    },
    pattern: {
      value:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    },
  },
  sexoTutor: {
    required: {
      message: 'Es requerido el sexo del tutor',
    },
  },
  fechaNacimientoTutor: {
    required: {
      message: 'La fecha de nacimiento del tutor es requerida',
    },
    custom: {
      isValid: (value) => {
        const fechaNac = new Date(value);
        const now = new Date();
        const time = now.getTime() - fechaNac.getTime();
        return time > 568024668000;
      },
      message:
        'Verifica la fecha de nacimiento, el tutor debe ser mayor de edad',
    },
  },
  telefonoTutor: {
    required: {
      message: 'Es requerido el teléfono del tutor',
    },
  },
  calle: {
    required: {
      message: 'Es requerido la calle',
    },
  },
  numExt: {
    required: {
      message: 'Es requerido el número exterior',
    },
  },
  numInt: {
    required: {
      message: 'Es requerido el número interior',
    },
  },
  ciudad: {
    required: {
      message: 'Es requerido la ciudad',
    },
  },
  colonia: {
    required: {
      message: 'Es requerido la colonia',
    },
  },
  codigoPostal: {
    required: {
      message: 'Es requerido el código postal',
    },
  },
  municipio: {
    required: {
      message: 'Es requerido el municipio',
    },
  },
  estado: {
    required: {
      message: 'Es requerido el estado',
    },
  },
  PaisId: {
    required: {
      message: 'Es requerido el país',
    },
  },
  NacionalidadId: {
    required: {
      message: 'Es requerida la nacionalidad',
    },
  },
};
