import * as store from '../../../../utils/localStore';
import React from 'react';
import { handleAlerts } from '../../../../utils';
import { Menu, MenuItem, styled, Backdrop } from '@material-ui/core';
import { DownloadHook } from '../downloads';
import { sesionStore } from '../../../../utils/sesionStore';
import HashLoader from 'react-spinners/HashLoader';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { makeStyles } from '@material-ui/core/styles';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
}));

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
  },
}));

export const AcademicDocumentMenu = ({
  open,
  anchorEl,
  handleClose,
  documentKey,
  notEnglish,
}) => {
  const global = React.useContext(sesionStore);
  const { state } = global;
  const { sesion } = state;
  const { hash } = store.getItems('educsesion');

  const [loading, setLoading] = React.useState(false);
  const [moneda, setMoneda] = React.useState(false);

  const classes = useStyles();

  const { successMessage, errorMessage } = handleAlerts();

  const {
    getHistorialPDF,
    getCredencialPDF,
    getConstanciaPDF,
    getFinalizacionPDF,
  } = DownloadHook({
    handleClose,
    setMoneda,
    errorMessage,
    successMessage,
    setLoading,
    hash,
    sesion,
  });

  const downloadFunctions = (language) => ({
    historial: getHistorialPDF(language),
    credencial: getCredencialPDF(language),
    constancia: getConstanciaPDF(language),
    finalizacion: getFinalizacionPDF(language),
  });

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        {moneda ? (
          <HashLoader loading={true} size={100} color='white' />
        ) : (
          <PacmanLoader loading={true} color='white' />
        )}
      </Backdrop>
      <StyledMenu
        id='personal-documents-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)' }}>
        <MenuItem onClick={downloadFunctions('es')[documentKey]}>
          Descargar en español
        </MenuItem>
        {!notEnglish && (
          <MenuItem onClick={downloadFunctions('en')[documentKey]}>
            Descargar en inglés
          </MenuItem>
        )}
      </StyledMenu>
    </>
  );
};
