import React, { useReducer, createContext } from 'react';
import * as store from '../../../utils/localStore';

const State = createContext(null);
const { Provider } = State;

const Reducer = (state, { property, changes = {} }) => {
  switch (property) {
    case 'main_step':
      return {
        ...state,
        step: 0,
        main_step: changes,
      };
    case 'step':
      return {
        ...state,
        step: changes,
      };
    case 'datos_estudiante':
      return {
        ...state,
        datos_estudiante: {
          ...state.datos_estudiante,
          ...changes,
        },
      };
    case 'datos_tutor':
      return {
        ...state,
        datos_tutor: {
          ...state.datos_tutor,
          ...changes,
        },
      };
    case 'condiciones':
      return {
        ...state,
        condiciones: {
          ...state.condiciones,
          ...changes,
        },
      };
    case 'datos_pago':
      return {
        ...state,
        datos_pago: {
          ...state.datos_pago,
          ...changes,
        },
      };
    case 'validacion_correo':
      return {
        ...state,
        validacion_correo: {
          ...state.validacion_correo,
          ...changes,
        },
      };
    case 'respuesta_registro':
      return {
        ...state,
        respuesta_registro: {
          ...state.respuesta_registro,
          ...changes,
        },
      };
    case 'respuesta_validacion':
      return {
        ...state,
        respuesta_validacion: {
          ...state.respuesta_validacion,
          ...changes,
        },
      };
    case 'respuesta_pago':
      return {
        ...state,
        respuesta_pago: {
          ...state.respuesta_pago,
          ...changes,
        },
      };
    case 'update_store':
      let total = 0;
      for (var i in changes)
        if (!changes[i].optativa) total += process.env.PRECIO_MXN_MATERIAS;
      return { ...state, materiasElegidas: changes, total: total };
    case 'clear_store':
      return { ...state, materiasElegidas: [], total: 0 };
    case 'update_moneda':
      return { ...state, moneda: changes };
    case 'update_total':
      return { ...state, total: changes };
    case 'update_modalidad':
      return { ...state, modalidad: changes };
    case 'verify':
      return { ...state, verify: changes };
    case 'restart':
      return { ...state, restart: changes };
    case 'update_remodalidad':
      let t = 0;
      for (var i in changes)
        if (!changes[i].optativa)
          t += state.modalidad
            ? process.env.PRECIO_MXN_MATERIAS / 4
            : process.env.PRECIO_MXN_MATERIAS;
      return {
        ...state,
        modalidad: !state.modalidad,
        total: t,
      };
    case 'fetch_sesion':
      const { estudiante, jwt } = changes;
      const { matricula } = estudiante;
      store.setItems('educsesion', { user: matricula, hash: jwt });
      return { ...state, sesion: estudiante, token: jwt };
    case 'alert':
      return { ...state, snackbar: changes };
    case 'materias':
      return { ...state, materias: changes };
    case 'concepto':
      return { ...state, concepto: changes };
    case 'pago_total_mxn':
      return { ...state, pago_total_mxn: changes };
    case 'pago_total_usd':
      return { ...state, pago_total_usd: changes };
    default:
      console.log(`We can't recognize the property: ${property}`);
      break;
  }
};

const InitialState = {
  main_step: 0, // Sirve para?
  step: 0, // Sirve para
  sesion: {},
  token: '',
  verify: false,
  restart: false,
  datos_estudiante: {
    nombre: '',
    apellido_paterno: '',
    apellido_materno: '',
    correo: '',
    correo_comprueba: '',
    telefono: '',
    password: '',
    password_comprueba: '',
  },
  datos_tutor: {
    nombre: '',
    correo: '',
    correo2: '',
    telefono: '',
  },
  condiciones: {
    certificado_secundaria: false,
    mayor_de_15: false,
    aviso_privacidad: false,
  },
  validacion_correo: {
    codigo: '',
    completo: false,
  },
  datos_pago: {
    metodo: 'Tarjeta',
    nombre_completo: '',
    correo: '',
    telefono: '',
    tarjeta: '',
    cvv: '',
    exp_mm: '',
    exp_yy: '',
  },
  respuesta_registro: {},
  respuesta_validacion: {},
  respuesta_pago: {},
  materiasElegidas: [],
  modalidad: false,
  total: {},
  pago_total_mxn: process.env.PRECIO_MXN,
  pago_total_usd: process.env.PRECIO_USD,
  moneda: false,
  concepto: 'Inscripción',
  snackbar: {
    open: false,
    message: '',
    type: 'success',
  },
  materias: [],
};

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { State, StateProvider };
