import React from 'react';
import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import { BootstrapSelect } from './components/BootstrapSelect';

export const SelectPaymentMethod = ({ setSelected, selected }) => {
  return (
    <Box>
      <Box display='flex' flexDirection='column' gridRowGap='4px'>
        <Typography variant='body2'>Método de pago</Typography>
        <Select
          onChange={(event) => setSelected(event.target.value)}
          value={selected}
          input={<BootstrapSelect />}>
          <MenuItem value='oxxo'>Oxxo</MenuItem>
          <MenuItem value='spei'>Spei</MenuItem>
          <MenuItem value='card'>Tarjeta</MenuItem>
        </Select>
      </Box>
    </Box>
  );
};
