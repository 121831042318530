import { PAGO_OXXO, PAGO_SPEI } from '../../../API';
import * as localStore from '../../../utils/localStore';

export const useReferencePayment = ({
  paymentData,
  setResponseData,
  paymentType,
}) => {
  const oxxoPay = async (matricula, moneda, importe, jwt, periodo, concept) => {
    try {
      const response = await PAGO_OXXO(
        matricula,
        jwt,
        importe,
        concept,
        moneda,
        periodo
      );
      const { barcode_url, expires_at, reference } =
        response.data.order.charges.data[0].payment_method;
      const id = response.data.order.id;
      return {
        paymentMethod: 'oxxo',
        result: { id, barcode_url, expires_at, reference },
        error: null,
        status: 'success',
      };
    } catch (error) {
      console.log('Error', error);
      return {
        paymentMethod: 'oxxo',
        status: 'error',
        result: null,
        error:
          'Hubo un error al generar la referencia, por favor intenta de nuevo más tarde',
      };
    }
  };

  const speiPay = async (matricula, moneda, importe, jwt, periodo, concept) => {
    try {
      const { data } = await PAGO_SPEI(
        matricula,
        jwt,
        importe,
        concept,
        moneda,
        periodo
      );
      const { bank, expires_at, clabe, reference } =
        data.order.charges.data[0].payment_method;
      const id = data.order.id;
      return {
        paymentMethod: 'spei',
        error: null,
        status: 'success',
        result: { bank, expires_at, clabe, id, reference },
      };
    } catch (error) {
      console.log('Error', error.response.data?.message || error);
      return {
        paymentMethod: 'spei',
        status: 'error',
        result: null,
        error:
          'Hubo un error al generar la referencia, por favor intenta de nuevo más tarde',
      };
    }
  };

  const offlinePayment = async () => {
    const { hash } = localStore.getItems('educsesion');
    switch (paymentType) {
      case 'oxxo':
        const dataOxxo = await oxxoPay(
          paymentData.studentId,
          paymentData.currency,
          paymentData.amount,
          hash,
          paymentData.term,
          paymentData.concept
        );
        setResponseData(dataOxxo);
        break;
      case 'spei':
        const dataSpei = await speiPay(
          paymentData.studentId,
          paymentData.currency,
          paymentData.amount,
          hash,
          paymentData.term,
          paymentData.concept
        );
        setResponseData(dataSpei);
        break;
      default:
        break;
    }
  };

  return { offlinePayment };
};
