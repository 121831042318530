import React, { Fragment, useEffect, Suspense, lazy } from 'react';
import { LinearProgress as Loading } from '@material-ui/core';
import Portada from '../subvistas/Common/Portada';

const FAQ = lazy(() => import('../subvistas/Common/FAQ'));
const Body = lazy(() => import('../componentes/Curricula/Body'));

import cover from '../imagenes/curricula.svg';

const Curricula = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Portada
        titulo='Currícula'
        descripcion={
          <Fragment>
            Nuestro plan de estudios es único en el sector. La{' '}
            <b>actualización</b> de nuestras <b>materias</b> es un proceso
            constante de nuestros especialistas, así aseguramos proveer siempre
            los <b>conocimientos más relevantes</b>.
          </Fragment>
        }
        img={cover}
        img_alt='En prepaenlinea.mx las materias actualizadas son garantía.'
        bg_color='#080f8c'
      />
      <Suspense fallback={<Loading />}>
        <Body />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <FAQ />
      </Suspense>
    </Fragment>
  );
};

export default Curricula;
