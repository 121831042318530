import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { animateScroll as scroll } from 'react-scroll';

const ArrowDown = () => {
  return (
    <IconButton
      onClick={() => {
        scroll.scrollMore(820);
      }}
      style={{
        position: 'fixed',
        bottom: 10,
        textAlign: 'center',
        zIndex: 10,
        left: 'calc(50% - 44px)',
      }}>
      <ExpandMore style={{ fontSize: '64px' }} />
    </IconButton>
  );
};

export default ArrowDown;
