import React, { forwardRef } from 'react';

//Components
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

//Styles
import { makeStyles } from '@material-ui/core/styles';

const Input = forwardRef(({ endAdornment, ...props }, ref) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiInputBase-input': {
        color: props.color ? 'white' : theme.palette.grey.dark,
        fontWeight: 'bold',
      },
      '& label': {
        color: props.color ? 'white' : theme.palette.grey.dark,
        fontSize: '.9rem',
      },
      '& label.Mui-focused': {
        color: props.color ? 'white' : theme.palette.secondary.dark,
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 8,
        borderWidth: 2,
        '& fieldset': {
          borderColor: props.color ? 'white' : theme.palette.grey.dark,
          borderWidth: 2,
        },
        '&:hover fieldset': {
          borderColor: props.color ? 'white' : theme.palette.grey.dark,
        },
        '&.Mui-focused fieldset': {
          borderColor: props.color ? 'gray' : theme.palette.secondary.dark,
        },
        '& .MuiSelect-icon': {
          color: props.color
            ? 'white !important'
            : theme.palette.grey.dark + ' !important',
        },
      },
    },
  }));

  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      {...props}
      variant='outlined'
      inputProps={{ ref: ref, style: props.style, autoFocus: props.autoFocus }}
      InputProps={{ endAdornment: endAdornment }}
      FormHelperTextProps={{
        style: {
          margin: 8,
          fontWeight: 'bold',
          borderRadius: '8px',
          color: '#C46173',
        },
      }}>
      {props.options
        ? props.options.map((option, i) => (
            <MenuItem
              key={
                option.value || option.id || option.clave || option.label || i
              }
              value={option.value || option.id || option.clave}>
              {option.label || option.nombre || option.value || option.titulo}
            </MenuItem>
          ))
        : null}
    </TextField>
  );
});

export default Input;
