import * as R from 'ramda';
import React, { Fragment, useContext, useState, useEffect } from 'react';
import { sesionStore } from '../../utils/sesionStore';
import { NavLink as Link, useHistory } from 'react-router-dom';
import * as store from '../../utils/localStore';
import { Typography, Grid } from '@material-ui/core';
import Button from '../../componentes/utils/Button';
import Input from '../../componentes/utils/Input';

//Styles
import { makeStyles } from '@material-ui/core/styles';

//Imagenes
import InscripcionImg from '../../imagenes/curricula.svg';

import { PERIODO_INSCRIPCION } from '../../API';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(245,245,245)',
    minHeight: '60vh',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '30%',
    },
  },
  callto: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const evaluate_status = (statusId, periodoInscripcion) => {
  // Si el usuario tiene el estado CuentaCreada, ConDatosPersonales,
  // ConDirección, ConDatosDeTutor o ConDocumentos e intenta hacer
  // login en tiempos de inscripción, hay que mostrar el mensaje Debes
  // completar tu proceso de inscripción y redirigirlo a la ventana de
  // inscripción.
  if (R.isNil(statusId) || R.includes(statusId, [1, 2, 7, 8, 9])) {
    if (!!periodoInscripcion) {
      return {
        message: 'Debes completar tu proceso de inscripción.',
        url: '/inscripciones',
        puedeLogin: false,
      };
      // Si el tiempo de inscripción ya pasó, hay que evitar que haga
      // login y mostrarle en pantalla el mensaje El periodo de
      // inscripción ha terminado.
    } else {
      return {
        message: 'El periodo de inscripción ha terminado.',
        puedeLogin: false,
      };
    }
  }
  // Asumimos que puede iniciar sesión
  return {
    puedeLogin: true,
  };
};

const Login = () => {
  const global = useContext(sesionStore);
  const { state, dispatch } = global;
  const { sesion } = state;

  const classes = useStyles();
  const [usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');
  const [loadingModal, setLoadingModal] = useState(false);
  const [inscripcionActiva, setInscripcionActiva] = React.useState(false);
  const [periodoInscripcion, setPeriodoInscripcion] = React.useState(null);

  React.useEffect(() => {
    const fetch = async () => {
      if (periodoInscripcion === null) {
        try {
          const response = await PERIODO_INSCRIPCION();
          setPeriodoInscripcion(response.data.periodo);
        } catch (error) {
          console.log('Error', error.response.data?.message || error);
        }
      }
    };
    fetch();
  }, []);

  const history = useHistory();

  const fetchData = async (user, password) => {
    const body = {
      usuario: user?.trim(),
      password: password?.trim(),
    };

    try {
      const { data } = await axios.post(
        process.env.PREPA_SERVER + 'estudiantes/signin',
        body
      );

      const validation = evaluate_status(
        data.estudiante?.EstatusId,
        periodoInscripcion
      );

      if (!validation.puedeLogin) {
        dispatch({
          type: 'OPEN_DIALOG',
          payload: {
            dialog_open: true,
            dialog_message: validation.message,
            process: async () => {
              if (validation.url) {
                history.push(validation.url);
              }
              // En cada llamada a 'OPEN_DIALOG', al final de cada
              // process, hay que hacer un dispatch hacia
              // 'CLOSE_DIALOG', para que se pueda cerrar el dialogo.
              dispatch({ type: 'CLOSE_DIALOG' });
            },
            title: 'Un momento...',
            buttonText: validation.url ? 'Completar inscripción' : 'Aceptar',
          },
        });
        return;
      }

      if (data.estudiante.EstatusId == 1) {
        const headers = {
          headers: {
            Authorization: `Bearer ${data.jwt}`,
          },
        };
        dispatch({
          type: 'OPEN_DIALOG',
          payload: {
            dialog_open: true,
            dialog_message: !loadingModal
              ? 'Debe confirmar su correo electrónico antes de continuar. ¿Desea que le envíemos el correo nuevamente?'
              : 'Enviando correo...',
            process: async () => {
              setLoadingModal(true);
              try {
                const response = await axios.get(
                  `${process.env.PREPA_SERVER}estudiantes/${data.estudiante.matricula}/sendValidation`,
                  headers
                );

                dispatch({
                  type: 'ALERT',
                  payload: {
                    open: true,
                    message: 'El correo ha sido enviado.',
                    type: 'success',
                  },
                });

                history.push('/inscripciones');
              } catch (error) {
                dispatch({
                  type: 'ALERT',
                  payload: {
                    open: true,
                    message:
                      'Ha ocurrido un error, por favor intente mas tarde.',
                    type: 'warning',
                  },
                });
              }
              setLoadingModal(false);
            },
            title: 'Un momento...',
            buttonText: 'Enviar correo de verificación',
          },
        });
      } else {
        dispatch({ type: 'FETCH_SESION', payload: data });
      }
    } catch (error) {
      console.log(error, error.response);
      dispatch({ type: 'CLEAR_SESION' });
      dispatch({
        type: 'ALERT',
        payload: {
          open: true,
          message: error.response.data.message,
          type: 'warning',
        },
      });
    }
  };

  const fetchStudendData = async (user, jwt) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
    try {
      const { data } = await axios.get(
        `${process.env.PREPA_SERVER}estudiantes/${user}`,
        headers
      );
      dispatch({ type: 'UPDATE_USER', payload: data.estudiante });
    } catch (error) {
      console.log(error);
      dispatch({ type: 'CLEAR_SESION' });
      dispatch({
        type: 'ALERT',
        payload: {
          open: true,
          message: 'Su sesión se ha cerrado, por favor inicie nuevamente.',
          type: 'warning',
        },
      });
    }
  };

  const login = async (e) => {
    e.preventDefault();

    if (usuario == '') {
      dispatch({
        type: 'ALERT',
        payload: {
          open: true,
          message: 'Ingrese el usuario por favor',
          type: 'error',
        },
      });
      return false;
    } else if (password == '') {
      dispatch({
        type: 'ALERT',
        payload: {
          open: true,
          message: 'Ingrese su password por favor',
          type: 'error',
        },
      });
      return false;
    }

    fetchData(usuario, password);
  };

  useEffect(() => {
    if (sesion == null && store.getItems('educsesion')) {
      const { user, hash } = store.getItems('educsesion');
      if (hash) fetchStudendData(user, hash);
    }
    window.scrollTo(0, 0);
  }, [true]);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const response = await PERIODO_INSCRIPCION();
        if (response.data?.periodo) setInscripcionActiva(true);
      } catch (error) {
        console.log('Error', error.response?.data?.message || error);
        setInscripcionActiva(false);
      }
    };
    fetch();
  }, []);

  return (
    <Fragment>
      <Grid
        container
        className={classes.root}
        alignItems='center'
        justifyContent='center'>
        <Grid
          item
          xs={false}
          sm={false}
          md={7}
          className={classes.callto}
          style={{
            minHeight: '80vh',
            textAlign: 'center',
            overflow: 'hidden',
            width: '100%',
            marginTop: '10%',
          }}>
          <img src={InscripcionImg} style={{ maxWidth: '400px' }} />
          <div
            style={{
              position: 'relative',
              top: '-60%',
              padding: '20px 0px',
              backgroundColor: 'rgba(255,255,255,.2)',
            }}>
            <Typography variant='h6'>¿Aún no te has inscrito?</Typography>
            <Typography variant='h3'>Inscríbete ahora</Typography>
            <br />
            {inscripcionActiva && (
              <Link to='/inscripciones'>
                <Button>Inscribirme</Button>
              </Link>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <form style={{ padding: '5% 10% 0 10%' }} onSubmit={login}>
            <Typography variant='h3'>
              <b>Inicia Sesión</b>
            </Typography>
            <br />
            <Input
              id='user'
              label='Usuario'
              name='user'
              onChange={(event) => setUsuario(event.target.value)}
              fullWidth
            />
            <br />
            <br />
            <Input
              id='password'
              type='password'
              label='Contraseña'
              name='password'
              onChange={(event) => setPassword(event.target.value)}
              fullWidth
            />
            <br />
            <Typography variant='caption' align='right'>
              ¿Olvidaste tu contraseña? Por favor, comunicate con nuestro equipo
              de soporte.
            </Typography>
            <br />
            <br />
            <Button type='submit' onClick={login}>
              Iniciar Sesión
            </Button>
            <br />
            <br />
            <br />
            <br />
            {inscripcionActiva && (
              <Typography variant='body1' align='center'>
                ¿Aún no tienes una cuenta? Inscríbete{' '}
                <Link className='link' to='/inscripciones'>
                  aquí.
                </Link>
              </Typography>
            )}
            <br />
            <br />
            <Typography variant='body1' align='center' color='secondary'>
              <i>
                “El único instrumento que los hombres tenemos tanto para
                perfeccionarnos como para vivir dignamente es la educación”
              </i>
              <br />- Tomás de Aquino
            </Typography>
            <br />
            <Typography variant='h6' align='center'>
              ¿Deseas saber más sobre la educación en casa? Visite nuestra
              sección de{' '}
              <Link className='link' to='/informacion'>
                Información.
              </Link>
            </Typography>
          </form>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Login;
