import * as R from 'ramda';

export const verifyUserInfo = (sesion) => {
  const isNilOrEmpty = (value) => R.or(R.isNil(value))(R.isEmpty(value));
  const paths = [
    { path: ['telefonoTutor'], message: 'Teléfono del tutor' },
    { path: ['sexoTutor'], message: 'Sexo del tutor' },
    { path: ['nombreTutor'], message: 'Nombre del tutor' },
    { path: ['correoTutor'], message: 'Correo del tutor' },
    {
      path: ['fechaNacimientoTutor'],
      message: 'Fecha de nacimiento del tutor',
    },
    { path: ['Persona', 'nombre'], message: 'Nombre' },
    { path: ['Persona', 'apellidoPaterno'], message: 'Apellido Paterno' },

    { path: ['Persona', 'fechaNacimiento'], message: 'Fecha de nacimiento' },
    { path: ['Persona', 'sexo'], message: 'Sexo del estudiante' },
    {
      path: ['Persona', 'Contacto', 'telefono'],
      message: 'Teléfono de contacto del estudiante',
    },
    { path: ['Persona', 'Usuario', 'Direccion', 'calle'], message: 'Calle' },
    {
      path: ['Persona', 'Usuario', 'Direccion', 'numExt'],
      message: 'Número exterior',
    },
    {
      path: ['Persona', 'Usuario', 'Direccion', 'numInt'],
      message: 'Número interior',
    },
    {
      path: ['Persona', 'Usuario', 'Direccion', 'ciudad'],
      message: 'Ciudad',
    },
    {
      path: ['Persona', 'Usuario', 'Direccion', 'colonia'],
      message: 'Colonia',
    },
    {
      path: ['Persona', 'Usuario', 'Direccion', 'codigoPostal'],
      message: 'Código postal',
    },
    {
      path: ['Persona', 'Usuario', 'Direccion', 'municipio'],
      message: 'Municipio',
    },
    {
      path: ['Persona', 'Usuario', 'Direccion', 'estado'],
      message: 'Estado',
    },
    { path: ['Persona', 'Usuario', 'Direccion', 'PaisId'], message: 'País' },
    {
      path: ['Persona', 'Usuario', 'Direccion', 'PaisId'],
      message: 'Nacionalidad',
    },
  ];

  const messages = R.map((cond) => cond.message)(
    R.filter((cond) => isNilOrEmpty(R.path(cond.path)(sesion)))(paths)
  );
  return messages;
};
