import * as R from 'ramda';
import * as store from '../../../../utils/localStore';
import DialogUpload from './DialogUpload';
import React, { useContext } from 'react';
import axios from 'axios';
import download from 'downloadjs';
import { backend, handleAlerts, fileToBase64 } from '../../../../utils';
import { Menu, MenuItem, styled } from '@material-ui/core';
import { sesionStore } from '../../../../utils/sesionStore';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
}));

export const DocumentMenu = ({
  estudiante,
  open,
  anchorEl,
  handleClose,
  documentKey,
  disableUploadDocuments,
}) => {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [handleUploadKey, setHandleUploadKey] = React.useState(null);
  const { successMessage, errorMessage } = handleAlerts();

  const global = useContext(sesionStore);
  const { dispatch } = global;

  const hiddenFileInput = React.useRef(null);

  const validateAndSet = R.cond([
    [
      (file) => !R.equals('application/pdf')(R.prop('type', file)),
      () => errorMessage('El tipo del archivo debe ser PDF'),
    ],
    [
      (file) => R.propSatisfies((s) => s > 10000000, 'size', file),
      () => errorMessage('El tamaño máximo para el archivo debe ser de 5 MB'),
    ],
    [
      R.T,
      async (file) => {
        const raw = await fileToBase64(file);
        R.ifElse(
          R.propSatisfies((x) => !R.isNil(x), 'error'),
          () => {
            errorMessage(
              'Ha ocurrido un error al procesar la imagen. Intente de nuevo o con otro archivo'
            );
          },
          () => {
            setSelectedFile(file);
            setOpenDialog(true);
          }
        )(raw);
      },
    ],
  ]);

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    validateAndSet(file);
  };

  const handleSelectFile = (message, key) => () => {
    setHandleUploadKey(key);
    setMessage(message);
    hiddenFileInput.current.click();
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setOpenDialog(false);
    handleClose();
  };

  const onFileUpload = async (url, formData) => {
    try {
      const { hash } = store.getItems('educsesion');
      const config = {
        headers: {
          Authorization: `Bearer ${hash}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post(url, formData, config);
      handleCloseDialog();
      handleClose();
      successMessage(data.message);
      return data;
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      handleCloseDialog();
      errorMessage(message);
      console.log('Error', message);
    }
  };

  const handleFileUploadActa = async () => {
    const url = `${backend}estudiantes/${estudiante.matricula}/uploadDocumento?tipo=acta_de_nacimiento`;
    const formData = new FormData();
    formData.append('documento', selectedFile);
    const data = await onFileUpload(url, formData);
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        ...estudiante,
        Persona: { ...estudiante.Persona, documentoOficial: data.url },
      },
    });
  };

  const handleFileUploadCURP = async () => {
    const url = `${backend}estudiantes/${estudiante.matricula}/uploadDocumento?tipo=curp`;
    const formData = new FormData();
    formData.append('documento', selectedFile);
    const data = await onFileUpload(url, formData);
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        ...estudiante,
        Persona: { ...estudiante.Persona, documentoCurp: data.url },
      },
    });
  };

  const handleFileUploadCertificado = async () => {
    const url = `${backend}estudiantes/${estudiante.matricula}/uploadDocumento?tipo=certificado_secundaria`;
    const formData = new FormData();
    formData.append('documento', selectedFile);
    const data = await onFileUpload(url, formData);
    dispatch({
      type: 'UPDATE_USER',
      payload: { ...estudiante, documentoCertificadoSecundaria: data.url },
    });
  };

  const onDownload = async (url, documentName, message) => {
    try {
      const { hash } = store.getItems('educsesion');
      const config = {
        headers: {
          Authorization: `Bearer ${hash}`,
        },
        responseType: 'blob',
      };
      const response = await axios.get(url, config);
      download(response.data, documentName, 'application/pdf');
      successMessage(message);
    } catch (error) {
      errorMessage(error.message);
      console.log('Error', error.response.data?.message || error);
    }
  };

  const handleDownloadActa = async () => {
    const url = `${backend}estudiantes/${estudiante.matricula}/download?tipo=acta_de_nacimiento`;
    const documentName = `acta_${estudiante.matricula}.pdf`;
    const message = 'Se ha descargado correctamente el Acta de Nacimiento';
    await onDownload(url, documentName, message);
  };

  const handleDownloadCertificado = async () => {
    const url = `${backend}estudiantes/${estudiante.matricula}/download?tipo=certificado_secundaria`;
    const documentName = `certificado_${estudiante.matricula}.pdf`;
    const message =
      'Se ha descargado correctamente el Certificado de Secundaria';
    await onDownload(url, documentName, message);
  };

  const handleDownloadCurp = async () => {
    const url = `${backend}estudiantes/${estudiante.matricula}/download?tipo=curp`;
    const documentName = `curp_${estudiante.matricula}.pdf`;
    const message = 'Se ha descargado correctamente la CURP';
    await onDownload(url, documentName, message);
  };

  const handleFns = {
    acta: handleFileUploadActa,
    curp: handleFileUploadCURP,
    certificado: handleFileUploadCertificado,
  };

  const downloadFunctions = {
    acta: handleDownloadActa,
    curp: handleDownloadCurp,
    certificado: handleDownloadCertificado,
  };

  const uploadMessages = {
    acta: '¿Deseas subir el acta de nacimiento?',
    curp: '¿Deseas subir el CURP?',
    certificado: '¿Deseas subir el certificado de secundaria?',
  };

  return (
    <>
      <DialogUpload
        open={openDialog}
        handleClose={handleCloseDialog}
        handleUploadKey={handleUploadKey}
        message={message}
        document={selectedFile}
        fns={handleFns}
      />
      <input
        type='file'
        ref={hiddenFileInput}
        onChange={onFileChange}
        style={{ display: 'none' }}
      />
      <StyledMenu
        id='personal-documents-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)' }}>
        <MenuItem onClick={downloadFunctions[documentKey]}>Descargar</MenuItem>
        <MenuItem
          disabled={disableUploadDocuments}
          onClick={handleSelectFile(uploadMessages[documentKey], documentKey)}>
          Subir documento
        </MenuItem>
      </StyledMenu>
    </>
  );
};
