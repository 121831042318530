import React, { useEffect } from 'react';
import * as R from 'ramda';
import { Box, Table, TableBody, Typography } from '@material-ui/core';
import { SectionHeader } from './components/SectionHeader';
import { groupByCycle, processed } from './utils';
import { SubjectRow } from './components/SubjectRow';
import { TableHeaders } from './components/TableHeaders';

const Grades = ({ student }) => {
  const [groupsOfSubjects, setGroupsOfSubjects] = React.useState([]);

  const headers = ['Materia', 'Calificación', 'Letra'];
  const terms = student?.Periodos;

  useEffect(() => {
    if (terms?.length > 0) {
      if (!R.isNil(terms)) {
        const results = R.flatten(
          R.map((infoPeriodo) =>
            processed(infoPeriodo.Periodo?.periodo)(
              infoPeriodo.Materias,
              infoPeriodo
            )
          )(terms)
        );
        setGroupsOfSubjects(groupByCycle(results));
      }
    }
  }, [terms]);

  return (
    <>
      {R.isEmpty(groupByCycle) ? (
        <Typography variant='subtitle2'>No hay materias cursadas</Typography>
      ) : (
        <Box
          display='flex'
          flexDirection='column'
          gridRowGap='24px'
          padding='16px 24px'>
          {groupsOfSubjects.map((group) => (
            <Box key={group.title} borderBottom='1px solid #aca7b475'>
              <SectionHeader
                hasScholarship={group.becado}
                title={group.title}
                registrationId={student.matricula}
                group={group}
              />
              <Table>
                <TableHeaders headers={headers} />
                <TableBody>
                  {group.subjects.map((subject, index) => (
                    <SubjectRow key={index} subject={subject} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default Grades;
