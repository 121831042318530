import download from 'downloadjs';
import { GET_DOCUMENT } from '../../../API';

export const DownloadHook = ({
  handleClose,
  successMessage,
  errorMessage,
  setLoading,
  setMoneda,
  hash,
  sesion,
}) => {
  const downloadDocument = async (url, documentName, message) => {
    try {
      setMoneda(Math.random() < 0.5);
      setLoading(true);
      handleClose();
      const response = await GET_DOCUMENT(url, hash);
      download(response.data, documentName, 'application/pdf');
      successMessage(message);
    } catch (error) {
      const message = JSON.parse(await error.response.data?.text())?.message;
      console.log('Error', message || error.response?.statusText || error);
      errorMessage(message || error.response?.statusText || error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const getHistorialPDF = (historialLang) => async () => {
    const url = `${process.env.PREPA_SERVER}estudiantes/${sesion.matricula}/documentos?documento=historial&idioma=${historialLang}&firma=true`;
    const document = `historial_${sesion.matricula}_${historialLang}.pdf`;
    const message = 'Se ha descargado el historial correctamente';
    await downloadDocument(url, document, message);
  };

  const getBoletaPDF = (boletaLang, periodo) => async () => {
    const url = `${process.env.PREPA_SERVER}estudiantes/${sesion.matricula}/documentos?documento=boleta&idioma=${boletaLang}&periodo=${periodo}&firma=true`;
    const document = `boleta_${sesion.matricula}_${boletaLang}.pdf`;
    const message = 'Se ha descargado la boleta correctamente';
    await downloadDocument(url, document, message);
  };

  const getCredencialPDF = (credencialLang) => async () => {
    const url = `${process.env.PREPA_SERVER}estudiantes/${sesion.matricula}/documentos?documento=credencial&idioma=${credencialLang}`;
    const document = `credencial_${sesion.matricula}_${credencialLang}.pdf`;
    const message = 'Se ha descargada la credencial correctamente';
    await downloadDocument(url, document, message);
  };

  const getConstanciaPDF = (constanciaLang) => async () => {
    const url = `${process.env.PREPA_SERVER}estudiantes/${sesion.matricula}/documentos?documento=constancia&idioma=${constanciaLang}`;
    const document = `constancia_${sesion.matricula}_${constanciaLang}.pdf`;
    const message = 'Se ha descargado la constancia correctamente';
    await downloadDocument(url, document, message);
  };

  const getFinalizacionPDF = (language) => async () => {
    const url = `${process.env.PREPA_SERVER}estudiantes/${sesion.matricula}/documentos?documento=finalizacion&idioma=${language}`;
    const document = `constancia_finalizacion_${sesion.matricula}_${language}.pdf`;
    const message =
      'Se ha descargado la constancia de finalización correctamente';
    await downloadDocument(url, document, message);
  };

  return {
    getHistorialPDF,
    getBoletaPDF,
    getCredencialPDF,
    getConstanciaPDF,
    getFinalizacionPDF,
  };
};
