import * as R from 'ramda';
import {
  Grid,
  Card,
  CardHeader,
  Button,
  DialogActions,
  Typography,
  IconButton,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from '@material-ui/core';
import React from 'react';
import { handleAlerts, isEmptyOrNil, format } from '../../../../utils';
import * as localStore from '../../../../utils/localStore';
import { MATERIAS_FALTANTES, INSCRIPCION_MATERIAS } from '../../../../API';
import { sesionStore } from '../../../../utils/sesionStore';
import ClearIcon from '@material-ui/icons/Clear';
import { ReinscripcionContext } from './ReinscripcionContext';
import { CustomButton } from '../../../../componentes/CustomButton';

const SeleccionMaterias = () => {
  const [state, setState] = React.useContext(ReinscripcionContext);
  const [hasMaterias, setHasMaterias] = React.useState(false);
  const [materias, setMaterias] = React.useState(null);
  const [materiasElegidas, setMateriasElegidas] = React.useState([]);
  const [moneda, setMoneda] = React.useState('mxn');
  const { state: sesionState } = React.useContext(sesionStore);
  const { user, hash } = localStore.getItems('educsesion');
  const { errorMessage } = handleAlerts();

  const moveToSelected = (id) => {
    const materia = R.find((m) => m.id === id)(materias);
    setMaterias(R.filter((m) => m.id !== id)(materias));
    setMateriasElegidas(R.append(materia)(materiasElegidas));
  };

  const removeFromSelected = (id) => {
    const materia = R.find((m) => m.id === id)(materiasElegidas);
    setMateriasElegidas(R.filter((m) => m.id !== id)(materiasElegidas));
    setMaterias(R.append(materia)(materias));
  };

  React.useEffect(() => {
    const fetchMaterias = async () => {
      if (materias === null) {
        try {
          const response = await MATERIAS_FALTANTES(user, hash);
          setMaterias(response?.data?.obligatorias);
          setHasMaterias(response?.data?.obligatorias.length === 0);
        } catch (error) {
          console.log('Error', error.response.data?.message || error);
        }
      }
    };
    fetchMaterias();
  }, [materias, user, hash, hasMaterias]);

  return hasMaterias ? (
    <Box
      display='flex'
      flexDirection='column'
      gridRowGap='48px'
      maxWidth='600px'
      margin='32px auto'>
      <Box
        display='flex'
        flexDirection='column'
        gridRowGap='24px'
        alignItems='start'
        maxWidth='500px'
        style={{
          padding: '24px 32px',
          backgroundColor: '#d4a6de3d',
          borderRadius: '8px',
        }}>
        <Typography variant='body1' style={{ fontWeight: 700 }}>
          Concluiste las materias obligatorias. Comunícate en el canal
          #inscripciones-a-prepaenlinea para poder cursar materias optativas.
        </Typography>
        <CustomButton
          variant='secondary'
          target='_blank'
          component='a'
          href='https://discord.gg/NJMAaQt3qb'>
          Conectarse a Discord
        </CustomButton>
      </Box>
      <CustomButton
        variant='primary'
        style={{ alignSelf: 'end' }}
        onClick={() => {
          window.location.reload(true);
        }}>
        Regresar al perfil
      </CustomButton>
    </Box>
  ) : (
    <Grid
      item
      container
      spacing={3}
      alignItems='stretch'
      direction='row'
      justifyContent='center'
      style={{ height: '100%', width: '100%', padding: 8 }}>
      <Grid item xs={12}>
        <Typography variant='h5' style={{ fontWeight: 700, color: '#21594F' }}>
          Elige tus materias
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: '#a2faaa, padding: 10, borderRadius: 8',
        }}>
        <Typography variant='subtitle1'>
          Puedes elegir hasta cuatro materias obligatorias. Las materias optativas las podrás elegir cuando empiece el cuatrimestre.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            <strong>Materias Elegibles</strong>
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          direction='row'
          style={{ marginBottom: '32px' }}>
          <FormControl
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              columnGap: '16px',
            }}>
            <FormLabel id='currency'>
              <Typography variant='body1'>Ver precios en:</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby='currency'
              defaultValue='mxn'
              name='radio-buttons-group'
              onChange={(event) => {
                setMoneda(event.target.value);
              }}
              value={moneda}>
              <FormControlLabel
                value='mxn'
                control={<Radio />}
                label='Pesos mexicanos'
              />
              <FormControlLabel
                value='usd'
                control={<Radio />}
                label='Dólares'
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              overflow: 'hidden',
              overflowY: 'scroll',
              height: 320,
              paddingTop: 16,
              paddingBottom: 16,
            }}>
            <Grid
              item
              container
              spacing={3}
              alignItems='stretch'
              direction='row'
              justifyContent='flex-start'>
              {isEmptyOrNil(materias) ? (
                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                    align='center'
                    style={{ marginTop: 32 }}>
                    No hay más materias disponibles
                  </Typography>
                </Grid>
              ) : (
                materias?.map((materia, index) => (
                  <Grid item key={index}>
                    <Card
                      style={{
                        margin: 8,
                        borderRadius: 16,
                        width: 300,
                      }}>
                      <CardHeader
                        title={
                          <>
                            <Typography
                              variant='h6'
                              gutterBottom
                              align='center'>
                              <strong>{materia.titulo}</strong>
                            </Typography>
                            <Typography variant='subtitle1' align='center'>
                              <strong>
                                {materia.optativa
                                  ? 'Materia Optativa'
                                  : 'Materia Curricular'}
                              </strong>
                            </Typography>
                          </>
                        }
                        subheader={
                          <>
                            <Typography variant='subtitle2' align='center'>
                              <strong>Tutor:</strong>&nbsp;{materia.tutor}
                            </Typography>
                            <Typography variant='subtitle1' align='center'>
                              {moneda === 'usd'
                                ? process.env.PRECIO_USD_MATERIAS_S
                                : process.env.PRECIO_MXN_MATERIAS_S}
                            </Typography>
                          </>
                        }
                      />
                      <DialogActions>
                        <Button
                          style={{ paddingBottom: 5, paddingTop: 5 }}
                          onClick={() => moveToSelected(materia.id)}
                          variant='contained'
                          color='secondary'>
                          Agregar
                        </Button>
                      </DialogActions>
                    </Card>
                  </Grid>
                ))
              )}
              {}
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            <strong>Materias seleccionadas</strong>
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item container xs={12}>
            {materiasElegidas.map((materia, index) => (
              <Grid
                key={index}
                item
                container
                spacing={1}
                justifyContent='center'
                alignItems='center'>
                <Grid item xs={12} sm={6}>
                  <Typography variant='body1'>{materia.titulo}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant='body1' align='left'>
                    {moneda === 'usd'
                      ? process.env.PRECIO_USD_MATERIAS_S
                      : process.env.PRECIO_MXN_MATERIAS_S}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <IconButton onClick={() => removeFromSelected(materia.id)}>
                    <ClearIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            {!isEmptyOrNil(materiasElegidas) && (
              <Grid
                item
                container
                spacing={1}
                alignItems='center'
                justifyContent='center'
                style={{ marginTop: 8 }}>
                <Grid item xs={12}>
                  <Divider style={{ margin: 8 }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant='subtitle1'>
                    <strong>Total</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant='body1' align='left'>
                    $
                    {format(
                      moneda === 'usd'
                        ? R.length(materiasElegidas) *
                            process.env.PRECIO_USD_MATERIAS
                        : R.length(materiasElegidas) *
                            process.env.PRECIO_MXN_MATERIAS
                    ).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}></Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} />
      <Grid item container xs={6} direction='column' alignItems='flex-end'>
        <Button
          size='large'
          variant='contained'
          color='secondary'
          onClick={async () => {
            if (
              R.length(materiasElegidas) < 1 ||
              R.length(materiasElegidas > 4)
            ) {
              errorMessage('Debes seleccionar entre una y cuatro materias');
            } else {
              try {
                await INSCRIPCION_MATERIAS(
                  user,
                  R.map((m) => m.clave)(materiasElegidas),
                  hash
                );
                setState({
                  ...state,
                  materiasElegidas,
                  monedaPago: moneda,
                  vista: 'pago',
                  usuario: sesionState.sesion,
                  jwt: hash,
                });
              } catch (error) {
                console.log('Error', error.response.data?.message || error);
                errorMessage(
                  'Hubo un error al registrar las materias del estudiante'
                );
              }
            }
          }}>
          Continuar al Pago
        </Button>
      </Grid>
    </Grid>
  );
};

export default SeleccionMaterias;
