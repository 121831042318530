import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { PERIODO_INSCRIPCION } from '../../API';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '5% 5%',
    backgroundColor: 'rgb(240,240,240)',
  },
  elemento: {
    textAlign: 'center',
  },
}));

const Mantenimiento = () => {
  const classes = useStyles();
  const [periodoInsc, setPeriodoInsc] = React.useState(null);

  React.useEffect(() => {
    const fetch = async () => {
      if (periodoInsc) {
        try {
          const response = await PERIODO_INSCRIPCION();
          setPeriodoInsc(response.data.periodo);
        } catch (error) {
          console.log('Error', error.response.data?.message || error);
        }
      }
    };
    fetch();
  }, [periodoInsc]);

  return periodoInsc ? (
    <div className={classes.root}>
      <Typography variant='h2'>
        El próximo período de inscripciones inicia el día{' '}
        {new Date(periodoInsc?.fechaInicio).toLocaleDateString('es-MX', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
        .
      </Typography>
      <Typography variant='h5'>
        Infórmate de los requisitos y no pierdas esta oportunidad.
        <br />
        Si tienes alguna duda puedes contactarnos por medio de los siguientes
        canales:
      </Typography>
      <br />
      <Typography variant='h5'>
        ✉️<b>Correo</b>: school@prepaenlinea.mx
      </Typography>
      <Typography variant='h5'>
        💬 <b>Chat te atención</b>: Dando clic a la burbuja verde en la esquina
        inferior derecha.
      </Typography>
      <Typography variant='h5'>
        📞 <b>Llamada telefónica al</b>: +52 (222) 454 - 5670
      </Typography>
      <br />
      <Typography variant='h5'>
        Horario de atención: Lunes a viernes de 9:00 hrs a 14:00 hrs y de 15:00
        hrs a 18:00 hrs.
      </Typography>
      <hr style={{ margin: '50px 0' }} />
      <div style={{ textAlign: 'center' }}>
        <br />
        <br />
        <br />
        <Typography variant='h1' color='secondary'>
          {'\u231B'}
        </Typography>
        <br />
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <Typography variant='h2'>
        Aún no tenemos definida la fecha del próximo periodo de inscripciones,
        pero mantente atento a las notificaciones en discord.
      </Typography>
      <Typography variant='h5'>
        <br />
        Si tienes alguna duda puedes contactarnos por medio de los siguientes
        canales:
      </Typography>
      <br />
      <Typography variant='h5'>
        ✉️<b>Correo</b>: school@prepaenlinea.mx
      </Typography>
      <Typography variant='h5'>
        💬 <b>Chat te atención</b>: Dando clic a la burbuja verde en la esquina
        inferior derecha.
      </Typography>
      <Typography variant='h5'>
        📞 <b>Llamada telefónica al</b>: +52 (222) 454 - 5670
      </Typography>
      <br />
      <Typography variant='h5'>
        Horario de atención: Lunes a viernes de 9:00 hrs a 14:00 hrs y de 15:00
        hrs a 18:00 hrs.
      </Typography>
      <hr style={{ margin: '50px 0' }} />
      <div style={{ textAlign: 'center' }}>
        <br />
        <br />
        <br />
        <Typography variant='h1' color='secondary'>
          {'\u231B'}
        </Typography>
        <br />
      </div>
    </div>
  );
};

export default Mantenimiento;
