import React, { useReducer, createContext } from 'react';

const globalStore = createContext(null);
const { Provider } = globalStore;

const initialState = {
  nextTerm: '',
};

const globalReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_NEXT_TERM':
      return { ...state, nextTerm: payload };
    case 'IS_ENROLLMENT':
      return { ...state, isEnrollment: payload };
  }
};

const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { globalStore, GlobalProvider };
