import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
import Footer from '../componentes/Footer';
import faq from '../imagenes/FAQ.svg';

import { Facebook, Instagram } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    padding: '100px 10%',
    textAlign: 'center',
  },
}));

const Mantenimiento = () => {
  const classes = useStyles();

  return (
    <Fragment>
      {/* <Head titulo='Mantenimiento' /> */}
      <div className={classes.root}>
        <img alt='' src={faq} style={{ width: '200px' }} />
        <Typography variant='h3'>Sitio en mantenimiento</Typography>
        <br />
        <Typography variant='h6'>
          Estamos trabajando para poderte brindar una mejor atención.
          <br />
          Si necesitas ayuda, entra a nuestro{' '}
          <a href='https://discord.gg/NJMAaQt3qb' target='_blank'>
            servidor de Discord.
          </a>
        </Typography>
        <br />
        <br />
        <Typography variant='body1'>Visita nuestras redes sociales:</Typography>
        <Grid container>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <a href='https://www.facebook.com/prepaenlineamx' target='_blank'>
              <IconButton>
                <Facebook fontSize='large' />
              </IconButton>
            </a>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            <a
              href='https://www.instagram.com/prepaenlineamx'
              target='_blank'>
              <IconButton>
                <Instagram fontSize='large' />
              </IconButton>
            </a>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Mantenimiento;
