import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { simpleRowStyles } from '../components/List/List.style';
import { List } from '../components/List/List';

export const Subjects = ({ currentSubjects }) => {
  const classes = simpleRowStyles();
  return (
    <Box className={classes.column}>
      <List
        title='Materias actuales'
        type='subjects'
        rows={
          <div>
            {currentSubjects.length === 0 ? (
              <div className={classes.root}>
                <Typography variant='subtitle2'>
                  No hay materias cursadas
                </Typography>
              </div>
            ) : (
              currentSubjects.map((subject) => (
                <div className={classes.root} key={subject.Materia.clave}>
                  <p className={classes.title}>{subject.Materia.titulo}</p>
                </div>
              ))
            )}
          </div>
        }
      />
    </Box>
  );
};
