import { makeStyles } from '@material-ui/core';

const colorsByListType = {
  subjects: '#21594F',
  documents: '#773286',
  academicHistory: '#77692C',
  payments: '#61C4B2',
};

export const listStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  header: {
    backgroundColor: (props) => colorsByListType[props.type],
    padding: '16px',
    borderRadius: '8px 8px 0 0',
  },
  title: {
    margin: 0,
    fontFamily: 'Quicksand',
    fontSize: '16px',
    fontWeight: 700,
    color: '#fff',
  },
  rows: {
    maxHeight: (props) => props.maxHeight || '100%',
    overflow: 'auto',
    marginBottom: (props) => (props.maxHeight ? '16px' : 0),
  },
});

export const simpleRowStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    borderBottom: '1px solid #E0E0E0',
  },
  title: {
    margin: 0,
    fontFamily: 'Quicksand',
    fontSize: '16px',
    color: '#1D1B20',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '16px',
  },
  check: {
    fill: '#61C480',
  },
  warning: {
    fill: '#C46173',
  },
  column: {
    width: '100%',
    '@media (min-width: 1024px)': {
      width: '50%',
    },
  },
});
